import {createApi, fetchBaseQuery} from "@reduxjs/toolkit/query/react";
import {ApiUrl} from "../constant";
import { companyStyleDataAction } from "../redux/CompanyInformation/companySlice";

export const companyApi = createApi({
  reducerPath: "companyAPI",
  baseQuery: fetchBaseQuery({
    baseUrl: ApiUrl
  }),
  endpoints: (builder) => ({
    getCompany: builder.mutation({
      query: () => ({
        url: "/company",
        method: "GET",
        headers: {
          "Content-type": "application/json; charset=UTF-8",
          Authorization: localStorage.getItem("accessToken")
        }
      })
    }),
    createCompany: builder.mutation({
      query: (payload) => ({
        url: "/company",
        method: "PUT",
        body: payload,
        headers: {
          // 'Content-Type': `multipart/form-data`,
          Authorization: localStorage.getItem("accessToken")
        }
      })
    }),
    extendFreeTrialOfCompany: builder.mutation({
      query: (payload) => ({
        url: "/subscription/company/extend-trial",
        method: "POST",
        body: payload,
        headers: {
          // 'Content-Type': `multipart/form-data`,
          Authorization: localStorage.getItem("accessToken")
        }
      })
    }),
    companyBillingDetails: builder.mutation({
      query: (id) => ({
        url: `/subscription/get-latest-invoice-details?company_id=${id}`,
        method: "GET",
        headers: {
          "Content-Type": `multipart/form-data`,
          Authorization: localStorage.getItem("accessToken")
        }
      })
    }),
    companyDefaultSchedule: builder.mutation({
      query: () => ({
        url: `/workschedule/company-default-schedule`,
        method: "GET",
        headers: {
          "Content-Type": `multipart/form-data`,
          Authorization: localStorage.getItem("accessToken")
        }
      })
    }),
    createCompanyWorkSchedule: builder.mutation({
      query: (payload) => {
        return {
          url: `/workschedule/company/bulk/update`,
          method: "POST",
          body: payload,
          headers: {
            "Content-type": "application/json; charset=UTF-8",
            Authorization: localStorage.getItem("accessToken")
          }
        };
      },
      invalidatesTags: ["Post"]
    }),
    applyCompanySchedule: builder.mutation({
      query: () => {
        return {
          url: `/workschedule/apply-company-schedule`,
          method: "POST",
          headers: {
            "Content-type": "application/json; charset=UTF-8",
            Authorization: localStorage.getItem("accessToken")
          }
        };
      }
    }),
    sendNotificationMail: builder.mutation({
      query: (payload) => ({
        url: "/employeenotification/turn/on-off",
        method: "PUT",
        body: payload,
        headers: {
          "Content-type": "application/json; charset=UTF-8",
          Authorization: localStorage.getItem("accessToken")
        }
      })
    }),
    getNotificationMail: builder.mutation({
      query: () => ({
        url: `/employeenotification/email-setting`,
        method: "GET",
        headers: {
          "Content-Type": `multipart/form-data`,
          Authorization: localStorage.getItem("accessToken")
        }
      })
    }),
    auditorCompanyLogin: builder.mutation({
      query: (payload) => {
        return {
          url: `/auditor/company/login`,
          method: "POST",
          body: payload,
          headers: {
            "Content-type": "application/json; charset=UTF-8",
            Authorization: localStorage.getItem("accessToken")
          }
        };
      }
    }),
    createCompanyCustomization: builder.mutation({
      query: (payload) => {
        const { formData, company_id} = payload;
        return {
          url: `/company/customization/${company_id}`,
          method: "PUT",
          body: formData,
          headers: {
            // "Content-type": "application/json; charset=UTF-8",
            Authorization: localStorage.getItem("accessToken")
          }
        };
      },
      invalidatesTags: ["companyDetailsInfo"]
    }),
    customizationDetails: builder.query({
      query: ({id}) => {
        return {
          url: `/company/customization/${id}`,
          method: "GET",
          headers: {
            "Content-type": "application/json; charset=UTF-8",
            Authorization: localStorage.getItem("accessToken")
          }
        };
      },
      providesTags: ["companyDetailsInfo"],
      // eslint-disable-next-line no-unused-vars
      async onQueryStarted(arg, {dispatch, queryFulfilled}) {
        try {
            // eslint-disable-next-line no-unused-vars
          const {data} = await queryFulfilled;
          if(data){
            dispatch(companyStyleDataAction(data?.data)); 
          }
        
          // dispatch(orderDetailsAction(data));
        } catch (error) {
          // commonErrorHandler(error?.error, dispatch);
        }
      }
    }),
    resetCompanyStyles: builder.mutation({
      query: ({id}) => ({
        url: `company/customization/reset/${id}`,
        method: 'PUT',
        headers: {
          'Content-type': 'application/json; charset=UTF-8',
          Authorization:  localStorage.getItem('accessToken')
        }
       
      }),
      async onQueryStarted(arg, {dispatch, queryFulfilled}) {
        try {
            // eslint-disable-next-line no-unused-vars
          const {data} = await queryFulfilled;
          if(data){
            dispatch(companyStyleDataAction(data?.data)); 
          }
        
          // dispatch(orderDetailsAction(data));
        } catch (error) {
          // commonErrorHandler(error?.error, dispatch);
        }
      }
      // invalidatesTags: [ 'Post' ]
    }) 
  })
});

export const {
  useCreateCompanyMutation,
  useGetCompanyMutation,
  useCompanyBillingDetailsMutation,
  useExtendFreeTrialOfCompanyMutation,
  useCompanyDefaultScheduleMutation,
  useCreateCompanyWorkScheduleMutation,
  useApplyCompanyScheduleMutation,
  useSendNotificationMailMutation,
  useGetNotificationMailMutation,
  useAuditorCompanyLoginMutation,
  useCreateCompanyCustomizationMutation,
  useCustomizationDetailsQuery,
  useResetCompanyStylesMutation
} = companyApi;
