import {useDispatch, useSelector} from "react-redux";
import {FormSelect} from "../../components/formInput";
import {useGetDepartmentListMutation} from "../../services/approverListApi";
import {
  useFilterEmployeeDepartmentMutation,
  useGetEmployeeMutation
} from "../../services/employeeApi";
import Select from "react-select";
import {useEffect, useRef, useState} from "react";
import {
  REACT_APP_COMPANY_ADMIN_ROLE_ID,
  REACT_APP_EMPLOYEE_ROLE_ID,
  REACT_APP_MANAGER_ROLE_ID
} from "../../helper/constant";
import {toast} from "react-toastify";
import {employeeInfo} from "../../redux/EmployeeSlice/employeeSlice";
import {departmentListAction} from "../../redux/DepartmentSlice/departmentSlice";
import PrimaryButton from "../../components/primaryButton";
import {useCreateHolidayLocationMutation} from "../../services/holidayApi";

const UpdateLocation = ({handleClose}) => {
  const ignore = useRef(false);
  const dispatch = useDispatch();
  const companyData = useSelector(
    (state) => state?.persistedReducer?.companySlice?.companyData?.companyData
  );
  const locations = [
    {label: "England + Wales", value: 1},
    {label: "Scotland", value: 2},
    {label: "Ireland", value: 3}
  ];
  const [createHolidayLocation, {data, isSuccess, isError, error: apiError}] =
    useCreateHolidayLocationMutation();

  const departmentApiData = useSelector(
    (state) => state?.persistedReducer?.departmentSlice?.allDepartmentList
  );
  const loggedInUserData = useSelector(
    (state) => state?.persistedReducer?.userPermissionsStates?.user
  );
  const [
    filterEmployeeDepartment,
    {data: filterDepartmentApi, isSuccess: isSuccessFilterDepartment}
  ] = useFilterEmployeeDepartmentMutation();
  const toastId = useRef(null);
  const [
    getDepartmentList,
    {data: departmentApiResponse, isSuccess: isSuccessDepartmentApi}
  ] = useGetDepartmentListMutation();
  const [
    getEmployee,
    {
      data: employeeListAllData,
      isSuccess: isEmployeeAPISuccess
      //   isLoading: isEmployeeAPILoading
    }
  ] = useGetEmployeeMutation();
  const [isAllDepartment, setIsAllDepartment] = useState(false);
  const [filterEmployee, setFilterEmployee] = useState();
  const [allDepartmentListData, setAllDepartmentListData] = useState();
  //   eslint-disable-next-line no-unused-vars
  const [error, setError] = useState({
    errorDepartmentId: "",
    errorEmployeeId: "",
    //errorLeaveType: "",
    errorLocationId: ""
  });
  const [formData, setFormData] = useState({
    department_id: 0,
    employee_id: 0,
    location_id: 0
  });
  useEffect(() => {
    if (isSuccess) {
      handleClose();
      toast.dismiss(toastId.current);
      const toastMessage = data?.data?.message
        ? data?.data?.message
        : "Location Updated successfully";
      toast.success(toastMessage, {
        render: toastMessage,
        type: "success",
        autoClose: 5000,
        duplicate: false,
        isLoading: false
      });
    }
    if (isError) {
      toast.dismiss(toastId.current);
      const toastMessage = apiError?.data?.message
        ? apiError?.data?.message
        : "Something went wrong";
      toast.error(toastMessage, {
        render: toastMessage,
        type: "error",
        autoClose: 8000,
        duplicate: false,
        isLoading: false
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isSuccess, isError]);

  useEffect(
    () => {
      if (isSuccessFilterDepartment) {
        const arr = [];
        filterDepartmentApi?.data?.length > 0 &&
          filterDepartmentApi?.data?.forEach((emp) => {
            let empOption = {
              value: emp?.id,
              label: `${emp?.first_name} ${emp?.last_name}`
            };
            arr?.push(empOption);
          });
        setFilterEmployee(arr);
        //  setFilterEmployee(filterDepartmentApi.data);
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [isSuccessFilterDepartment]
  );

  useEffect(
    () => {
      if (isEmployeeAPISuccess) {
        dispatch(employeeInfo(employeeListAllData));
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [isEmployeeAPISuccess]
  );

  useEffect(
    () => {
      if (!ignore.current) {
        getDepartmentList();
        let payload = `/employee?page=${1}&status=${1}&limit=100`;
        getEmployee(payload);
        // if (employeeIdData) {
        //   setEmployeeId(employeeIdData);
        //   setSkip(false);
        // }
      }
      return () => {
        ignore.current = true;
      };
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );
  useEffect(
    () => {
      if (isSuccessDepartmentApi) {
        dispatch(departmentListAction(departmentApiResponse));
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [isSuccessDepartmentApi]
  );

  useEffect(
    () => {
      let arr = [];
      if (departmentApiData) {
        departmentApiData?.data?.length > 0 &&
          departmentApiData?.data?.forEach((dept) => {
            if (
              loggedInUserData?.roleId == REACT_APP_MANAGER_ROLE_ID &&
              loggedInUserData?.department_id &&
              dept?.id == loggedInUserData?.department_id
            ) {
              let deptOption = {
                value: dept?.id,
                label: dept?.department_name
              };
              arr?.push(deptOption);
            }
            if (loggedInUserData?.roleId == REACT_APP_COMPANY_ADMIN_ROLE_ID) {
              let deptOption = {
                value: dept?.id,
                label: dept?.department_name
              };
              arr?.push(deptOption);
            }
            if (
              loggedInUserData?.roleId == REACT_APP_EMPLOYEE_ROLE_ID &&
              loggedInUserData?.department_id
            ) {
              let deptOption = {
                value: dept?.id,
                label: dept?.department_name
              };
              arr?.push(deptOption);
            }
          });
        setAllDepartmentListData(arr);
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [loggedInUserData, departmentApiData]
  );

  const handleMultipleEmployee = (value) => {
    console.log(value);
    if (value[0]?.value === "All Employee") {
      setFormData({...formData, ["employee_id"]: ""});
    } else {
      let selectedEmployee = value?.map((employee) => {
        return employee?.value?.toString(); // Convert each employee ID to a string;
      });
      setFormData((formValue) => {
        return {
          ...formValue,
          ["employee_id"]: selectedEmployee
        };
      });
    }
  };
  const handlerChange = (e) => {
    e.preventDefault();
    const {name} = e.target;
    if (name === "department_id") {
      if (e.target.value === "All Department") {
        setIsAllDepartment(true);
      } else {
        setIsAllDepartment(false);
      }
    }
    setError({
      errorDepartmentId: "",
      errorEmployeeId: "",
      errorLocationId: ""
    });
    setFormData((formValue) => {
      return {
        ...formValue,
        ["department_id"]: `${e.value}`
      };
    });
    setFormData({...formData, [name]: e.target.value});

    if (name === "department_id") {
      let value = {
        departmentId: e.target.value,
        parmStatus: 1
      };
      filterEmployeeDepartment(value);
    }
  };
  const validateForm = () => {
    const errors = {};

    // Check if department_id is 0
    if (formData?.department_id === 0) {
      errors.errorDepartmentId = "Please select Department";
    }

    // Check if location_id is 0
    if (formData?.location_id === 0) {
      errors.errorLocationId = "Please select Location";
    }

    // Check if employee_id is 0 but only if department_id is not 0
    if (
      formData?.department_id !== 0 &&
      formData?.department_id !== "All Department" &&
      formData?.employee_id === 0
    ) {
      errors.errorEmployeeId = "Please select EmployeeId";
    }

    // If there are any errors, set the errors and return false
    if (Object.keys(errors).length > 0) {
      setError((prevError) => ({
        ...prevError,
        ...errors
      }));
      return false; // Validation failed
    }

    // Clear all errors and return true if validation passes
    setError({
      errorDepartmentId: "",
      errorEmployeeId: "",
      errorLocationId: ""
    });

    return true; // Validation passed
  };

  const handlerSave = (e) => {
    e.preventDefault();
    if (validateForm()) {
      let value = {
        company_id: companyData?.id,
        location_id: formData?.location_id,
        employee_ids:
          formData?.department_id === "All Department"
            ? ""
            : formData?.employee_id === "All Employee"
            ? formData?.employee_id === 0
              ? ""
              : ""
            : formData?.employee_id,
        all: formData?.department_id === "All Department" ? 1 : 0,
        department_id:
          formData?.department_id === "All Department"
            ? ""
            : formData?.department_id
      };
      // Proceed with form submission or other operations
      createHolidayLocation(value);
    } else {
      // Handle form validation failure

      console.log("Form is invalid, check errors.");
    }
  };

  return (
    <>
      <div className="header">
        <span>
          <h3>Update Location</h3>
        </span>
      </div>
      <div className="content">
        <div className=" w-full">
          <div className="form-group mb-6 ">
            <label className="formBlock	">
              Who for{" "}
              <span className=" relative -top-2 fa fa-asterisk secondry-text text-xs text-[8px]" />
            </label>
            <div className="flex flex-wrap ">
              <div className="md:w-1/2 md:pr-6 mb-6 md:mb-0 w-full">
                <select
                  className="formControl mr-2"
                  id="department"
                  name="department_id"
                  onChange={(e) => handlerChange(e)}
                >
                  <FormSelect
                    optionValue={0}
                    optionName={"Select Department"}
                  />
                  ;
                  <FormSelect
                    optionValue={"All Department"}
                    optionName={"Select All Department"}
                  />
                  {allDepartmentListData?.length > 0
                    ? allDepartmentListData?.map((departments, key) => {
                        return (
                          <FormSelect
                            key={key}
                            optionValue={departments?.value}
                            optionName={departments?.label}
                          />
                        );
                      })
                    : null}
                </select>
                {console.log("error", error)}
                <div className="help-block text-red-700 mt-1">
                  {error.errorDepartmentId ? error.errorDepartmentId : ""}
                </div>
              </div>
              <div className="md:w-1/2 w-full">
                <Select
                  // className="formControl mr-2"
                  options={[
                    {value: "0", label: "Select Employee Name"}, // Default Option
                    {
                      value: "All Employee",
                      label: "Select All Employee"
                    }, // "All Employee" Option

                    ...(filterEmployee && filterEmployee.length > 0
                      ? filterEmployee.map((employee) => ({
                          value: employee.value,
                          label: employee.label
                        }))
                      : [])
                  ]}
                  id="employee"
                  name="employee_id"
                  isMulti={true}
                  isDisabled={isAllDepartment}
                  onChange={(value) => {
                    handleMultipleEmployee(value);
                  }}
                />
                <div className="help-block text-red-700 mt-1">
                  {error.errorEmployeeId ? error.errorEmployeeId : ""}
                </div>
                {/* <div className="text-red-700 mt-2">{errorEmployee}</div> */}
              </div>
            </div>
          </div>
          <div>
            <label className="formBlock	">
              Select Location{" "}
              <span className=" relative -top-2 fa fa-asterisk secondry-text text-xs text-[8px]" />
            </label>
            <select
              className="formControl mr-2"
              id="location"
              name="location_id"
              onChange={(e) => handlerChange(e)}
            >
              <FormSelect optionValue={"0"} optionName={"Select Location"} />;
              {locations?.length > 0
                ? locations?.map((departments, key) => {
                    return (
                      <FormSelect
                        key={key}
                        optionValue={departments?.value}
                        optionName={departments?.label}
                      />
                    );
                  })
                : null}
            </select>
            <div className="help-block text-red-700 mt-1">
              {error.errorLocationId ? error.errorLocationId : ""}
            </div>
          </div>
        </div>
        <div className="lg:w w-full  ">
          <div className="w-full">
            <div className="form-group pt-4 pb-3 flex justify-center ">
              <PrimaryButton
                btnText="Next"
                Btnclass="btn save mr-2"
                btnype="button"
                buttonID="next"
                onClick={(e) => handlerSave(e)}
              />
              <PrimaryButton
                btnText="Cancel"
                onClick={() => handleClose()}
                Btnclass="btn cancel"
                buttonID="cancel"
                btntype="button"
              />
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default UpdateLocation;
