/* eslint-disable no-unused-vars */
/* eslint-disable camelcase */
/* eslint-disable no-debugger */
//import React from "react";
/* eslint-disable */
import {useState, useEffect, useRef} from "react";
import {Helmet, HelmetProvider} from "react-helmet-async";
import Popup from "reactjs-popup";
import {DateFiled, FormTextarea} from "../../components/formInput";
import PrimaryButton from "../../components/primaryButton";
import {useParams, useNavigate, useLocation} from "react-router-dom";
import {useDispatch, useSelector} from "react-redux";
import {
  useEmployeeLeftDetailsMutation,
  useEmployeeLeftMutation,
  useGetEmployeeDetailMutation,
  useUpdateEmployeeByIdMutation
} from "../../services/employeeApi";
import {checkValidations} from "./empolyeeValidation";
import {parseISO} from "date-fns";
import {toast} from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import {useGetApproverListAPIMutation} from "../../services/approverListApi";
import ProfileDetails from "./ProfileDetails";
import ApproverEmployee from "./ApproverEmployee";
import EmployeeNotes from "./EmployeeNotes";
import UserAllowances from "./UserAllowances";
import EmployeeBankHoliday from "./EmployeeBankHoliday";
import {LoadingIndicator} from "../../components/loading-indicator";
import MyProfile from "../Employees/MyProfile";
import {
  REACT_APP_COMPANY_ADMIN_ROLE_ID,
  REACT_APP_EMPLOYEE_ROLE_ID
} from "../../helper/constant";
import EmployeeHistory from "./employeeHistory";
import EmployeeDocument from "./EmployeeDocument";
import {employeeDetailsAction} from "../../redux/EmployeeSlice/employeeSlice";
import TimeInLieu from "./timeInLieu";
import MyAbsenceEmployee from "./myAbsenceEmployee";
import WorkScheduleToggle from "./WorkSchedule";
import EmployeeTrends from "./EmployeeTrends";

const EmployeeDetails = () => {
  const dispatch = useDispatch();
  const {id} = useParams();
  //  const navigates = useNavigate();
  const toastId = useRef(null);
  const navigate = useNavigate();

  const employee_data = useSelector(
    (state) => state?.persistedReducer?.userPermissionsStates?.user?.employee_id
  );
  const roleIdData = useSelector(
    (state) => state?.persistedReducer?.userPermissionsStates?.user?.roleId
  );
  const employeeData = useSelector(
    (state) => state?.persistedReducer?.employeeSlice?.employeeDetails
  );

  const [
    getApproverListAPI,
    {data: approverList, isSuccess: isApproveSuccess}
  ] = useGetApproverListAPIMutation();
  const getEmployeeDepartments = useSelector(
    (state) => state?.departmentState?.employeeDepartmentsData
  );

  const [getEmployeeDetail, {data: employeeDataList, isSuccess}] =
    useGetEmployeeDetailMutation();
  const permissionsSelector = useSelector(
    (state) => state.persistedReducer.userPermissionsStates.userPermissions
  );
  const activeEmployeeUpdatePermissions = permissionsSelector?.filter(
    (obj) =>
      obj?.module_name === "Active Employee" &&
      obj?.module_permission_name === "Update"
  );
  const activeEmployeeDeletePermissions = permissionsSelector?.filter(
    (obj) =>
      obj?.module_name === "Active Employee" &&
      obj?.module_permission_name === "Delete"
  );

  const [
    updateEmployeeById,
    {
      data,
      isSuccess: isProfileUpdate,
      isError: isProfileError,
      isLoading: isprofileLoading,
      error: profileError
    }
  ] = useUpdateEmployeeByIdMutation();
  const [
    employeeLeft,
    {
      isSuccess: isEmployeeLeft,
      isError: isEmployeeLeftError,
      error: employeeLeftError,
      isLoading: isLeftLoading
    }
  ] = useEmployeeLeftMutation();
  const [
    employeeLeftDetails,
    {
      data: employeeLeftDetailsData,
      isSuccess: isLeftDetailsSuccess,
      isError: isLeftDetailsError,
      error: employeeLeftDetailsError
      // isLoading: isLeftDetailsLoading
    }
  ] = useEmployeeLeftDetailsMutation();
  const [isLeave, setIsLeave] = useState(false);
  const [isExpenses, setIsExpense] = useState(false);
  const [isOverTime, isSetOverTime] = useState(false);
  const [isSame, setIsSame] = useState(false);
  const [leaveApprover, setLeaveApprover] = useState();
  const [open, setOpen] = useState(false);
  const [file, setFile] = useState("");
  const [preview, setPriview] = useState("/images/no-image.png");

  const [isLeft, setIsLeft] = useState(false);
  const closeModal = () => {
    setOpen(false);
  };

  const {state} = useLocation();

  const location = useLocation();
  const initialTab = location.state?.activeTab || "Overview";
  const [toggleState, setToggleState] = useState(initialTab);
  const [isNext, setIsNext] = useState(false);
  const toggleTab = (index) => {
    setToggleState(index);
  };

  const [employeeInfo, setEmployeeInfo] = useState({
    annual_allowance: 0,
    companies_id: 0,
    companyInfo: {},
    country_code: "",
    createdAt: "",
    deletedAt: null,
    departmentInfo: null,
    department_id: 0,
    rolesId: null,
    dob: "",
    email: "",
    employee_can_approve_expense: "0",
    employee_can_approve_leave: "0",
    employee_can_approve_ovetime_lieu: "0",
    employment_start_date: "",
    expense_approver: "0",
    first_name: "",
    gender: "0",
    historyInfo: [],
    hours_or_day: "0",
    id: id,
    last_name: "",
    leaveInfo: [],
    leave_approver: "0",
    leaving_date: new Date(),
    leftInfo: null,
    loginInfo: null,
    location_id: 0,
    maximum_leave_carry: 0,
    mobile_no: "",
    note: null,
    noteInfo: [],
    overtime_lieu_approver: 0,
    pro_rata: "0",
    profile_image: null,
    remaining_leave_next_year: null,
    remaining_leave_this_year: null,
    remaining_time_in_lieu: 0,
    country: "",
    postal_code: "",
    city: "",
    m_roles_id: "",
    notes: "",
    address: "",
    address2: "",
    status: ""
  });

  const [errorValidations, setErrorValidations] = useState({
    leaveDateError: ""
  });

  const handleDate = (date, name) => {
    setEmployeeInfo({...employeeInfo, [name]: date});
  };

  const handelLeaveEmployee = () => {
    setIsNext(true);
    employeeLeft({
      leaving_date: employeeInfo?.leaving_date,
      note: employeeInfo?.notes,
      id: employeeInfo?.id,
      previewMode: 0
    });
  };
  const handleLeaveDetails = () => {
    employeeLeftDetails({
      leaving_date: employeeInfo?.leaving_date,
      note: employeeInfo?.notes,
      id: employeeInfo?.id,
      previewMode: "1"
    });
  };
  const handleUpload = async (event) => {
    if (event.target.files[0]) {
      const image = event.target.files[0];

      // Create a FileReader to read the image file
      const reader = new FileReader();

      // Set up the FileReader to handle the file
      reader.onload = function (event) {
        const img = new Image();
        img.src = event.target.result;

        // When the image has loaded, resize and optimize it
        img.onload = function () {
          const canvas = document.createElement("canvas");
          const ctx = canvas.getContext("2d");

          // Set the canvas dimensions to the new size (e.g., 80x80)
          canvas.width = 80;
          canvas.height = 80;

          // Draw the image on the canvas with the new dimensions
          ctx.drawImage(img, 0, 0, 80, 80);

          // Convert the canvas content to a data URL
          canvas.toBlob(
            (blob) => {
              // Set the resized and optimized image blob
              setFile(blob);
              setPriview(URL.createObjectURL(blob));
            },
            "image/jpeg",
            1
          ); // Adjust quality as needed
        };
      };

      // Read the image file as a data URL
      reader.readAsDataURL(image);
    }
  };

  const handelChange = (e) => {
    let {name, value} = e.target;

    let message = checkValidations(name, value);
    setErrorValidations({...errorValidations, [name]: message});
    setEmployeeInfo({...employeeInfo, [name]: value});
  };
  useEffect(
    () => {
      if (isSuccess) {
        dispatch(employeeDetailsAction(employeeDataList));
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [isSuccess]
  );

  useEffect(
    () => {
      if (employeeData?.data?.length > 0 && employeeData?.data[0]) {
        setEmployeeInfo({
          annual_allowance: employeeData?.data[0]?.annual_allowance,
          companies_id: employeeData?.data[0]?.companies_id,
          companyInfo: employeeData?.data[0]?.companyInfo,
          country_code: employeeData?.data[0]?.country_code,
          createdAt: employeeData?.data[0]?.createdAt,
          deletedAt: employeeData?.data[0]?.deletedAt,
          departmentInfo: employeeData?.data[0]?.departmentInfo,
          department_id: employeeData?.data[0]?.department_id,
          designation: employeeData?.data[0]?.designation,
          dob: employeeData?.data[0]?.dob
            ? parseISO(employeeData?.data[0]?.dob)
            : new Date(),
          email: employeeData?.data[0]?.email,
          employee_can_approve_expense:
            employeeData?.data[0]?.employee_can_approve_expense,
          employee_can_approve_leave:
            employeeData?.data[0]?.employee_can_approve_leave,
          employee_can_approve_ovetime_lieu:
            employeeData?.data[0]?.employee_can_approve_ovetime_lieu,
          employment_start_date: employeeData?.data[0]?.employment_start_date,
          expense_approver: employeeData?.data[0]?.expense_approver,
          rolesId: employeeData?.data[0]?.rolesId,
          first_name: employeeData?.data[0]?.first_name,
          gender: employeeData?.data[0]?.gender,
          historyInfo: employeeData?.data[0]?.historyInfo,
          hours_or_day: employeeData?.data[0]?.hours_or_day,
          id: employeeData?.data[0]?.id,
          last_name: employeeData?.data[0]?.last_name,
          leaveInfo: employeeData?.data[0]?.leaveInfo,
          leave_approver: employeeData?.data[0]?.leave_approver,
          leaving_date: employeeData?.data[0]?.leaving_date
            ? parseISO(employeeData?.data[0]?.leaving_date)
            : "",
          leftInfo: employeeData?.data[0]?.leftInfo,
          loginInfo: employeeData?.data[0]?.loginInfo,
          maximum_leave_carry: employeeData?.data[0]?.maximum_leave_carry,
          mobile_no: employeeData?.data[0]?.mobile_no,
          note: employeeData?.data[0]?.note,
          noteInfo: employeeData?.data[0]?.noteInfo,
          overtime_lieu_approver: employeeData?.data[0]?.overtime_lieu_approver,
          pro_rata: employeeData?.data[0]?.pro_rata,
          profile_image: employeeData?.data[0]?.profile_image,
          remaining_leave_next_year:
            employeeData?.data[0]?.remaining_leave_next_year,
          remaining_leave_this_year:
            employeeData?.data[0]?.remaining_leave_this_year,
          remaining_time_in_lieu: employeeData?.data[0]?.remaining_time_in_lieu,
          m_roles_id: employeeData?.data[0]?.rolesInfo[0]?.roleType?.id,
          workScheduleInfo: employeeData?.data[0]?.workScheduleInfo,
          country: employeeData?.data[0]?.country,
          city: employeeData?.data[0]?.city,
          address: employeeData?.data[0]?.address,
          postalCode: employeeData?.data[0]?.postal_code,
          address2: employeeData?.data[0]?.address2,
          status: employeeData?.data[0]?.status,
          location_id: employeeData?.data[0]?.location_id
        });
        setPriview(employeeData?.data[0]?.profile_image);
        setIsLeave(employeeData?.data[0]?.employee_can_approve_leave == 1);
        setIsExpense(employeeData?.data[0]?.employee_can_approve_expense == 1);
        isSetOverTime(
          employeeData?.data[0]?.employee_can_approve_ovetime_lieu == 1
        );
        setLeaveApprover(employeeData?.data[0]?.leave_approver);

        if (
          employeeData?.data[0]?.leave_approver ===
            employeeData?.data[0]?.expense_approver &&
          employeeData?.data[0]?.leave_approver ===
            employeeData?.data[0]?.overtime_lieu_approver &&
          employeeData?.data[0]?.expense_approver ===
            employeeData?.data[0]?.overtime_lieu_approver
        ) {
          if (
            employeeData?.data[0]?.leave_approver !== null &&
            employeeData?.data[0]?.expense_approver !== null &&
            employeeData?.data[0]?.overtime_lieu_approver !== null
          ) {
            setIsSame(true);
          }
        }
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [employeeData]
  );

  useEffect(
    () => {
      if (id) {
        getEmployeeDetail(id);
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [id]
  );

  useEffect(
    () => {
      if (isLeftLoading) {
        toastId.current = toast.loading("Please wait...", {
          // position: "top-center",
          position: "top-center",
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          theme: "colored",
          type: "info"
        });
      }
      if (isEmployeeLeft) {
        navigate("/employees");
        toast.dismiss(toastId.current);
        toast.success("Employee left successfully.", {
          render: "Employee left successfully.",
          position: "top-center",
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          theme: "colored",
          type: "success"
        });
      }
      if (isEmployeeLeftError) {
        const message = employeeLeftError?.data?.message
          ? employeeLeftError?.data?.message
          : "Something went wrong";
        toast.update(
          toastId.current,
          (message,
          {
            render: message,
            type: "error",
            autoClose: 5000,
            isLoading: false
          })
        );
        closeModal();
        setIsLeft((o) => !o);
        close();
      }
      if (isLeftDetailsSuccess) {
        setIsNext(true);
      }
      if (isLeftDetailsError) {
        const message = employeeLeftDetailsError?.data?.message
          ? employeeLeftDetailsError?.data?.message
          : "Something went wrong";
        toast.dismiss(toastId.current);
        toast.error(message, {
          render: message,
          position: "top-center",
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          theme: "colored",
          type: "error"
        });
        // toast.update(
        //   toastId.current,
        //   (message,
        //   {
        //     render: message,
        //     type: "error",
        //     autoClose: 5000,
        //     isLoading: false
        //   })
        // );
        closeModal();
        setIsLeft((o) => !o);
        setIsNext(false);
        close();
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [
      isEmployeeLeft,
      isEmployeeLeftError,
      isLeftLoading,
      isLeftDetailsSuccess,
      isLeftDetailsError,
      employeeLeftDetailsError
    ]
  );

  return (
    <>
      <div>
        <HelmetProvider>
          <Helmet>
            <title>Schedulize | Employees</title>
          </Helmet>
        </HelmetProvider>
        {roleIdData == REACT_APP_EMPLOYEE_ROLE_ID ? (
          <MyProfile />
        ) : (
          <div>
            {employeeData?.data?.length > 0 &&
            employeeData?.data[0]?.id &&
            employee_data ? (
              <>
                <div className="py-6">
                  <div className="container mx-auto ">
                    <h2 className="text-xl mb-3 font-semibold  ">
                      {employeeData?.data[0]?.id !== employee_data
                        ? "Employee Profile"
                        : "My Profile"}
                    </h2>
                    <div
                      className="card bg-white  mb-8 md:p-6 p-4 pb-0"
                      style={{paddingBottom: "0"}}
                    >
                      <div className="employeeDetails flex">
                        <div className="w-20 mr-4 employeeImage">
                          <div className="imageUpload ">
                            {preview ? (
                              <img
                                style={{height: 80, width: 80}}
                                src={preview}
                                className="rounded-lg"
                              />
                            ) : employeeData?.data[0]?.profile_image ? (
                              <img
                                style={{height: 80, width: 80}}
                                src={employeeData?.data[0]?.profile_image}
                                className="rounded-lg"
                              />
                            ) : (
                              <p className="text-center">Upload photo</p>
                            )}
                            <div className="file-upload">
                              {" "}
                              <input type="file" onChange={handleUpload} />{" "}
                              <i className="fa fa-file-image" />
                            </div>
                          </div>
                        </div>
                        <div className="w-full employeeRight ">
                          <div className="employeeContent">
                            <h3>
                              {employeeInfo?.first_name +
                                "  " +
                                employeeInfo?.last_name}
                            </h3>
                            <p>
                              {
                                employeeData?.data[0]?.rolesInfo[0]?.roleType
                                  ?.role_name
                              }
                            </p>
                          </div>

                          {/* <ToastContainer /> */}
                          {employeeData?.data?.length > 0 &&
                          employeeData?.data[0]?.id !== employee_data ? (
                            <>
                              {employeeInfo?.status === "1" &&
                              activeEmployeeDeletePermissions[0]?.status ===
                                true ? (
                                <div className="flex justify-end  ">
                                  <Popup
                                    open={isLeft}
                                    onClose={() => closeModal()}
                                    closeOnDocumentClick={false}
                                    trigger={
                                      <button
                                        className="btn-md btn-add "
                                        aria-describedby="popup-8"
                                        id="empLeft"
                                        onClick={() => setIsLeft((o) => !o)}
                                      >
                                        <span className="fa fa-share mr-2" />
                                        Employee has left
                                      </button>
                                    }
                                    modal
                                    nested
                                  >
                                    {(close) => (
                                      <div className="modal">
                                        <button
                                          id="empLeftClose"
                                          className="close"
                                          onClick={() => {
                                            closeModal();
                                            close();
                                            setIsNext(false);
                                          }}
                                        >
                                          &times;
                                        </button>
                                        <div className="header">
                                          <h3>Employee Left Detail </h3>
                                        </div>

                                        <div className="content">
                                          {isNext ? (
                                            <div className="flex flex-wrap ">
                                              <div className="w-full">
                                                <div className="form-group mb-6 ">
                                                  <label className="formBlock	">
                                                    Total allowance(standard):{" "}
                                                    {(
                                                      (employeeLeftDetailsData
                                                        ?.data
                                                        ?.annualAllowance ||
                                                        0) +
                                                      (employeeLeftDetailsData
                                                        ?.data
                                                        ?.brought_forward || 0)
                                                    ).toFixed(2)}
                                                    {/* <span className=" relative -top-2 fa fa-asterisk secondry-text text-xs text-[8px]" /> */}
                                                  </label>
                                                </div>
                                              </div>
                                              <div className="w-full">
                                                <div className="form-group mb-6 ">
                                                  <label className="formBlock	">
                                                    Total allowance (revised):{" "}
                                                    {
                                                      employeeLeftDetailsData
                                                        ?.data
                                                        ?.remaining_leave_allowance
                                                    }
                                                    {/* <span className=" relative -top-2 fa fa-asterisk secondry-text text-xs text-[8px]" /> */}
                                                  </label>
                                                </div>
                                              </div>
                                              <div className="w-full">
                                                <div className="form-group mb-6 ">
                                                  <label className="formBlock	">
                                                    Leave taken:{" "}
                                                    {
                                                      employeeLeftDetailsData
                                                        ?.data
                                                        ?.leave_taken_in_days
                                                    }{" "}
                                                    days
                                                    {/* <span className=" relative -top-2 fa fa-asterisk secondry-text text-xs text-[8px]" /> */}
                                                  </label>
                                                </div>
                                                <div className="w-full">
                                                  <div className="form-group mb-6 ">
                                                    <label className="formBlock	">
                                                      Leave remaining:{" "}
                                                      {
                                                        employeeLeftDetailsData
                                                          ?.data
                                                          ?.remaining_leave_allowance
                                                      }
                                                      {/* <span className=" relative -top-2 fa fa-asterisk secondry-text text-xs text-[8px]" /> */}
                                                    </label>
                                                  </div>
                                                </div>
                                              </div>
                                              <div className="lg:w w-full  ">
                                                <div className="w-full mt-4">
                                                  <div className="form-group pt-4 pb-3 flex justify-center ">
                                                    <PrimaryButton
                                                      Btnclass="btn save mr-2"
                                                      btnText="Save"
                                                      onClick={
                                                        handelLeaveEmployee
                                                      }
                                                      type="button"
                                                      buttonID="empLeftSave"
                                                    />
                                                    <button
                                                      type="button"
                                                      onClick={() => {
                                                        close();
                                                        setIsNext(false);
                                                      }}
                                                      className="btn cancel "
                                                      id="empLeftCancel"
                                                    >
                                                      Cancel
                                                    </button>
                                                  </div>
                                                </div>
                                              </div>
                                            </div>
                                          ) : (
                                            <form>
                                              <div className="flex flex-wrap ">
                                                <div className="w-full">
                                                  <div className="form-group mb-6 ">
                                                    <label className="formBlock	">
                                                      Leaving Date{" "}
                                                      <span className=" relative -top-2 fa fa-asterisk secondry-text text-xs text-[8px]" />
                                                    </label>
                                                    <DateFiled
                                                      name="leaving_date"
                                                      id="leavingDate"
                                                      dateFormat="dd/MM/yyyy"
                                                      value={
                                                        employeeInfo?.leaving_date
                                                      }
                                                      handlerChange={(date) =>
                                                        handleDate(
                                                          date,
                                                          "leaving_date"
                                                        )
                                                      }
                                                    />
                                                    {/* <div className="help-block text-red-700" >
                                                    {errorValidations?.leaveDateError}
                                                  </div> */}
                                                  </div>
                                                </div>
                                                <div className="w-full">
                                                  <div className="form-group mb-6 ">
                                                    <label className="formBlock	">
                                                      Note(optional)
                                                    </label>
                                                    <FormTextarea
                                                      textName="notes"
                                                      value={
                                                        employeeInfo?.notes
                                                      }
                                                      inputID="empLeftNote"
                                                      handlerChange={
                                                        handelChange
                                                      }
                                                    />
                                                    <div className="help-block" />
                                                  </div>
                                                </div>

                                                <div className="lg:w w-full  ">
                                                  <div className="w-full mt-4">
                                                    <div className="form-group pt-4 pb-3 flex justify-center ">
                                                      <PrimaryButton
                                                        Btnclass="btn save mr-2"
                                                        btnText="Next"
                                                        onClick={
                                                          handleLeaveDetails
                                                        }
                                                        type="button"
                                                        buttonID="empLeftSave"
                                                      />
                                                      <button
                                                        type="button"
                                                        onClick={() => close()}
                                                        className="btn cancel "
                                                        id="empLeftCancel"
                                                      >
                                                        Cancel
                                                      </button>
                                                    </div>
                                                  </div>
                                                </div>
                                              </div>
                                            </form>
                                          )}
                                        </div>
                                      </div>
                                    )}
                                  </Popup>
                                </div>
                              ) : null}
                            </>
                          ) : (
                            ""
                          )}
                        </div>
                      </div>
                      <div className="employeeTab">
                        <ul>
                          <li>
                            <span
                              onClick={() => toggleTab("Overview")}
                              className={
                                toggleState === "Overview" ? " active" : "  "
                              }
                            >
                              Overview
                            </span>
                          </li>
                          {employeeData?.data?.length > 0 &&
                          employeeData?.data[0]?.id !== employee_data ? (
                            <li>
                              <span
                                onClick={() => {
                                  toggleTab("Approver");
                                  let payload = `employee/approver/list/?department_id=${employeeData?.data[0]?.department_id}&exclude_employee_id=${id}`;

                                  employeeInfo.department_id
                                    ? getApproverListAPI(payload)
                                    : null;
                                }}
                                className={
                                  toggleState === "Approver" ? "  active" : " "
                                }
                              >
                                Approver
                              </span>
                            </li>
                          ) : (
                            ""
                          )}
                          <li>
                            <span
                              onClick={() => {
                                toggleTab("WorkingSchedule");
                                // handelWorkingSchedule();
                              }}
                              className={
                                toggleState === "WorkingSchedule"
                                  ? "  active"
                                  : " "
                              }
                            >
                              Working Schedule
                            </span>
                          </li>
                          <li>
                            <span
                              onClick={() => toggleTab("BankHoliday")}
                              className={
                                toggleState === "BankHoliday" ? "  active" : " "
                              }
                            >
                              Bank Holidays
                            </span>
                          </li>
                          <li>
                            <span
                              onClick={() => toggleTab("Leave")}
                              className={
                                toggleState === "Leave" ? "  active" : " "
                              }
                            >
                              Allowances
                            </span>
                          </li>

                          <li>
                            <span
                              onClick={() => toggleTab("Notes")}
                              className={
                                toggleState === "Notes" ? "  active" : " "
                              }
                            >
                              Notes
                            </span>
                          </li>
                          <li>
                            <span
                              onClick={() => toggleTab("History")}
                              className={
                                toggleState === "History" ? "active" : " "
                              }
                            >
                              History
                            </span>
                          </li>
                          <li>
                            <span
                              onClick={() => toggleTab("EmployeeDocument")}
                              className={
                                toggleState === "EmployeeDocument"
                                  ? "active"
                                  : " "
                              }
                            >
                              Documents
                            </span>
                          </li>
                          <li>
                            <span
                              onClick={() => toggleTab("timeInLieu")}
                              className={
                                toggleState === "timeInLieu" ? "active" : " "
                              }
                            >
                              Time in lieu
                            </span>
                          </li>
                          <li>
                            <span
                              onClick={() => toggleTab("myAbsence")}
                              className={
                                toggleState === "myAbsence" ? "active" : " "
                              }
                            >
                              My Absence
                            </span>
                          </li>

                          {(roleIdData == REACT_APP_COMPANY_ADMIN_ROLE_ID ||
                            roleIdData == REACT_APP_SUPER_ADMIN_ROLE_ID) && (
                            <li>
                              <span
                                onClick={() => toggleTab("trends")}
                                className={
                                  toggleState === "trends" ? "active" : " "
                                }
                              >
                                Trends
                              </span>
                            </li>
                          )}
                        </ul>
                      </div>
                    </div>
                    <div className="card bg-white    mb-3">
                      <div className="tabCard ">
                        <div
                          className={
                            toggleState === "Overview"
                              ? "tabContent tabContentActive"
                              : "tabContent"
                          }
                        >
                          <div className="heading_title px-7 justify-between flex  items-center py-4 hidden">
                            <h3>Profile Details</h3>
                            <div className="headerButton" />
                          </div>
                          <ProfileDetails
                            employeeInfo={employeeInfo}
                            handelChange={handelChange}
                            getEmployeeDepartments={getEmployeeDepartments}
                            setEmployeeInfo={setEmployeeInfo}
                            getEmployeeDetail={getEmployeeDetail}
                            updateEmployeeById={updateEmployeeById}
                            isProfileUpdate={isProfileUpdate}
                            activeEmployeeUpdatePermissions={
                              activeEmployeeUpdatePermissions
                            }
                            file={file}
                          />
                        </div>

                        <div
                          className={
                            toggleState === "Approver"
                              ? "tabContent tabContentActive"
                              : "tabContent"
                          }
                        >
                          <div className="heading_title px-7 justify-between flex  items-center py-4 hidden">
                            <h3>Approver</h3>
                            <div className="headerButton" />
                          </div>
                          <ApproverEmployee
                            employeeInfo={employeeInfo}
                            handelChange={handelChange}
                            getApproverListAPI={getApproverListAPI}
                            setEmployeeInfo={setEmployeeInfo}
                            updateEmployeeById={updateEmployeeById}
                            approverList={approverList}
                            isApproveSuccess={isApproveSuccess}
                            errorValidations={errorValidations}
                            isLeave={isLeave}
                            setIsLeave={setIsLeave}
                            isExpenses={isExpenses}
                            setIsExpense={setIsExpense}
                            isOverTime={isOverTime}
                            isSetOverTime={isSetOverTime}
                            setIsSame={setIsSame}
                            isSame={isSame}
                            activeEmployeeUpdatePermissions={
                              activeEmployeeUpdatePermissions
                            }
                            //  employeeData ={employeeData}
                            //  leaveApprover = { leaveApprover}
                            //  setLeaveApprover = {setLeaveApprover}
                            isProfileError={isProfileError}
                            isprofileLoading={isprofileLoading}
                            profileError={profileError}
                          />
                        </div>
                        <div
                          className={
                            toggleState === "WorkingSchedule"
                              ? "tabContent tabContentActive"
                              : "tabContent"
                          }
                        >
                          <div className="heading_title px-7 justify-between flex  items-center py-4 hidden">
                            <h3>Working Schedule</h3>
                            <div className="headerButton" />
                          </div>
                          <div className="md:p-7 p-4">
                            <WorkScheduleToggle
                              state={state}
                              employeeInfo={employeeInfo}
                              handelChange={handelChange}
                              activeEmployeeUpdatePermissions={
                                activeEmployeeUpdatePermissions
                              }
                            />
                          </div>
                        </div>
                        <div
                          className={
                            toggleState === "Leave"
                              ? "tabContent tabContentActive"
                              : "tabContent"
                          }
                        >
                          <div className="heading_title px-7 justify-between flex  items-center py-4 hidden">
                            <h3>Leave History</h3>
                            <div className="headerButton" />
                          </div>
                          <UserAllowances
                            employeeInfo={employeeInfo}
                            activeEmployeeUpdatePermissions={
                              activeEmployeeUpdatePermissions
                            }
                          />
                        </div>
                        <div
                          className={
                            toggleState === "BankHoliday"
                              ? "tabContent tabContentActive"
                              : "tabContent"
                          }
                        >
                          <div className="heading_title px-7 justify-between flex  items-center py-4 hidden">
                            <h3>Bank Holidays</h3>
                            <div className="headerButton" />
                          </div>

                          <EmployeeBankHoliday
                            employeeInfo={employeeInfo}
                            setEmployeeInfo={setEmployeeInfo}
                            employeeData={employeeData}
                            activeEmployeeUpdatePermissions={
                              activeEmployeeUpdatePermissions
                            }
                            toggleState={toggleState}
                          />
                        </div>
                        <div
                          className={
                            toggleState === "Settings"
                              ? "tabContent tabContentActive"
                              : "tabContent"
                          }
                        >
                          <div className="heading_title px-7 justify-between flex  items-center py-4 hidden">
                            <h3>Settings </h3>
                            <div className="headerButton" />
                          </div>
                          <div className="md:p-7 p-4">
                            <div className="flex flex-wrap mt-2 ">
                              <div className=" md:w-1/2 md:pr-6 w-full">
                                <div className="form-group mb-6 ">
                                  <label className="formBlock	">
                                    Change Date Format
                                  </label>
                                  <select
                                    className="formControl"
                                    id="changeDate"
                                  >
                                    <option>MM/DD/YYYY</option>
                                    <option>DD/MM/YYYY</option>
                                    <option>YYYY/MM/DD</option>
                                  </select>
                                  <div className="help-block" />
                                </div>
                              </div>
                            </div>
                            {activeEmployeeUpdatePermissions[0]?.status ===
                              true && (
                              <div className="lg:w w-full  ">
                                <div className="w-full mt-8">
                                  <div className="form-group pt-8 pb-3 flex border-t  ">
                                    <PrimaryButton
                                      Btnclass="btn save mr-2"
                                      btnText="Save"
                                      buttonID="settingSave"
                                    />
                                    <PrimaryButton
                                      btnText="Cancel"
                                      Btnclass="btn cancel "
                                      buttonID="settingCancel"
                                      // onClick={handelCancle}
                                    />
                                  </div>
                                </div>
                              </div>
                            )}
                          </div>
                        </div>
                        <div
                          className={
                            toggleState === "History"
                              ? "tabContent tabContentActive"
                              : "tabContent"
                          }
                        >
                          <EmployeeHistory />
                        </div>
                        <div
                          className={
                            toggleState === "EmployeeDocument"
                              ? "tabContent tabContentActive"
                              : "tabContent"
                          }
                        >
                          <EmployeeDocument />
                        </div>
                        <div
                          className={
                            toggleState === "Notes"
                              ? "tabContent tabContentActive"
                              : "tabContent"
                          }
                        >
                          <EmployeeNotes
                            activeEmployeeUpdatePermissions={
                              activeEmployeeUpdatePermissions
                            }
                            employeeInfo={employeeInfo}
                            toggleTab={toggleTab}
                          />
                        </div>
                        <div
                          className={
                            toggleState === "timeInLieu"
                              ? "tabContent tabContentActive"
                              : "tabContent"
                          }
                        >
                          <TimeInLieu employeeInfo={employeeInfo} />
                        </div>
                        <div
                          className={
                            toggleState === "myAbsence"
                              ? "tabContent tabContentActive"
                              : "tabContent"
                          }
                        >
                          <MyAbsenceEmployee employeeInfo={employeeInfo} />
                        </div>
                        <div
                          className={
                            toggleState === "trends"
                              ? "tabContent tabContentActive"
                              : "tabContent"
                          }
                        >
                          <EmployeeTrends employeeInfo={employeeInfo} />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </>
            ) : (
              <LoadingIndicator />
            )}
          </div>
        )}
      </div>
    </>
  );
};

export default EmployeeDetails;
