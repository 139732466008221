// eslint-disable-next-line no-unused-vars
/* eslint-disable no-debugger */
/* eslint-disable */
//eslint-disable-next-line no-undef
import {useEffect, useState, useRef} from "react";
import FullCalendar from "@fullcalendar/react";
import interactionPlugin from "@fullcalendar/interaction";
import multiMonthPlugin from "@fullcalendar/multimonth";
import daysGridPlugin from "@fullcalendar/daygrid";
import moments from "moment";
import Popup from "reactjs-popup";
import {FormTextarea} from "../../components/formInput";
import moment from "moment";
import PrimaryButton from "../../components/primaryButton";
import {toast} from "react-toastify";
import {useChangesStatusApporveMutation} from "../../services/approverListApi";
import {getDay} from "date-fns";
import {useSelector} from "react-redux";
import {REACT_APP_AUDITOR_ROLE_ID} from "../../helper/constant";

const FullCalendar2 = ({
  dateOfBirth,
  setOpen,
  setSelectedYearCalendar,
  leaveData,
  getEmployeeAllDayScheduleRespo,
  handleDateChange,
  filterEmployeeById,
  employeeId,
  allHolidayData,
  workSchedule,
  id,
  employeeStartDate,
  roleIdData,
  weekOffData
}) => {
  const [
    changesStatusApporve,
    {isLoading, isSuccess, isError, error: apiError}
  ] = useChangesStatusApporveMutation();
  const [events, setEvents] = useState([]);
  const calendarRef = useRef();
  const [leaveDataShow, setLeaveDataShow] = useState();
  const closeModal = () => {
    setOpen(false);
    setOpenPopUp(false);
  };
  const [openPopup, setOpenPopUp] = useState(false);

  let eventData = [];
  let eventDate = [];
  const [totalEventDate, setTotalEventDate] = useState([]);
  const [weekOffSet, setWeekOffSet] = useState();
  useEffect(() => {
    if (weekOffData?.off_days) {
      setWeekOffSet(
        new Set(
          weekOffData?.off_days?.map((date) =>
            moment(date).format("YYYY-MM-DD")
          )
        )
      );
    }
  }, [weekOffData]);
  // const weekOffSet = new Set(weekOffData?.off_days?.map((date) => moment(date).format("YYYY-MM-DD")));
  // Optimized day styling function
  const handleDayCellDidMount = (info) => {
    const dayDate = moment(info?.date).format("YYYY-MM-DD");

    // If the day is a week off, change its background and text color
    if (weekOffSet?.has(dayDate)) {
      info.el.style.backgroundColor = "rgb(209 209 209)";
      info.el.style.color = "#ddd";
    }
  };
  // Combine all event creation into a single useEffect
  useEffect(() => {
    const combinedEvents = [];
    const totalEventDate = [];

    // Helper function to add events
    const addEvent = (event) => {
      combinedEvents.push(event);
      totalEventDate.push(moment(event.start).format("YYYY-MM-DD"));
    };

    // Joining Date Event
    if (employeeStartDate) {
      const joinEvent = {
        title: JSON.stringify({
          leaveType: "Joining Date",
          icon: "fas fa-glass-cheers"
        }),
        start: new Date(
          `${moment(employeeStartDate).format("YYYY-MM-DD")} 10:00`
        ),
        end: new Date(
          `${moment(employeeStartDate).format("YYYY-MM-DD")} 19:00`
        ),
        backgroundColor: "orange",
        icon: "fas fa-glass-cheers"
      };
      addEvent(joinEvent);
    }

    // Birthday Event
    if (dateOfBirth) {
      const dobEvent = {
        title: JSON.stringify({
          leaveType: "Birthday",
          icon: "fa fa-birthday-cake"
        }),
        start: new Date(`${moment(dateOfBirth).format("YYYY-MM-DD")} 10:00`),
        end: new Date(`${moment(dateOfBirth).format("YYYY-MM-DD")} 19:00`),
        backgroundColor: "red",
        icon: "fa fa-birthday-cake"
      };
      addEvent(dobEvent);
    }

    // Leave Events
    leaveData?.forEach((leave) => {
      if (leave?.status === "0" || leave?.status === "1") {
        const leaveEvent = {
          id: leave?.id,
          title: JSON.stringify({
            leaveType: leave?.leaveTypeInfo?.leave_name || "Absence",
            icon:
              leave?.status === "1"
                ? leave.leaveTypeInfo?.icon || "fa fa-times-circle"
                : "fa-sharp fa-solid fa-r"
          }),
          start: new Date(
            `${moment(leave?.start_date).format("YYYY-MM-DD")} ${
              leave?.start_time
            }`
          ),
          end: new Date(
            `${moment(leave.start_date).format("YYYY-MM-DD")} ${leave.end_time}`
          ),
          isHalfDay: parseFloat(leave.leave_day) < 1,
          halfDayPercentage: parseFloat(leave.leave_day) * 100,
          backgroundColor: leave.leaveTypeInfo?.color || "red",
          icon: "fa fa-times-circle"
        };
        addEvent(leaveEvent);
      }
    });

    // Holiday Events
    allHolidayData?.data?.forEach((holiday) => {
      const startDate = holiday.start_date.split("T")[0];
      const endDate = holiday.end_date.split("T")[0];
      const holidayEvent = {
        id: holiday.id,
        title: JSON.stringify({
          leaveType: holiday.name,
          icon: "fa-sharp fa-solid fa-b"
        }),
        start: new Date(`${startDate} 10:00`),
        end: new Date(`${startDate} 19:00`),
        backgroundColor: "red",
        icon: "fa-sharp fa-solid fa-b"
      };
      addEvent(holidayEvent);
    });

    // Weekoff Events
    weekOffSet?.forEach((weekoffDate) => {
      const weekoffEvent = {
        title: JSON.stringify({
          leaveType: "Weekoff",
          icon: "fas fa-calendar-times"
        }),
        start: new Date(`${weekoffDate} 10:00`),
        end: new Date(`${weekoffDate} 19:00`),
        backgroundColor: "rgb(209 209 209)",
        icon: "fas fa-calendar-times"
      };
      addEvent(weekoffEvent);
    });

    // Update state with all combined events
    setEvents(combinedEvents);
  }, [employeeStartDate, dateOfBirth, leaveData, allHolidayData, weekOffSet]);

  const handleSelect = (info) => {
    setOpen(false);
    let day =
      workSchedule &&
      workSchedule?.find(
        (f) => parseInt(f.day) === getDay(new Date(info.start))
      );
    if (totalEventDate?.indexOf(info?.startStr) > -1 || day?.is_weekend === 1) {
      setOpen(false);
    } else {
      handleDateChange(info);
      if (id === undefined) {
        setOpen(true);
      }
    }
  };

  const handlerShow = (eventId) => {
    setOpenPopUp(true);
    let leave_data = leaveData?.find((l) => l?.id == eventId);
    let holiday_data = allHolidayData?.data?.find((l) => l?.id == eventId);

    if (holiday_data?.location_id !== undefined) {
      setLeaveDataShow(holiday_data);
    } else {
      setLeaveDataShow(leave_data);
    }
  };

  const toastId = useRef(null);
  useEffect(
    () => {
      if (isLoading) {
        toast.dismiss(toastId.current);
        toastId.current = toast.loading("Please wait...", {
          position: "top-center",
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          theme: "colored",
          type: "info"
        });
      }
      if (isSuccess) {
        setTimeout(() => {
          toast.dismiss(toastId.current);
        }, 10000);
        toast.update(
          toastId.current,
          ("Leave Cancelled Successfully.",
          {
            render: "Leave Cancelled Successfully.",
            type: "success",
            autoClose: 10000,
            isLoading: false
          })
        );
        filterEmployeeById(employeeId);
      }
      if (isError) {
        const toastMessage = apiError.data.message
          ? apiError.data.message
          : "Something went wrong";
        toast.update(
          toastId.current,
          (toastMessage,
          {
            render: toastMessage,
            type: "error",
            autoClose: 8000,
            isLoading: false,
            duplicate: false,
            delay: 10
          })
        );
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [isSuccess, isError, isLoading]
  );

  const cancelEvent = (e, formData, close, setError, error) => {
    e.preventDefault();
    if (formData.notes !== "") {
      setError({...error, ["notesError"]: ""});
      let val = {
        status: "2",
        cancel_reason: formData.notes,
        employee_id: `${employeeId}`,
        id: leaveDataShow.id
      };
      changesStatusApporve(val);

      close();
      setOpenPopUp(false);
    } else {
      setError({...error, ["notesError"]: "please enter the notes"});
    }
  };

  const handleEventMouseEnter = (mouseEnterInfo) => {
    const eventTitle = mouseEnterInfo?.event?.title;
    const eventId = mouseEnterInfo?.event?.id;

    // Extract the relevant details from the event title (assuming it's a JSON string)
    const details = JSON.parse(eventTitle);

    // Show details using your preferred method (e.g., tooltip, custom component)
    // You might want to replace the following line with your own logic for showing details.
    alert(`Event ID: ${eventId}\nLeave Type: ${details?.leaveType}`);
  };

  return (
    <>
      <Popup
        open={openPopup}
        onClose={() => closeModal()}
        closeOnDocumentClick={false}
        modal
        nested
      >
        {(close) => (
          <div className="modal">
            <button
              className="close"
              id="fullCalendarClose"
              onClick={() => {
                closeModal();
                close();
              }}
            >
              &times;
            </button>
            <LeaveInfo
              leaveDataShow={leaveDataShow}
              setOpenPopUp={setOpenPopUp}
              cancelEvent={cancelEvent}
              getEmployeeAllDayScheduleRespo={getEmployeeAllDayScheduleRespo}
              roleIdData={roleIdData}
            />
          </div>
        )}
      </Popup>

      <FullCalendar
        editable
        selectable
        events={events}
        firstDay="1"
        select={handleSelect}
        allDay={true}
        // eventMouseLeave= {eventRender}
        dayCellDidMount={handleDayCellDidMount}
        eventContent={(events) => (
          <EventItem
            events={events}
            handlerShow={handlerShow}
            workSchedule={workSchedule}
          />
        )}
        multiMonthMaxColumns={7}
        plugins={[interactionPlugin, multiMonthPlugin, daysGridPlugin]}
        ref={calendarRef}
        eventMouseEnter={handleEventMouseEnter}
        datesSet={(args) => {
          const dateObject = new Date(args?.startStr);

          // Get the year from the Date object
          const year = dateObject.getFullYear();
          setSelectedYearCalendar(year);
        }}
      />
    </>
  );
};
export default FullCalendar2;

const EventItem = ({events, handlerShow}) => {
  let leave_data = events?.event?.title ? JSON.parse(events?.event?.title) : "";
  useEffect(
    () => {
      let leaveTitle = events?.event?.title
        ? JSON.parse(events?.event?.title)
        : "";
      if (events.event.start <= events.event.end) {
        let currentDate = new Date(events.event.start);
        while (currentDate <= events.event.end) {
          let current_date = moments(currentDate).format("YYYY-MM-DD");

          // Check if the current date is a weekend
          // const weekendDates = workSchedule?.filter(
          //   (item) => item?.is_weekend === 1
          // );
          // const weekendDays = weekendDates?.map((item) =>
          //   item?.day === "7" ? 0 : parseInt(item?.day)
          // );

          // let isWeekend = weekendDays?.includes(currentDate.getDay());

          // if (!isWeekend) {
          let elements = document.querySelectorAll(
            '[data-date="' + current_date + '"]'
          );
          for (let i = 0; i < elements.length; i++) {
            if (events?.event?.extendedProps?.isHalfDay) {
              elements[i].style.background = `linear-gradient(-45deg, ${
                events.backgroundColor
              } ${
                events?.event?.extendedProps?.halfDayPercentage < 30
                  ? 30
                  : events?.event?.extendedProps?.halfDayPercentage
              }%, ${events.backgroundColor} ${
                events?.event?.extendedProps?.halfDayPercentage < 30
                  ? 30
                  : events?.event?.extendedProps?.halfDayPercentage
              }%, ${"#ffffff"} ${
                events?.event?.extendedProps?.halfDayPercentage < 30
                  ? 30
                  : events?.event?.extendedProps?.halfDayPercentage
              }%, #ffffff ${
                100 - events?.event?.extendedProps?.halfDayPercentage > 30
                  ? 70
                  : 100 - events?.event?.extendedProps?.halfDayPercentage
              }%)`;

              if (events?.event?.extendedProps?.halfDayPercentage < 40) {
                elements[i].style.color = "black";
              } else {
                elements[i].style.color = "#ffffff";
              }
            } else {
              elements[i].style.backgroundColor = events.backgroundColor;
              elements[i].style.color = "#fff";
            }

            elements[i].style.position = "relative";
            elements[i].innerHTML =
              '<div class="text-center calIcon" ><span class="tooltip">' +
              leaveTitle?.leaveType +
              '</span><span><i class="' +
              leave_data?.icon +
              '"></i></span></div>';

            // Add click event to show details when clicked
            elements[i]?.addEventListener("click", function () {
              handlerShow(events?.event?.id);
            });
          }
          // }

          currentDate.setDate(currentDate.getDate() + 1);
        }
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [events]
  );

  return <></>;
};

const LeaveInfo = ({leaveDataShow, cancelEvent, roleIdData}) => {
  const formattedDate = useSelector(
    (state) => state?.persistedReducer?.userPermissionsStates?.dateFormats
  );
  const handlerSave = (e) => {
    e.preventDefault();
    setOpen(true);
  };

  const [open, setOpen] = useState(false);
  const [openCancel, setOpenCancel] = useState(false);
  const [formData, setFormData] = useState({
    notes: "",
    id: leaveDataShow?.id
  });
  const [error, setError] = useState({
    notesError: ""
  });
  const closeModal = () => {
    setOpen(false);
  };
  const closeModalcancel = () => {
    setOpenCancel(false);
  };

  const handlerChange = (e) => {
    e.preventDefault();
    if (e.target.Name === "notes") {
      if (e.target === "") {
        setError({...error, ["notesError"]: "Please enter the notes"});
      } else {
        setError({...error, ["notesError"]: ""});
      }
    }
    setFormData({...formData, [e.target.name]: e.target.value});
  };
  //let leaveDuration = Math.floor(leaveDataShow.total_duration/(60)/getEmployeeAllDayScheduleRespo?.dayHours) === leaveDataShow.total_duration/(60)/getEmployeeAllDayScheduleRespo?.dayHours ? leaveDataShow.total_duration/(60)/getEmployeeAllDayScheduleRespo?.dayHours : (leaveDataShow.total_duration/(60)/getEmployeeAllDayScheduleRespo?.dayHours).toFixed(1);

  return (
    <>
      {!leaveDataShow?.location_id ? (
        <>
          <div className="header">
            <span>
              <h3>
                {leaveDataShow?.location_id
                  ? "Holiday Details"
                  : leaveDataShow?.leaveTypeInfo === null
                  ? "Absence Details"
                  : "Leave Details"}
              </h3>
            </span>
          </div>
          <div className="stepIndicator">
            <ul>
              <li className="active">
                <span>1</span>Request
              </li>
              <li>
                <span>2</span>Details
              </li>
            </ul>
          </div>
          <div className="content">
            <form>
              <div className="flex flex-wrap ">
                {leaveDataShow?.leaveTypeInfo === null ? (
                  ""
                ) : (
                  <div className=" w-full ">
                    <div className="form-group mb-6 ">
                      <label className="formBlock	">
                        Type{" "}
                        <span className=" relative -top-2 fa fa-asterisk secondry-text text-xs text-[8px]" />
                      </label>
                      <input
                        className="formControl"
                        value={leaveDataShow?.leaveTypeInfo?.leave_name}
                        disabled
                      />
                    </div>
                  </div>
                )}
                <div className=" w-full ">
                  <div className="mb-2">
                    <div className="flex">
                      <input
                        type="checkbox"
                        checked={leaveDataShow?.is_private === "1"}
                        className="mr-2 w-5 h-5"
                        id="private"
                      />
                      <label className="formBlock">Private</label>
                    </div>
                  </div>
                  <div className="w-full ">
                    <div className="form-group mb-4 ">
                      <label className="formBlock ">Reason (optional) </label>
                      <input
                        value={leaveDataShow?.reason}
                        className="formControl"
                      />
                      <div className="help-block" />
                    </div>
                  </div>
                  <div className="form-group mb-4 ">
                    <div className="flex">
                      <div className="w-full">
                        <label className="formBlock">
                          Select Date
                          <span className="relative -top-2 fa fa-asterisk secondry-text text-xs text-[8px]" />
                        </label>
                        <input
                          className="formControl"
                          value={`${moment(leaveDataShow?.start_date).format(
                            formattedDate
                          )}`}
                        />
                      </div>
                    </div>
                  </div>
                </div>
                <div className="lg:w w-full  ">
                  <div className="w-full">
                    <div className="form-group pt-4 pb-3 flex justify-center ">
                      <PrimaryButton
                        btnText="Next"
                        Btnclass="btn save mr-2"
                        btnype="button"
                        buttonID="next"
                        onClick={(e) => handlerSave(e)}
                      />
                      {/* <PrimaryButton
            btnText="Back"
            onClick={() => close()}
            Btnclass="btn cancel"
            buttonID="leaveCancel"
            btntype="button"
          /> */}
                    </div>
                  </div>
                </div>
                <Popup
                  open={open}
                  onClose={() => closeModal()}
                  closeOnDocumentClick={false}
                  className="leavePopup"
                  modal
                  nested
                >
                  {(close) => (
                    <div className="modal">
                      <button
                        className="close"
                        id="requestLeaveClose"
                        onClick={() => {
                          closeModal();
                          close();
                        }}
                      >
                        &times;
                      </button>
                      <div
                        style={{
                          maxHeight: "70vh",
                          overflowY: "auto",
                          paddingBottom: "24px"
                        }}
                      >
                        <div className="stepIndicator">
                          <ul>
                            <li>
                              <span>1</span>Request
                            </li>
                            <li className="active">
                              <span>2</span>Details
                            </li>
                          </ul>
                        </div>
                        <div className="p-6">
                          <h3 className="text-lg primary-text mb-4">
                            Leave Details
                          </h3>
                          <div>
                            <div>
                              <div className="w-full flex justify-between pb-4  md:flex-nowrap flex-wrap ">
                                <div className="w-1/3">
                                  <h3 className="text-base text-left pt-1">
                                    {moment(leaveDataShow?.start_date).format(
                                      formattedDate
                                    )}
                                  </h3>
                                </div>
                                <div className=" md:w-2/3 w-full pt-2 md:pt-0 md:pl-4  ">
                                  <div className="flex justify-between items-center w-full">
                                    <input
                                      className="formControl"
                                      value={moment(
                                        leaveDataShow?.start_time,
                                        "hh:mm:ss"
                                      ).format("HH:mm")}
                                    />
                                    <div>to</div>

                                    <input
                                      className="formControl"
                                      value={moment(
                                        leaveDataShow?.end_time,
                                        "hh:mm:ss"
                                      ).format("HH:mm")}
                                    />
                                  </div>
                                  <div className="w-full mt-2">
                                    <div className="timeDiv">
                                      <span className="fa fa-clock secondry-text" />
                                      <span>
                                        <span className="secondry-text">
                                          {Math.floor(
                                            leaveDataShow?.total_duration / 60
                                          )}
                                        </span>{" "}
                                        Hours{" "}
                                        <span className="secondry-text pl-2 pr-1">
                                          {leaveDataShow?.total_duration % 60}
                                        </span>{" "}
                                        Minutes
                                      </span>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="px-6 text-right pb-4">
                          <h1 className="text-lg">
                            Total:{" "}
                            {Math.floor(leaveDataShow?.total_duration / 60)}{" "}
                            hours {leaveDataShow?.total_duration % 60} minutes
                          </h1>
                          <p className="ml-10">Annual Leave</p>
                        </div>
                      </div>
                      {roleIdData !== REACT_APP_AUDITOR_ROLE_ID && (
                        <div className="lg:w w-full  ">
                          <div className="w-full mb-4">
                            <div className="form-group pt-4 pb-3 flex justify-center ">
                              <div className="w-full text-right mt-2 mr-[200px]">
                                {leaveDataShow?.location_id ? (
                                  " "
                                ) : (
                                  <Popup
                                    open={openCancel}
                                    onClose={() => closeModalcancel()}
                                    closeOnDocumentClick={false}
                                    trigger={
                                      <div
                                        onClick={() => setOpenCancel((o) => !o)}
                                      >
                                        <button className="btn cancel ">
                                          Cancel Leave
                                        </button>
                                      </div>
                                    }
                                    modal
                                    nested
                                  >
                                    {(close) => (
                                      <div className="modal">
                                        <button
                                          className="close"
                                          id="cancelLeaveClose"
                                          onClick={() => {
                                            closeModalcancel();
                                            closeModal();
                                            close();
                                          }}
                                        >
                                          &times;
                                        </button>
                                        <div className="header">
                                          {" "}
                                          <h3>Cancel Leave</h3>
                                        </div>
                                        <div className="content">
                                          <form>
                                            <label className="formBlock	">
                                              Reason
                                              <span className=" relative -top-2 fa fa-asterisk secondry-text text-xs text-[8px]" />
                                            </label>
                                            <FormTextarea
                                              textName="notes"
                                              id="reason"
                                              handlerChange={handlerChange}
                                            />
                                            <div className="help-block text-red-700 mt-1">
                                              {error.notesError
                                                ? error.notesError
                                                : ""}
                                            </div>
                                            <div className="w-full mt-8">
                                              <div className="form-group pt-4 pb-3 flex justify-center ">
                                                <button
                                                  id="submit"
                                                  className="btn save mr-2"
                                                  onClick={(e) =>
                                                    cancelEvent(
                                                      e,
                                                      formData,
                                                      close,
                                                      setError,
                                                      error
                                                    )
                                                  }
                                                >
                                                  Submit
                                                </button>
                                                <button
                                                  className="btn cancel mr-2"
                                                  id="cancel"
                                                  onClick={() => {
                                                    close();
                                                    setOpenCancel(false);
                                                  }}
                                                >
                                                  Cancel
                                                </button>
                                              </div>
                                            </div>
                                          </form>
                                        </div>
                                      </div>
                                    )}
                                  </Popup>
                                )}
                              </div>
                            </div>
                          </div>
                        </div>
                      )}
                    </div>
                  )}
                </Popup>
              </div>
            </form>
          </div>
        </>
      ) : (
        <>
          <div className="header">
            <span>
              <h3>
                {leaveDataShow?.location_id
                  ? "Holiday Details"
                  : "Leave Details"}
              </h3>
            </span>
          </div>

          <div className="stepIndicator">
            <ul>
              <li className="active">
                <span>1</span>Request
              </li>
              <li>
                <span>2</span>Details
              </li>
            </ul>
          </div>
          <div className="content">
            <form>
              <div className="flex flex-wrap ">
                <div className=" w-full ">
                  <div className="form-group mb-6 ">
                    <label className="formBlock	">
                      Type{" "}
                      <span className=" relative -top-2 fa fa-asterisk secondry-text text-xs text-[8px]" />
                    </label>
                    <input
                      className="formControl"
                      value={leaveDataShow?.name}
                      disabled
                    />
                  </div>
                </div>

                <div className=" w-full ">
                  <div className="mb-2">
                    <div className="flex">
                      <input
                        type="checkbox"
                        checked={leaveDataShow?.is_private === "1"}
                        className="mr-2 w-5 h-5"
                        id="private"
                      />
                      <label className="formBlock">Private</label>
                    </div>
                  </div>
                  <div className="w-full ">
                    <div className="form-group mb-4 ">
                      <label className="formBlock ">Reason (optional) </label>
                      <input
                        value={leaveDataShow?.reason}
                        className="formControl"
                      />
                      <div className="help-block" />
                    </div>
                  </div>
                  <div className="form-group mb-4 ">
                    <div className="flex">
                      <div className="w-full">
                        <label className="formBlock">
                          Select Date
                          <span className="relative -top-2 fa fa-asterisk secondry-text text-xs text-[8px]" />
                        </label>
                        <input
                          className="formControl"
                          value={`${moment(leaveDataShow?.start_date).format(
                            formattedDate
                          )}`}
                        />
                      </div>
                    </div>
                  </div>
                </div>
                <div className="lg:w w-full  ">
                  <div className="w-full">
                    <div className="form-group pt-4 pb-3 flex justify-center "></div>
                  </div>
                </div>
              </div>
            </form>
          </div>
        </>
      )}
    </>
  );
};
