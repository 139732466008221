import {useEffect, useState, useRef} from "react";
import {Link} from "react-router-dom";
import PrimaryButton from "../../components/primaryButton";
import {
  useCreateLeaveAllowanceAllyearMutation,
  useEmployeeAllowanceToDaysMutation,
  useEmployeeAllowanceToHourMutation,
  useGetEmployeeAllowancesMutation,
  useUpdateEmployeeAllowancesMutation,
  useUpdateEmployeeByIdMutation
} from "../../services/employeeApi";
import {toast} from "react-toastify";
import Popup from "reactjs-popup";
import {useSelector} from "react-redux";
import {formatYearHelper} from "../../helper/helper";
import LoadingScreen from "../../helper/LoadingScreen";

const UserAllowances = ({
  employeeInfo,
  activeEmployeeUpdatePermissions,
  myprofileReadOnly = false
}) => {
  //Selector for carrying out data from redux
  const year = useSelector(
    (state) => state?.persistedReducer?.userPermissionsStates?.nextcarryForward
  );
  const roleIdData = useSelector(
    (state) => state?.persistedReducer?.userPermissionsStates?.user?.roleId
  );

  //States and hooks
  const [formData, setFormData] = useState({
    annual_allowance: "",
    maximum_leave_carry: ""
  });
  const [allYearData, setAllYearData] = useState({
    previousYearBroughtForward: "",
    previousYearAllowance: "",
    currentBroughtForward: "",
    currentYearAllowance: "",
    nextBroughtForward: "",
    nextAllowance: ""
  });

  const [open, setOpen] = useState(false);
  const closeModal = () => {
    setOpen(false);
  };
  const toastId = useRef(null);
  const current = new Date().getFullYear();

  //Current, next, previous year
  const [formattedYear, formattedNextYear] = formatYearHelper(year, 0);
  const [formattedYearPrevious, formattedNextYearPrevious] = formatYearHelper(
    year,
    -1
  );
  const [formattedYearNext, formattedNextYearNext] = formatYearHelper(year, 1);

  //Calling apis here
  const [getEmployeeAllowance, {data: data, isLoading}] =
    useGetEmployeeAllowancesMutation();
  const [
    createLeaveAllowanceAllyear,
    {isSuccess: createLeaveAllowanceAllyearSuccess}
  ] = useCreateLeaveAllowanceAllyearMutation();
  const [
    employeeAllowanceToHour,
    {
      isSuccess: allowanceToHourSuccess,
      isError: allowanceToHourError,
      isLoading: allowanceToHourLoading,
      error: allowanceToHourErrorAPI_ERROR
    }
  ] = useEmployeeAllowanceToHourMutation();
  const [
    employeeAllowanceToDays,
    {
      isSuccess: allowanceToDaysSuccess,
      isError: allowanceToDaysError,
      isLoading: allowanceToDaysLoading,
      error: allowanceToDaysErrorAPI_ERROR
    }
  ] = useEmployeeAllowanceToDaysMutation();
  const [
    updateEmployeeAllowances,
    {
      isLoading: isLoadingUpdate,
      isSuccess: isSuccessUpdate,
      isError: isErrorUpdate,
      error: apiError
    }
  ] = useUpdateEmployeeAllowancesMutation();
  const [updateEmployeeById,  {
    isLoading: isLoadingYearUpdate,
    isSuccess: isSuccessYearUpdate,
    isError: isErrorYearUpdate,
    error: apiYearError
  }] = useUpdateEmployeeByIdMutation();

  useEffect(() => {
    if (employeeInfo?.id) {
      getEmployeeAllowance(employeeInfo?.id);
    }
  }, [employeeInfo?.id]);

  useEffect(
    () => {
      if (isLoadingUpdate || isLoadingYearUpdate) {
        toastId.current = toast.loading("Please wait...", {
          position: "top-center",
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          theme: "colored",
          type: "info"
        });
      }
      if (isSuccessUpdate || isSuccessYearUpdate) {
        toast.dismiss(toastId.current);
        toast.success("Employee allowances has been updated successfully.", {
          render: "Employee allowances has been updated successfully.",
          type: "success",
          autoClose: 2000,
          isLoading: false,
          theme: "colored"
        });
        getEmployeeAllowance(employeeInfo?.id);
      }
      if (isErrorUpdate || isErrorYearUpdate) {
        const toastMessage = isErrorYearUpdate ? apiYearError.data.message : apiError.data.message
          ? apiError.data.message
          : "Something went wrong";
        toast.update(
          toastId.current,
          (toastMessage,
          {
            render: toastMessage,
            type: "error",
            autoClose: 5000,
            isLoading: false
          })
        );
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [isSuccessUpdate, isErrorUpdate, isSuccessYearUpdate, isLoadingYearUpdate, isErrorYearUpdate]
  );

  useEffect(
    () => {
      if (allowanceToHourLoading) {
        toastId.current = toast.loading("Please wait...", {
          position: "top-center",
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          theme: "colored",
          type: "info"
        });
      }
      if (allowanceToHourSuccess) {
        toast.dismiss(toastId.current);
        toast.success("Employee allowances converted to hours successfully.", {
          render: "Employee allowances converted to hours successfully.",
          type: "success",
          autoClose: 2000,
          isLoading: false,
          theme: "colored"
        });
        getEmployeeAllowance(employeeInfo?.id);
        document?.getElementById("closeChangeHourModel").click();
      }
      if (allowanceToHourError) {
        const toastMessage = allowanceToHourErrorAPI_ERROR.data.message
          ? allowanceToHourErrorAPI_ERROR.data.message
          : "Something went wrong";
        toast.update(
          toastId.current,
          (toastMessage,
          {
            render: toastMessage,
            type: "error",
            autoClose: 5000,
            isLoading: false
          })
        );
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [allowanceToHourLoading, allowanceToHourSuccess, allowanceToHourError]
  );

  useEffect(
    () => {
      if (allowanceToDaysLoading) {
        toastId.current = toast.loading("Please wait...", {
          position: "top-center",
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          theme: "colored",
          type: "info"
        });
      }
      if (allowanceToDaysSuccess) {
        toast.dismiss(toastId.current);
        toast.success("Employee allowances converted to days successfully.", {
          render: "Employee allowances converted to days successfully.",
          type: "success",
          autoClose: 2000,
          isLoading: false,
          theme: "colored"
        });
        getEmployeeAllowance(employeeInfo?.id);
        document?.getElementById("closeChangeModel").click();
      }
      if (allowanceToDaysError) {
        const toastMessage = allowanceToDaysErrorAPI_ERROR.data.message
          ? allowanceToDaysErrorAPI_ERROR.data.message
          : "Something went wrong";
        toast.update(
          toastId.current,
          (toastMessage,
          {
            render: toastMessage,
            type: "error",
            autoClose: 5000,
            isLoading: false
          })
        );
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [allowanceToDaysLoading, allowanceToDaysSuccess, allowanceToDaysError]
  );

  useEffect(() => {
    if ( isSuccessYearUpdate) {
      let val = {
        employee_id: data?.employeeData?.id,
        prev_year_allowance: allYearData?.previousYearAllowance
          ? allYearData?.previousYearAllowance
          : data?.data?.previousYear?.allowance,
        prev_year_brought_forward: allYearData?.previousYearBroughtForward
          ? allYearData?.previousYearBroughtForward
          : data?.data?.previousYear?.broughtForward,
        current_year_allowance: allYearData?.currentYearAllowance
          ? allYearData?.currentYearAllowance
          : data?.data?.currentYear?.allowance,
        current_year_brought_forward: allYearData?.currentBroughtForward
          ? allYearData?.currentBroughtForward
          : data?.data?.currentYear?.broughtForward,
        next_year_allowance: allYearData?.nextAllowance
          ? allYearData?.nextAllowance
          : data?.data?.nextYear?.allowance,
        next_year_brought_forward: allYearData?.nextBroughtForward
          ? allYearData?.nextBroughtForward
          : data?.data?.nextYear?.broughtForward
      };
      createLeaveAllowanceAllyear(val);
    }
  }, [isSuccessYearUpdate]);

  useEffect(() => {
    if (createLeaveAllowanceAllyearSuccess) {
      getEmployeeAllowance(employeeInfo?.id);
    }
  }, [createLeaveAllowanceAllyearSuccess]);

  const handlerAllowanceChange = (e) => {
    e.preventDefault();
    if (e.target.name === "annual_allowances") {
      setFormData((currentValue) => {
        return {
          ...currentValue,
          annual_allowance: e.target.value
        };
      });
      setFormData((currentValue) => {
        return {
          ...currentValue,
          currentYearAllowances: e.target.value
        };
      });
    }
    if (e.target.name === "maxium_leave") {
      setFormData((currentValue) => {
        return {
          ...currentValue,
          maximum_leave_carry: e.target.value
        };
      });
    }
  };

  const handleChange = (e) => {
    const {name, value} = e.target;
    setAllYearData((currentValue) => {
      return {
        ...currentValue,
        [name]: value
      };
    });
  };

  const handlerUpdateEmployeeAllowance = (e) => {
    e.preventDefault();
    let value = {
      allowance: formData.annual_allowance
        ? formData?.annual_allowance
        : data?.data?.currentYear?.allowance,
      maximum_leave_carry: `${formData.maximum_leave_carry}`
        ? `${formData.maximum_leave_carry}`
        : data?.maxLeaveCarryForward,
      employee_id: data?.employeeData?.id,
      year: current,
      brought_forward: 0
    };
    updateEmployeeAllowances(value);
  };

  const handlerUpdate = (e) => {
    e.preventDefault();
  
    let value1 = {
      maximum_leave_carry: `${formData.maximum_leave_carry}`,
      id: data?.employeeData?.id
    };
    updateEmployeeById(value1);
  };

  const handleConfirmation = (e) => {
    e.preventDefault();
    let value = {
      employee_id: employeeInfo?.id
    };
    employeeAllowanceToHour(value);
  };

  const handleConfirmationForDays = (e) => {
    e.preventDefault();
    let value = {
      employee_id: employeeInfo?.id
    };
    employeeAllowanceToDays(value);
  };

  return (
    <div className="lg:p-7 p-4">
      {isLoading ? (
        <LoadingScreen />
      ) : (
        <>
          <div className=" mb-5">
            <p>
              {data?.employeeData?.hours_or_day === "1" ? (
                <>
                  Allowances are tracked in Hours{" "}
                  <Popup
                    open={open}
                    onClose={() => closeModal()}
                    closeOnDocumentClick={false}
                    trigger={
                      <Link
                        to="#"
                        className="font-medium"
                        id="closeChangeModel"
                      >
                        Change to days.
                      </Link>
                    }
                    modal
                    nested
                  >
                    {(close) => (
                      <div className="modal">
                        <button
                          className="close"
                          id="resetClose"
                          onClick={() => {
                            closeModal();
                            close();
                          }}
                        >
                          &times;
                        </button>
                        <div className="header">
                          <h3>Change to days</h3>
                        </div>
                        <div className="content">
                          <p>Are you sure you want to change to days?</p>
                          <div className="w-full mt-8">
                            <div className="form-group pt-4 pb-3 flex justify-center ">
                              <button
                                id="resetPswYes"
                                className="btn save mr-2"
                                onClick={(e) => handleConfirmationForDays(e)}
                              >
                                Yes
                              </button>
                              <button
                                type="button"
                                id="resetPswNo"
                                onClick={() => close()}
                                className="btn cancel "
                              >
                                No
                              </button>
                            </div>
                          </div>
                        </div>
                      </div>
                    )}
                  </Popup>
                </>
              ) : (
                <>
                  Allowances are tracked in days{" "}
                  <Popup
                    open={open}
                    onClose={() => closeModal()}
                    closeOnDocumentClick={false}
                    trigger={
                      <Link
                        to="#"
                        className="font-medium"
                        id="closeChangeHourModel"
                      >
                        Change to hourly.
                      </Link>
                    }
                    modal
                    nested
                  >
                    {(close) => (
                      <div className="modal">
                        <button
                          className="close"
                          id="resetClose"
                          onClick={() => {
                            closeModal();
                            close();
                          }}
                        >
                          &times;
                        </button>
                        <div className="header">
                          <h3>Change to hourly</h3>
                        </div>
                        <div className="content">
                          <p>Are you sure you want to change to hourly?</p>
                          <div className="w-full mt-8">
                            <div className="form-group pt-4 pb-3 flex justify-center ">
                              <button
                                id="resetPswYes"
                                className="btn save mr-2"
                                onClick={(e) => handleConfirmation(e)}
                              >
                                Yes
                              </button>
                              <button
                                type="button"
                                id="resetPswNo"
                                onClick={() => close()}
                                className="btn cancel "
                              >
                                No
                              </button>
                            </div>
                          </div>
                        </div>
                      </div>
                    )}
                  </Popup>
                </>
              )}
            </p>
          </div>
          <div className="flex flex-wrap mt-2 ">
            <div className="md:w-1/2 md:pr-6 w-full">
              <div className="form-group mb-6 ">
                <label className="formBlock	">
                  Annual Allowance
                  <span /> (
                  {data?.employeeData?.hours_or_day === "1"
                    ? "Hour"
                    : "Day"}){" "}
                </label>
                <div className="flex">
                  <div className="w-full pr-4">
                    <input
                      type="text"
                      name="annual_allowances"
                      defaultValue={data?.employeeData?.annual_allowance?.toFixed(
                        2
                      )}
                      id="AnnualAllowance"
                      onChange={(e) => handlerAllowanceChange(e)}
                      className="formControl"
                      disabled={myprofileReadOnly}
                    />
                  </div>
                </div>
              </div>
            </div>
            <div className="md:w-1/2 md:pr-6 w-full">
              <div className="form-group mb-6 ">
                <label className="formBlock	">
                  Maximum leave employee can carry forward <span /> (
                  {data?.employeeData?.hours_or_day === "1" ? "Hour" : "Day"})
                </label>
                <div className="flex">
                  <div className="w-full pr-4">
                    <input
                      type="text"
                      className="formControl"
                      name="maxium_leave"
                      defaultValue={data?.maxLeaveCarryForward?.toFixed(2)}
                      id="MaximumLeave"
                      onChange={(e) => handlerAllowanceChange(e)}
                      disabled={myprofileReadOnly}
                    />
                  </div>
                </div>
              </div>
            </div>
            <div className="w-full pr-4">
              <PrimaryButton
                btnText="Save"
                Btnclass="btn save mr-2"
                buttonID="saveAllowance "
                onClick={(e) => handlerUpdateEmployeeAllowance(e)}
              />
            </div>
          </div>
          <p className="font-semibold text-leading mt-2">
            Previous year: {formattedNextYearPrevious} - {formattedYearPrevious}
          </p>
          <div className="currentYear ">
            <form>
              <div className="flex flex-wrap justify-between">
                <div className="sm:w-1/4 w-full  lg:pr-6">
                  <div className="sm:flex items-center justify-between w-full mt-2 text-center lg:text-">
                    <div className="text-center">
                      <p>Brought forward</p>
                      <input
                        type="number"
                        id="previousYearBroughtForward"
                        name="previousYearBroughtForward"
                        className="w-24 h-16 font-bold border p-2 text-center primary-text"
                        defaultValue={data?.data?.previousYear?.broughtForward?.toFixed(
                          2
                        )}
                        onChange={(e) => handleChange(e)}
                      />
                    </div>
                    <span className="fa fa-plus lg:mt-8 mt-4 xl:mr-8 3xl:mr-16 md:mr-4" />
                  </div>
                </div>
                <div className="sm:w-1/4 w-full  lg:pr-6">
                  <div className="sm:flex items-center justify-between w-full mt-2 text-center">
                    <div className="text-center">
                      <p>Allowance</p>
                      <input
                        type="number"
                        id="previousYearAllowance"
                        name="previousYearAllowance"
                        className="w-24 h-16 font-bold border p-2 text-center primary-text font-bold"
                        onChange={(e) => handleChange(e)}
                        defaultValue={data?.data?.previousYear?.allowance?.toFixed(
                          2
                        )}
                      />
                    </div>
                    <span className="fa fa-minus lg:mt-8 mt-4 xl:mr-8 3xl:mr-16 md:mr-4" />
                  </div>
                </div>
                <div className="sm:w-1/4 w-full  lg:pr-6">
                  <div className="sm:flex items-center justify-between w-full mt-2 text-center">
                    <div className="text-center">
                      <p>Approved leaves</p>
                      <input
                        type="number"
                        id="approvedLeaves"
                        className="w-24 h-16 font-bold border p-2 text-center text-red-500"
                        readOnly
                        value={data?.data?.previousYear?.approvedLeaves?.toFixed(
                          2
                        )}
                        disabled={myprofileReadOnly}
                      />
                    </div>
                    <span className=" lg:mt-8 mt-4 xl:mr-8 md:mr-4 3xl:mr-16 text-3xl">
                      =
                    </span>
                  </div>
                </div>
                <div className="w-auto ml-auto mr-auto md:ml-0 md:mr-0">
                  <div className="sm:flex items-center justify-between w-full mt-2">
                    <div className="text-center">
                      <p>Remaining</p>
                      <div className="w-24 h-16 font-bold border p-2 text-center text-xl mx-auto bg-secondry text-white rounded justify-center flex items-center">
                        {data?.data?.previousYear?.remaining?.toFixed(2)}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </form>
          </div>
          <p className="font-semibold text-leading mt-8">
            Current year: {formattedNextYear} - {formattedYear}
          </p>
          <div className="currentYear ">
            <form>
              <div className="flex flex-wrap justify-between">
                <div className="sm:w-1/4 w-full  lg:pr-6">
                  <div className="sm:flex items-center justify-between w-full mt-2 text-center lg:text-">
                    <div className="text-center">
                      <p>Brought forward</p>
                      <input
                        type="number"
                        id="currentBroughtForward"
                        name="currentBroughtForward"
                        className="w-24 h-16 font-bold border p-2 text-center primary-text font-bold"
                        onChange={(e) => handleChange(e)}
                        defaultValue={data?.data?.currentYear?.broughtForward?.toFixed(
                          2
                        )}
                      />
                    </div>
                    <span className="fa fa-plus lg:mt-8 mt-4 xl:mr-8 md:mr-4" />
                  </div>
                </div>
                <div className="sm:w-1/4 w-full  lg:pr-6">
                  <div className="sm:flex items-center justify-between w-full mt-2 text-center">
                    <div className="text-center">
                      <p>Allowance</p>
                      <input
                        type="number"
                        id="currentYearAllowance"
                        name="currentYearAllowance"
                        className="w-24 h-16 border p-2 text-center primary-text font-bold"
                        onChange={(e) => handleChange(e)}
                        defaultValue={data?.data?.currentYear?.allowance?.toFixed(
                          2
                        )}
                      />
                    </div>
                    <span className="fa fa-minus lg:mt-8 mt-4 xl:mr-8 md:mr-4" />
                  </div>
                </div>
                <div className="sm:w-1/4 w-full  lg:pr-6">
                  <div className="sm:flex items-center justify-between w-full mt-2 text-center">
                    <div className="text-center">
                      <p>Approved leaves</p>
                      <input
                        type="number"
                        className="w-24 h-16 border p-2 text-center primary-text font-bold"
                        id="currentYearApprovedLeaves"
                        readOnly
                        value={data?.data?.currentYear?.approvedLeaves?.toFixed(
                          2
                        )}
                        disabled={myprofileReadOnly}
                      />
                    </div>
                    <span className=" lg:mt-8 mt-4 lg:mr-8 xl:mr-8 md:mr-4 text-3xl">
                      =
                    </span>
                  </div>
                </div>

                <div className="w-auto ml-auto mr-auto md:ml-0 md:mr-0">
                  <div className="sm:flex items-center justify-between w-full mt-2">
                    <div className="text-center">
                      <p>Remaining</p>
                      <div className="w-24 h-16 font-bold border p-2 text-center text-xl mx-auto bg-secondry text-white rounded justify-center flex items-center">
                        {data?.data?.currentYear?.remaining?.toFixed(2)}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </form>
          </div>
          <p className="font-semibold text-leading mt-8">
            Next year: {formattedNextYearNext} - {formattedYearNext}
          </p>
          <div className="currentYear ">
            <form>
              <div className="flex flex-wrap justify-between">
                <div className="sm:w-1/4 w-full  lg:pr-6">
                  <div className="sm:flex items-center justify-between w-full mt-2 text-center lg:text-">
                    <div className="text-center">
                      <p>Brought forward</p>
                      <input
                        type="number"
                        id="nextBroughtForward"
                        name="nextBroughtForward"
                        className="w-24 h-16 font-bold border p-2 text-center primary-text font-bold"
                        onChange={(e) => handleChange(e)}
                        defaultValue={data?.data?.nextYear?.broughtForward?.toFixed(
                          2
                        )}
                      />
                    </div>
                    <span className="fa fa-plus lg:mt-8 mt-4 xl:mr-8 md:mr-4" />
                  </div>
                </div>
                <div className="sm:w-1/4 w-full  lg:pr-6">
                  <div className="sm:flex items-center justify-between w-full mt-2 text-center">
                    <div className="text-center">
                      <p>Allowance</p>
                      <input
                        type="number"
                        id="nextAllowance"
                        name="nextAllowance"
                        className="w-24 h-16 font-bodata?.currentYearld border p-2 text-center primary-text font-bold"
                        onChange={(e) => handleChange(e)}
                        defaultValue={data?.data?.nextYear?.allowance?.toFixed(
                          2
                        )}
                      />
                    </div>
                    <span className="fa fa-minus lg:mt-8 mt-4 xl:mr-8 md:mr-4" />
                  </div>
                </div>
                <div className="sm:w-1/4 w-full  lg:pr-6">
                  <div className="sm:flex items-center justify-between w-full mt-2 text-center">
                    <div className="text-center">
                      <p>Approved leaves</p>
                      <input
                        type="number"
                        id="nextApprovedLeaves"
                        className="w-24 h-16 font-bold border p-2 text-center text-red-500"
                        readOnly
                        value={data?.data?.nextYear?.approvedLeaves?.toFixed(2)}
                        disabled={myprofileReadOnly}
                      />
                    </div>
                    <span className=" lg:mt-8 mt-4 xl:mr-8 md:mr-4 text-3xl">
                      =
                    </span>
                  </div>
                </div>
                <div className="w-auto ml-auto mr-auto md:ml-0 md:mr-0">
                  <div className="sm:flex items-center justify-between w-full mt-2">
                    <div className="text-center">
                      <p>Remaining</p>
                      <div className="w-24 h-16 font-bold border p-2 text-center text-xl mx-auto bg-secondry text-white rounded justify-center flex items-center">
                        {data?.data?.nextYear?.remaining?.toFixed(2)}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </form>
          </div>
          <div className="lg:w w-full  ">
            {(employeeInfo.status === "1" &&
              activeEmployeeUpdatePermissions[0]?.status === true) ||
            !myprofileReadOnly ? (
              <div className="w-full mt-8">
                {roleIdData === 4 ? null : (
                  <div className="form-group pt-8 pb-3 flex border-t  ">
                    <PrimaryButton
                      btnText="Save"
                      Btnclass="btn save mr-2"
                      buttonID="saveAllowance "
                      onClick={(e) => handlerUpdate(e)}
                    />
                  </div>
                )}
              </div>
            ) : null}
          </div>
        </>
      )}
    </div>
  );
};

export default UserAllowances;
