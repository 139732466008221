import "./App.css";
import RouterFile from "./route/router";
import "reactjs-popup/dist/index.css";
import {Provider} from "react-redux";
import {store, persistor} from "./redux/store";
import {PersistGate} from "redux-persist/integration/react";
import {ToastContainer} from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Notification from "./Notification";


function App() {
  return (
    <Provider store={store}>
      <PersistGate loading={null} persistor={persistor}>
        {/* <Elements stripe={stripePromise} options={options}> */}
        <div className="App">
          <ToastContainer
            // position="top-right"
            position="top-center"
            autoClose={5000}
            hideProgressBar={false}
            newestOnTop={false}
            closeOnClick
            rtl={false}
            pauseOnFocusLoss
            d
            draggable
            pauseOnHover
            theme="colored"
          />
          <RouterFile />
          <Notification />
        </div>
        {/* </Elements> */}
      </PersistGate>
    </Provider>
  );
}

export default App;
