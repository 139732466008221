import {BarChartExpense} from "../dashboard/barChartExpense";
import {BarChart} from "../dashboard/barChart";

const Trends = ({
  toggleTab4,
  toggleState4,
  expense,
  mileage,
  leave,
  overtime,
  absence
}) => {
  return (
    <div className=" w-full  ">
      <div className="card w-full infoList ChartDiv">
        <div className="heading_title lg:px-3 px-3 flex justify-between items-center py-2">
          <div>
            <h3 className=" font-semibold text-lg">Trends</h3>
          </div>
        </div>
        <div className="p-2">
          <div className="cardtabBtn  flex-wrap">
            <button
              onClick={() => toggleTab4(1)}
              id="puchase"
              className={
                toggleState4 === 1
                  ? "btn-sm  mr-2 active mb-1 sm:mb-0"
                  : "btn-sm  mr-2 mb-1 sm:mb-0"
              }
            >
              Expenses - Purchase
            </button>

            <button
              onClick={() => toggleTab4(2)}
              id="mileage"
              className={
                toggleState4 === 2
                  ? "btn-sm mr-2 active mb-1 sm:mb-0"
                  : "btn-sm mr-2 mb-1 sm:mb-0  "
              }
            >
              Expenses - Mileage
            </button>
            <button
              onClick={() => toggleTab4(3)}
              id="leave"
              className={
                toggleState4 === 3
                  ? "btn-sm mr-2 active mb-1 sm:mb-0"
                  : "btn-sm mr-2 mb-1 sm:mb-0 "
              }
            >
              Leave
            </button>
            <button
              onClick={() => toggleTab4(4)}
              id="overtime"
              className={
                toggleState4 === 4
                  ? "btn-sm mr-2 active mb-1 sm:mb-0"
                  : "btn-sm mr-2 mb-1 sm:mb-0"
              }
            >
              Overtime
            </button>
            <button
              onClick={() => toggleTab4(5)}
              id="absence"
              className={
                toggleState4 === 5
                  ? "btn-sm active mb-1 sm:mb-0"
                  : "btn-sm mb-1 sm:mb-0 "
              }
            >
              Absence
            </button>
          </div>
          <div className="tabCard">
            <div
              className={
                toggleState4 === 1
                  ? "tabContent tabContentActive"
                  : "tabContent"
              }
            >
              <div className="barChartArea">
                <BarChartExpense chart={expense} />
              </div>
            </div>
            <div
              className={
                toggleState4 === 2
                  ? "tabContent tabContentActive"
                  : "tabContent"
              }
            >
              <div className="barChartArea">
                <BarChart chart={mileage} />
              </div>
            </div>
            <div
              className={
                toggleState4 === 3
                  ? "tabContent tabContentActive"
                  : "tabContent"
              }
            >
              <div className="barChartArea">
                <BarChart chart={leave} />
              </div>
            </div>
            <div
              className={
                toggleState4 === 4
                  ? "tabContent tabContentActive"
                  : "tabContent"
              }
            >
              <div className="barChartArea">
                <BarChart chart={overtime} />
              </div>
            </div>
            <div
              className={
                toggleState4 === 5
                  ? "tabContent tabContentActive"
                  : "tabContent"
              }
            >
              <div className="barChartArea">
                <BarChart chart={absence} />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Trends;
