import { useState, useEffect, useRef } from "react";
import { useParams
  //  useNavigate
   } from "react-router-dom";
import { Helmet, HelmetProvider } from "react-helmet-async";
import {  toast } from "react-toastify";
import Popup from "reactjs-popup";
import moment from "moment/moment";
import AddEmployeeLeave from "./AddEmployeeLeave";
import {
  useFilterEmployeeByIdMutation,
  useGetEmployeeAllDaysScheduleMutation,
  useAllEmployeeCalendarViewDetailsMutation
} from "../../services/employeeApi";
import { useGetAllHolidayMutation } from "../../services/holidayApi";
import { CSVLink } from "react-csv";
import LoadingScreen from "../../helper/LoadingScreen";
import { useSelector } from "react-redux";
import { useGetAllNotificationCountMutation } from "../../services/notesApi";
import { useDispatch } from "react-redux";
import { notificationCount } from "../../redux/AuthSlice/authSlice";
import CalendarViewToggle from "./calenderViewToggle";
import ListLeave from "./listLeave";
import { useLocation } from 'react-router-dom';
import { allEmployeeCalendarDataAction, employeeScheduleInfoAction, filterEmployeeByIDAction } from "../../redux/EmployeeSlice/employeeSlice";
import { REACT_APP_AUDITOR_ROLE_ID } from "../../helper/constant";

const CalendarView = () => {
  
// Selectors
const roleIdData = useSelector((state) => state?.persistedReducer?.userPermissionsStates?.user?.roleId);
const getEmployeeAllDayScheduleRespo = useSelector((state) => state?.persistedReducer?.employeeSlice?.employeeScheduleInfo);
const employeeIdData = useSelector((state) => state?.persistedReducer?.userPermissionsStates?.user);
const employeeDataResponse = useSelector((state) => state?.persistedReducer?.employeeSlice?.filterEmployeeByIdData);
const employeeDetailsViewRespo = useSelector((state) => state?.persistedReducer?.employeeSlice?.allEmployeeCalendarData);

// Mutations
const [ getAllHoliday, { data: allHolidayData } ] = useGetAllHolidayMutation();
const [  filterEmployeeById, { data: employeeDataResponseList, isSuccess: isSuccessEmployee, isLoading, isError, error: apiError } ] = useFilterEmployeeByIdMutation();
const [  getEmployeeAllDaysSchedule, { data: EmployeeAllDayScheduleRespo, isSuccess: isSuccessSchedule }  ] = useGetEmployeeAllDaysScheduleMutation();
const [  allEmployeeCalendarViewDetails, { data: employeeDetailsViewData, isSuccess: isSuccessCalendar }  ] = useAllEmployeeCalendarViewDetailsMutation();
const [  getAllNotificationCount, { data: notificationCountRespo, isSuccess: isSuccessNotification }  ] = useGetAllNotificationCountMutation();
 
// Initialization
const { id } = useParams();
const ignore = useRef(false);
const toastId = useRef(null);
const currentYear = new Date().getFullYear();

// states
const {state} = useLocation();
const [ open, setOpen ] = useState(false);
const [ toggleState2, setToggleState2 ] = useState("fullCalenderToggleTab");
const [ startDate, setStartDate ] = useState();
const [ endDate, setEndDate ] = useState();
const [ totalYearLeave, setLeaveTotalYear ] = useState([  ]);
const [ leaveInfoCurrentYear, setLeaveInfoCurrentYear ] = useState([  ]);
const [ nonDeductLeave, setNonDeductLeave ] = useState([  ]);
const [ excelData, setExcelData ] = useState([  ]);
const [ employeeOverTimeData, setEmployeeOverTimeData ] = useState([  ]);
const [ selectedYearCalendar, setSelectedYearCalendar ] = useState(currentYear);
const [ formData, setFormData ] = useState({
  Brought_forward: 0,
  Allowance: 0,
  Time_in_lieu: 0,
  Total: 0,
  leave_remaining: 0,
  total: 0,
  totalNonDeductLeave: 0,
  leaveInfoCurrentYear: [ ]
});

// Functions
const dispatch = useDispatch();
const createCsvFileName = () => `MyLeave_report_${moment().format()}.csv`;
const toggleTab2 = (index) => {
  setToggleState2(index);
};
const closeModal = () => {
  setOpen(false);
};

const exportTOCSV = (leaveinFo) => {
  let dataAbence = [  ];
if(leaveinFo){
  leaveinFo?.forEach((dExcel) => {
    let hour = parseInt(dExcel.total_duration) / 60;
    let days = hour / 8;
    let val = {
      start_date: moment(dExcel?.start_date).format("DD-MM-YYYY"),
      start_time: dExcel?.start_time,
      end_date: moment(dExcel?.end_date).format("DD-MM-YYYY"),
      end_time: dExcel?.end_time,
      total_duration: days,
      status:
        dExcel?.status === "1"
          ? "Apporved"
          : dExcel?.status === "0"
          ? "Applied"
          : "Cancel",
      type:
        dExcel.type === "3" ? "My leave" : dExcel.type === "1" ? "Leave" : "",
      applied_by: dExcel?.addedByInfo?.first_name,
      approved_by: dExcel?.approvedByInfo?.first_name,
      approved_date:
        dExcel?.approved_date !== null || dExcel?.approved_date === ""
          ? moment(dExcel?.approved_date).format("DD-MM-YYYY")
          : "",
      notes: dExcel?.notes,
      reason: dExcel.reason,
      cancel_reason: dExcel?.cancel_reason,
      createdAt: moment(dExcel?.createdAt).format("DD-MM-YYYY"),
      updatedAt: moment(dExcel?.updatedAt).format("DD-MM-YYYY"),
      deletedAt: dExcel?.deletedAt
        ? moment(dExcel?.deletedAt).format("DD-MM-YYYY")
        : "null"
    };
    dataAbence.push(val);
  });
  return dataAbence;
}
};

useEffect(
    () => {
      if (!ignore.current){
      if (id) {
        filterEmployeeById(id);
        getEmployeeAllDaysSchedule(id);
        allEmployeeCalendarViewDetails(id);
      } else {
        filterEmployeeById(employeeIdData?.employee_id);
        getEmployeeAllDaysSchedule(employeeIdData?.employee_id);
        allEmployeeCalendarViewDetails(employeeIdData?.employee_id);
      }
     
    }
    return () => { ignore.current = true; };
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [  ]
  );
  useEffect(() => {
    if(isSuccessEmployee){
       dispatch(filterEmployeeByIDAction(employeeDataResponseList));
    }
    
    if (isError) {
      if (apiError?.status.code === 403) {
        // navigate("/leave-calendar");
        const toastMessage = apiError.data.message
          ? apiError.data.message
          : "Something went wrong";
        toast.update(
          toastId.current,
          (toastMessage,
          {
            render: toastMessage,
            type: "error",
            autoClose: 5000,
            isLoading: false
          })
        );
      }
    }
  }, 
// eslint-disable-next-line react-hooks/exhaustive-deps
[  isSuccessEmployee, isError  ]);
  useEffect(() => {
    if(isSuccessSchedule){
      dispatch(employeeScheduleInfoAction(EmployeeAllDayScheduleRespo));
    }
  }, [  isSuccessSchedule  ]);

  useEffect(() => {
    if(state?.activeTab === "leaveRequest"){
      setToggleState2("leaveRequest");
    }
  }, [ state ]);
  useEffect(() => {
    if(isSuccessCalendar){
       dispatch(allEmployeeCalendarDataAction(employeeDetailsViewData));
    }
  }, 
// eslint-disable-next-line react-hooks/exhaustive-deps
[  isSuccessCalendar  ]);

  useEffect(
    () => {
      if (employeeDetailsViewRespo) {
        let leaveRemaining =
          employeeDetailsViewRespo?.remainingLeaveDaysCurrentYear;
        let leaveTaken = employeeDetailsViewRespo?.totalLeaveTakenInDays;
        let Total = employeeDetailsViewRespo?.total;
        let timeInLieu = employeeDetailsViewRespo?.timeInLieu;
        let allowance = employeeDetailsViewRespo?.currentYearAllowance;
        let broughForward = employeeDetailsViewRespo?.broughtForwardLeaveDays;
        let leaveInfoCurrentYear =
          employeeDetailsViewRespo?.leaveInfoCurrentYear;
        let currentYearTimeInLieuData =
          employeeDetailsViewRespo?.currentYearTimeInLieuData;

        setFormData({
          ...formData,
          Brought_forward:
            Math.floor(broughForward) === broughForward
              ? broughForward
              : broughForward?.toFixed(2),
          Allowance:
            Math.floor(allowance) === allowance
              ? allowance
              : allowance?.toFixed(2),
          Time_in_lieu:
            Math.floor(timeInLieu) === timeInLieu
              ? timeInLieu
              : timeInLieu?.toFixed(2),

          leave_remaining:
            Math.floor(leaveRemaining) === leaveRemaining
              ? leaveRemaining
              : leaveRemaining?.toFixed(2),
          leave_taken:
            Math.floor(leaveTaken) === leaveTaken
              ? leaveTaken
              : leaveTaken?.toFixed(2),

          total: Math.floor(Total) == Total ? Total : Total?.toFixed(2)
        });
        setLeaveInfoCurrentYear(leaveInfoCurrentYear);
        setEmployeeOverTimeData(currentYearTimeInLieuData);
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [  employeeDetailsViewRespo  ]
  );

  useEffect(
    () => {
      if (employeeDataResponse) {
        if (employeeDataResponse?.data?.length > 0) {
          getAllHoliday({
            location_id: employeeDataResponse?.data[0]?.location_id,
            start_year: selectedYearCalendar,
            page: "1"
          });
          let employeeLeaveData =
            employeeDataResponse?.data[0]?.leaveInfo?.filter(
              (f) => (f.type === "1" || f.type === "3") && f.status === "1"
            );
          let leaveNonDeduct = employeeLeaveData?.filter(
            (f) => f?.leaveTypeInfo?.is_deductible === "0"
          );
          setLeaveTotalYear(employeeLeaveData);
          // eslint-disable-next-line no-unused-vars
          let totalLeaveDuration = employeeLeaveData?.reduce(function (
            acc,
            obj
          ) {
            return acc + obj.total_duration;
          },
          0);
          let nonTotalLeave = leaveNonDeduct?.reduce(function (acc, obj) {
            return acc + obj.total_duration;
          }, 0);
          setNonDeductLeave(leaveNonDeduct);
          setFormData({
            ...formData,
            Total: 0,
            totalNonDeductLeave: nonTotalLeave / 60 / 8,
            total: employeeDetailsViewRespo?.total
          });
          if(employeeDataResponse?.data[0]?.leaveInfo){
            let csvData = exportTOCSV(employeeDataResponse?.data[0]?.leaveInfo);
            setExcelData(csvData);
          }
         
          let payload1 = `/employeenotification/count/?employee_id=${employeeIdData?.employee_id}`;
          getAllNotificationCount(payload1);
        } else {
          // navigate("/leave-calendar");
        }
      }

    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [ employeeDataResponse,  selectedYearCalendar ]
  );

  useEffect(
    () => {
      if (notificationCountRespo) {
        dispatch(notificationCount(notificationCountRespo?.unreadCount));
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [ isSuccessNotification ]
  );

  let headers = [ 
    { label: "Start Date", key: "start_date" },
    { label: "Start Time", key: "start_time" },
    { label: "End Date", key: "end_date" },
    { label: "End Time", key: "end_time" },
    { label: "Duration(Days/Hours)", key: "total_duration" },
    { label: "Status", key: "status" },
    { label: "Type", key: "type" },
    { label: "AppliedBy", key: "applied_by" },
    { label: "Approved By", key: "approved_by" },
    { label: "Approved Date", key: "approved_date" },
    { label: "Reason", key: "reason" },
    { label: "Notes", key: "notes" },
    { label: "Cancel Reason", key: "cancel_reason" },
    { label: "CreatedAt", key: "createdAt" },
    { label: "UpdatedAt", key: "updatedAt" },
    { label: "DeletedAt", key: "deletedAt" }
   ];

 // JSX
  return (
    <>
      <div>
        <HelmetProvider>
          <Helmet>
            <title>Schedulize | My Leave</title>
          </Helmet>
        </HelmetProvider>

        <div>
          {employeeDataResponse?.data?.length > 0 ? (
            <div className="py-6">
              <div className="container mx-auto ">
                <h2 className="text-xl mb-3 font-semibold  ">Year Calendar</h2>
                <div className="card bg-white  mb-3">
                  <div className="heading_title px-4 flex justify-between items-center py-4 capitalize">
                    <div className="listImage flex items-center">
                      {id
                        ? employeeDataResponse?.data[0]?.first_name
                        : employeeIdData?.first_name +
                          " " +
                          employeeIdData?.last_name}
                    </div>
                    <div className="headerButton">
                      {id === undefined ? (
                        <>
                          <CSVLink
                            className=""
                            data={excelData}
                            headers={headers}
                            filename={createCsvFileName()}
                            target="_blank"
                            style={{
                              textDecoration: "none",
                              outline: "none",
                              height: "5vh"
                            }}
                          >
                            <button
                              className="btn-md btn-add mr-2"
                              id="exportCSV"
                            >
                              <span className="fa fa-file-export mr-2" /> Export
                              to CSV
                            </button>
                          </CSVLink>
                          {roleIdData !== REACT_APP_AUDITOR_ROLE_ID && (
                          <Popup
                            open={open}
                            onClose={() => closeModal()}
                            closeOnDocumentClick={false}
                            className="bookTimeLeave"
                            trigger={
                              <button
                                className="btn-md btn-add  "
                                aria-describedby="popup-8"
                                id="addRequestLeave"
                                onClick={() => setOpen((o) => !o)}
                              >
                                <span className="fa fa-plus mr-2" />
                                Request Leave
                              </button>
                            }
                            modal
                            nested
                          >
                            {(close) => (
                              <div className="modal">
                                <button
                                  className="close"
                                  id="closeRequest Leave"
                                  onClick={() => {
                                    closeModal();
                                    close();
                                  }}
                                >
                                  &times;
                                </button>
                                <AddEmployeeLeave
                                  close={close}
                                  filterEmployeeById={filterEmployeeById}
                                  startDateShow={startDate}
                                  endDateShow={endDate}
                                  employeeId={
                                    id ? id : employeeIdData?.employee_id
                                  }
                                  allEmployeeCalendarViewDetails={
                                    allEmployeeCalendarViewDetails
                                  }
                                  isSuccessSchedule={isSuccessSchedule}
                                  getEmployeeAllDayScheduleRespo={
                                    getEmployeeAllDayScheduleRespo
                                  }
                                />
                              </div>
                            )}
                          </Popup>)}
                        </>
                      ) : (
                        ""
                      )}
                    </div>
                  </div>
                  <ul className="flex approvalSubTab mb-4 md:mt-8 mt-4  px-4 ">
                    <li>
                      <span
                        onClick={() => toggleTab2("fullCalenderToggleTab")}
                        className={
                          toggleState2 === "fullCalenderToggleTab"
                            ? " active"
                            : "  "
                        }
                      >
                        Calender View
                      </span>
                    </li>
                    <li>
                      <span
                        onClick={() => toggleTab2("leaveRequest")}
                        className={
                          toggleState2 === "leaveRequest" ? " active" : "  "
                        }
                      >
                        List View
                      </span>
                    </li>
                  </ul>
                  <div className="tabCard ">
                    <div
                      className={
                        toggleState2 === "fullCalenderToggleTab"
                          ? "tabContent tabContentActive"
                          : "tabContent"
                      }
                    >
                      <CalendarViewToggle
                        employeeDataResponse={employeeDataResponse}
                        getEmployeeAllDayScheduleRespo={
                          getEmployeeAllDayScheduleRespo
                        }
                        filterEmployeeById={filterEmployeeById}
                        employeeData={employeeIdData}
                        allHolidayData={allHolidayData}
                        isLoading={isLoading}
                        currentYear={currentYear}
                        employeeDetailsViewRespo={employeeDetailsViewRespo}
                        formData={formData}
                        employeeOverTimeData={employeeOverTimeData}
                        totalYearLeave={totalYearLeave}
                        nonDeductLeave={nonDeductLeave}
                        setStartDate={setStartDate}
                        setEndDate={setEndDate}
                        leaveInfoCurrentYear={leaveInfoCurrentYear}
                        setSelectedYearCalendar={setSelectedYearCalendar}
                        setToggleState2={setToggleState2}
                        roleIdData={roleIdData}
                        selectedYearCalendar={selectedYearCalendar}
                      />
                    </div>
                    <div
                      className={
                        toggleState2 === "leaveRequest"
                          ? "tabContent tabContentActive"
                          : "tabContent"
                      }
                    >
                      <ListLeave startDate={startDate} endDate={endDate} 
                        EmployeeAllDayScheduleRespo={EmployeeAllDayScheduleRespo}
                        isSuccessSchedule={isSuccessSchedule}
                        roleIdData={roleIdData}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          ) : (
            <LoadingScreen />
          )}
        </div>
      </div>
    </>
  );
};

export default CalendarView;
