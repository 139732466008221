import { createSlice } from "@reduxjs/toolkit";


const initialState = {
  employee: [],
  employeeScheduleInfo:[],
  allEmployeeInfoData: [],
  filterEmployeeByIdData: [],
  allEmployeeCalendarData: [],
  allEmployeeListData: [],
  filterCalenderEmployeeData: [],
  employeeDetails: [],
  timeInLieuData: [],
  employeeWorkScheduleHistory: []
};

export const employeeSlice = createSlice({
  name: "employeeSlice",
  initialState,
  reducers: {
    employeeInitialAction:() => initialState,
    employeeInfo: (state, action) => {
      state.employee = action.payload;
    },
    employeeScheduleInfoAction: (state, action) => {
      state.employeeScheduleInfo = action.payload;
    },
    allEmployeeInfoAction: (state, action) => {
      state.allEmployeeInfoData = action.payload;
    },
    filterEmployeeByIDAction: (state, action) => {
      state.filterEmployeeByIdData = action.payload;
    },
    allEmployeeCalendarDataAction: (state, action) => {
      state.allEmployeeCalendarData = action.payload;
    },
    allEmployeeListDataAction: (state, action) => {
      state.allEmployeeListData = action.payload;
    },
    filterCalenderEmployeeDataAction: (state, action) => {
      state.filterCalenderEmployeeData = action.payload;
    },
    employeeDetailsAction: (state, action) => {
      state.employeeDetails = action.payload;
    },
    timeInLieuDataAction: (state, action) => {
      state.timeInLieuData = action.payload;
    },
    employeeWorkScheduleHistoryAction: (state, action) => {
      state.employeeWorkScheduleHistory = action.payload;
    }
  }

});

export const {employeeInitialAction, timeInLieuDataAction, employeeWorkScheduleHistoryAction, employeeInfo, employeeScheduleInfoAction, allEmployeeInfoAction, filterEmployeeByIDAction, allEmployeeCalendarDataAction, allEmployeeListDataAction, filterCalenderEmployeeDataAction, employeeDetailsAction } = employeeSlice.actions;
// ? Export the authSlice.reducer to be included in the store.
export default employeeSlice.reducer;