import {useEffect, useRef, useState} from "react";
import Trends from "../employeeDashboard/Trends";
import {useGetAllChartDashboradMutation} from "../../services/dashboardAp";
import {useSelector} from "react-redux";

const EmployeeTrends = ({employeeInfo}) => {
  const cuurentYear = new Date().getFullYear();
  const ignore = useRef(false);
  const auditorRoleId = useSelector(
    (state) => state?.persistedReducer?.userPermissionsStates?.user?.roleId
  );
  const auditorCompanyId = useSelector(
    (state) => state?.persistedReducer?.auditorsSlice?.auditorSelectedCompany
  );
  const companyId = useSelector(
    (state) => state?.persistedReducer?.userPermissionsStates?.user?.company_id
  );
  const [
    getAllChartDashborad,
    {data: dashboardChartApiRespo, isSuccess: chartSuccess}
  ] = useGetAllChartDashboradMutation();

  const [expense, setExpense] = useState();
  const [mileage, setMileage] = useState();
  const [leave, setLeave] = useState();
  const [overtime, setOvertime] = useState();
  const [absence, setAbsence] = useState();
  const [toggleState4, setToggleState4] = useState(1);

  const toggleTab4 = (index) => {
    setToggleState4(index);
    let payload = `/reports/get-chart-data?company_id=${
      auditorRoleId === 5 ? auditorCompanyId : companyId
    }&employee_id=${employeeInfo?.id}&type=${index}&year=${cuurentYear}`;
    getAllChartDashborad(payload);
  };
  useEffect(() => {
    if (!ignore.current) {
      let payload = `/reports/get-chart-data?company_id=${
        auditorRoleId === 5 ? auditorCompanyId : companyId
      }&employee_id=${
        employeeInfo?.id
      }&type=${toggleState4}&year=${cuurentYear}`;
      getAllChartDashborad(payload);
    }
    return () => {
      ignore.current = true;
    };
  }, []);

  useEffect(
    () => {
      if (chartSuccess) {
        let chartData = dashboardChartApiRespo?.data;
        if (chartData?.type === "1") {
          setExpense(chartData);
        } else if (chartData?.type === "2") {
          setMileage(chartData);
        } else if (chartData?.type === "3") {
          setLeave(chartData);
        } else if (chartData?.type === "4") {
          setOvertime(chartData);
        } else if (chartData?.type === "5") {
          setAbsence(chartData);
        }
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [chartSuccess]
  );

  return (
    <div>
      <Trends
        toggleTab4={toggleTab4}
        toggleState4={toggleState4}
        expense={expense}
        mileage={mileage}
        leave={leave}
        overtime={overtime}
        absence={absence}
      />
    </div>
  );
};

export default EmployeeTrends;
