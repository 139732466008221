import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    absenceData:[ ],
    absenceAllRecordData:[]
};

export const absenceSlice = createSlice({
  name: "authSlice",
  initialState,
  reducers: {
    absenceInitialAction:() => initialState,
    absenceDataAction: (state, action) => {
      state.absenceData = action?.payload;
    },
    absenceAllRecordDataAction:(state, action) => {
        state.absenceAllRecordData = action?.payload;
      }
  }
});

export const {absenceInitialAction,  absenceDataAction, absenceAllRecordDataAction
} = absenceSlice.actions;
export default absenceSlice.reducer;