/* eslint-disable */
import React, {useState, useRef, useEffect} from "react";
// import {lexRuntime} from "./aws-config";
import {
  FaMicrophone,
  FaChevronDown,
  FaChevronUp,
  FaPaperPlane,
  FaRobot
} from "react-icons/fa";
import moment from "moment";
import LoadingScreen from "../../helper/LoadingScreen";

const ChatBot = ({
  thisWeek,
  leaveThisWeek,
  anniversariesThisWeek,
  absenceThisWeek,
  TotalOustandingLeaves,
  TotalExpenseToPay,
  TotalOustandingExpenses,
  TotalOustandingOvertimes
}) => {
  const chatContainerRef = useRef(null);
  const recognition = useRef(null);
  const [isExpanded, setIsExpanded] = useState(true);
  const [messages, setMessages] = useState([
    {
      sender: "bot",
      text: "Hello! How can I assist you today? Please Choose any of above options. Or Write Your Query!"
    }
  ]);
  const [input, setInput] = useState(""); // User input
  const [isListening, setIsListening] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [isClicked, setIsClicked] = useState(false);
  const [error, setError] = useState("");
  const userId = "unique-user-id"; // Generate or retrieve a unique user ID

  const defaultOptions = [
    "How many Birthdays in this week?",
    "How many Leave this week?",
    "How many anniversaries this week?",
    "How many absence this week?",
    "How many Outstanding Overtime Approvals?",
    "How many Outstanding Expense Approvals?",
    "How many Outstanding Leave Approvals?",
    "How many Expenses to Pay?"
  ];

  useEffect(() => {
    if ("SpeechRecognition" in window || "webkitSpeechRecognition" in window) {
      recognition.current = new (window.SpeechRecognition ||
        window.webkitSpeechRecognition)();
      recognition.current.continuous = true;
      recognition.current.interimResults = true;
      recognition.current.onresult = handleSpeechResult;
      recognition.current.onerror = handleSpeechError;
    }

    return () => {
      if (recognition.current) {
        recognition.current.stop();
      }
    };
  }, []);

  useEffect(() => {
    if (chatContainerRef.current) {
      setTimeout(() => {
        chatContainerRef.current.scrollTop =
          chatContainerRef.current.scrollHeight;
      }, 0);
    }
  }, [messages]);

  const handleSpeechResult = (event) => {
    const transcript = Array.from(event.results)
      .map((result) => result[0])
      .map((result) => result.transcript)
      .join("");

    if (event.results[0].isFinal) {
      setInput(transcript); // Only set final results
    }
  };

  const handleSpeechError = (event) => {
    if (event.error === "network") {
      setError("Network error. Please check your connection and try again.");
    } else {
      setError("Speech recognition error. Please try again.");
    }
    setIsListening(false);
  };

  const toggleExpansion = () => {
    setIsExpanded(!isExpanded);
  };

  const toggleVoiceInput = () => {
    if (isListening) {
      recognition.current.stop();
      setIsListening(false);
    } else {
      recognition.current.start();
      setIsListening(true);
      setError("");
    }
  };

  const handleInputChange = (e) => {
    setInput(e.target.value);
    setError("");

    // Check if the "Enter" key is pressed
    if (e.key === "Enter" && !e.shiftKey) {
      e.preventDefault(); // Prevent the default behavior of the Enter key
      handleSendMessage();
    }
  };
  const formatLeaveSummary = (leaveData, name) => {
    if (!leaveData || leaveData.length === 0) {
      return null; // Return null if no data is found
    }

    return leaveData
      .map((employee) => {
        const date =
          name === "Anniversary"
            ? employee?.employment_start_date
            : employee?.dob;
        const formattedDate = moment(date)?.format("MMM DD, YYYY"); // Format the date with moment

        return `${employee?.first_name} ${employee?.last_name} (Date: ${formattedDate})`;
      })
      .join(", ");
  };
  const handleSendMessage = async () => {
    if (!input.trim()) return;

    setIsClicked(false);
    // Add user message to chat
    const newMessages = [...messages, {sender: "user", text: input}];

    try {
      if (input === "How many Outstanding Leave Approvals?") {
        setMessages([
          ...newMessages,
          {
            sender: "bot",
            text: `Currently, there are ${TotalOustandingLeaves} outstanding leave approvals. Let me know if you'd like to see more details on any specific leave!`
          }
        ]);
        setInput("");
      } else if (input === "How many Outstanding Expense Approvals?") {
        setMessages([
          ...newMessages,
          {
            sender: "bot",
            text: `There are ${TotalOustandingExpenses} expense approvals pending. Feel free to ask if you need a breakdown of these expenses!`
          }
        ]);
        setInput("");
      } else if (input === "How many Outstanding Overtime Approvals?") {
        setMessages([
          ...newMessages,
          {
            sender: "bot",
            text: `At the moment, ${TotalOustandingOvertimes} overtime approvals are pending. Let me know if you'd like any further details.`
          }
        ]);
        setInput("");
      } else if (input === "How many Expenses to Pay?") {
        setMessages([
          ...newMessages,
          {
            sender: "bot",
            text: `The total number of expenses to be paid is ${TotalExpenseToPay}. Let me know if you'd like a detailed summary!`
          }
        ]);
        setInput("");
      } else if (
        input === "How many Leave this week?" ||
        input === "How many leave in this week?"
      ) {
        const leaveSummary = formatLeaveSummary(leaveThisWeek);
        const botResponse = leaveSummary
          ? `Here’s the list of employees on leave this week: ${leaveSummary}. Let me know if you need more details!`
          : "No employees are on leave this week.";
        setMessages([...newMessages, {sender: "bot", text: botResponse}]);
        setInput("");
      } else if (
        input === "How many anniversaries this week?" ||
        input === "How many anniversary in this week?"
      ) {
        const leaveSummary = formatLeaveSummary(
          anniversariesThisWeek,
          "Anniversary"
        );
        const botResponse = leaveSummary
          ? `Here’s the list of anniversaries this week: ${leaveSummary}. Let me know if you need more details!`
          : "There are no anniversaries this week.";
        setMessages([...newMessages, {sender: "bot", text: botResponse}]);
        setInput("");
      } else if (
        input === "How many absence this week?" ||
        input === "How many absence in this week?"
      ) {
        const leaveSummary = formatLeaveSummary(absenceThisWeek);
        const botResponse = leaveSummary
          ? `Here’s the list of employees absent this week: ${leaveSummary}. Let me know if you need more details!`
          : "There are no absences this week.";
        setMessages([...newMessages, {sender: "bot", text: botResponse}]);
        setInput("");
      } else if (
        input === "How many Birthdays in this week?" ||
        input === "How many Birthdays in this week?"
      ) {
        const leaveSummary = formatLeaveSummary(thisWeek);
        const botResponse = leaveSummary
          ? `Here’s the list of employees with Birthdays this week: ${leaveSummary}. Let me know if you need more details!`
          : "There are no Birthdays this week.";
        setMessages([...newMessages, {sender: "bot", text: botResponse}]);
        setInput("");
      } else {
        setIsLoading(true);
        try {
          const response = await fetch(
            "https://api-inference.huggingface.co/models/facebook/blenderbot-400M-distill",
            {
              method: "POST",
              headers: {
                Authorization: `Bearer ${process.env.REACT_HUGGING_SECRET_ACCESS_KEY}`,
                "Content-Type": "application/json"
              },
              body: JSON.stringify({inputs: input})
            }
          );

          if (!response.ok) {
            setIsLoading(false);
            throw new Error(`HTTP error! Status: ${response.status}`);
          }

          const result = await response.json();
          const botResponse =
            result?.generated_text || "I'm not sure how to respond.";
          setIsLoading(false);
          setMessages([...newMessages, {sender: "bot", text: botResponse}]);
          setInput("");
        } catch (error) {
          console.error("Error fetching from Hugging Face API:", error);
          setInput("");
          setMessages([
            ...newMessages,
            {
              sender: "bot",
              text: "Sorry, I'm having trouble responding right now."
            }
          ]);
        }
      }
    } catch (overallError) {
      console.error("Error in handleSendMessage:", overallError);
      setIsLoading(false);
      setMessages([
        ...newMessages,
        {sender: "bot", text: "An unexpected error occurred. Please try again."}
      ]);
    }
  };

  // const handleSendMessage = async () => {

  //   if (!input.trim()) return;

  //   setIsClicked(false);
  //   // Add user message to chat
  //   const newMessages = [...messages, {sender: "user", text: input}];
  //   if (input === "How many Outstanding Leave Approvals?") {
  //     setMessages([
  //       ...newMessages,
  //       {
  //         sender: "bot",
  //         text: `Currently, there are ${TotalOustandingLeaves} outstanding leave approvals. Let me know if you'd like to see more details on any specific leave!`
  //       }
  //     ]);
  //     setInput("");
  //   } else if (input === "How many Outstanding Expense Approvals?") {
  //     setMessages([
  //       ...newMessages,
  //       {
  //         sender: "bot",
  //         text: `There are ${TotalOustandingExpenses} expense approvals pending. Feel free to ask if you need a breakdown of these expenses!`
  //       }
  //     ]);
  //     setInput("");
  //   } else if (input === "How many Outstanding Overtime Approvals?") {
  //     setMessages([
  //       ...newMessages,
  //       {
  //         sender: "bot",
  //         text: `At the moment, ${TotalOustandingOvertimes} overtime approvals are pending. Let me know if you'd like any further details.`
  //       }
  //     ]);
  //     setInput("");
  //   } else if (input === "How many Expenses to Pay?") {
  //     setMessages([
  //       ...newMessages,
  //       {
  //         sender: "bot",
  //         text: `The total number of expenses to be paid is ${TotalExpenseToPay}. Let me know if you'd like a detailed summary!`
  //       }
  //     ]);
  //     setInput("");
  //   } else if (
  //     input === "How many Leave this week?" ||
  //     input === "How many leave in this week?"
  //   ) {
  //     const leaveSummary = formatLeaveSummary(leaveThisWeek);
  //     const botResponse = leaveSummary
  //       ? `Here’s the list of employees on leave this week: ${leaveSummary}. Let me know if you need more details!`
  //       : "No employees are on leave this week.";
  //     setMessages([...newMessages, {sender: "bot", text: botResponse}]);
  //     setInput("");
  //   } else if (
  //     input === "How many anniversaries this week?" ||
  //     input === "How many anniversary in this week?"
  //   ) {
  //     const leaveSummary = formatLeaveSummary(
  //       anniversariesThisWeek,
  //       "Anniversary"
  //     );
  //     const botResponse = leaveSummary
  //       ? `Here’s the list of anniversaries this week: ${leaveSummary}. Let me know if you need more details!`
  //       : "There is No anniversaries in this week.";
  //     setMessages([...newMessages, {sender: "bot", text: botResponse}]);
  //     setInput("");
  //   } else if (
  //     input === "How many absence this week?" ||
  //     input === "How many absence in this week?"
  //   ) {
  //     const leaveSummary = formatLeaveSummary(absenceThisWeek);
  //     const botResponse = leaveSummary
  //       ? `Here’s the list of employees absence this week: ${leaveSummary}. Let me know if you need more details!`
  //       : "There is No absence in this week.";
  //     setMessages([...newMessages, {sender: "bot", text: botResponse}]);
  //     setInput("");
  //   } else if (
  //     input === "How many Birthdays in this week?" ||
  //     input === "How many Birthdays in this week?"
  //   ) {
  //     const leaveSummary = formatLeaveSummary(thisWeek);
  //     const botResponse = leaveSummary
  //       ? `Here’s the list of employees Birthdays this week: ${leaveSummary}. Let me know if you need more details!`
  //       : "There is No Birthdays in this week.";
  //     setMessages([...newMessages, {sender: "bot", text: botResponse}]);
  //     setInput("");
  //   } else {
  //     // eslint-disable-next-line
  //     const response = await fetch(
  //       "https://api-inference.huggingface.co/models/facebook/blenderbot-400M-distill",

  //       {
  //           method: "POST",
  //           headers: {
  //               Authorization: `Bearer ${process.env.REACT_HUGGING_SECRET_ACCESS_KEY}`,
  //               "Content-Type": "application/json",
  //           },
  //           body: JSON.stringify(input),
  //       }

  //     );
  //     const result = await response.json();
  //     console.log("response: ", result);
  //     const botResponse  = result?.length> 0 && result[0]?.generated_text;
  //     setMessages([...newMessages, {sender: "bot", text: botResponse}]);
  //     setInput("");

  //     // Determine if the message should trigger sending employee data
  //     // let sessionAttributes = {};
  //     // if (input.trim().toLowerCase() === "taste") {
  //     //   sessionAttributes = {
  //     //     employeeData: JSON.stringify(employeeData) // Send employee data
  //     //   };
  //     // }
  //     // Prepare parameters for Lex
  //     // const params = {
  //     //   botAlias: "EmployeeBot", // Or your specific bot alias
  //     //   botName: "Schedulizer", // Replace with your Lex bot name
  //     //   // botVersion: 'Latest',
  //     //   inputText: input,
  //     //   userId: userId,
  //     //   sessionAttributes: {
  //     //     employeeData: JSON.stringify(data),
  //     //     name: name
  //     //   } // Include only if triggering
  //     // };

  //     // Send message to Lex
  //     // lexRuntime.postText(params, (err, data) => {
  //     //   if (err) {
  //     //     console.log("Error sending message to Lex:", err);
  //     //     // console.log("err", err);
  //     //     setMessages([
  //     //       ...newMessages,
  //     //       {sender: "bot", text: "Error communicating with bot."}
  //     //     ]);
  //     //   } else {
  //     //     // console.log("Lex response:", data);
  //     //     const botMessage = data.message;
  //     //     // Handle additional data if needed
  //     //     if (data.dialogState === "Fulfilled") {
  //     //       const processedDataStr =
  //     //         data.sessionAttributes?.processedData || "[]";
  //     //       if (processedDataStr) {
  //     //         const processedData = JSON.parse(processedDataStr);
  //     //         if (processedData?.length === 0) {
  //     //           setMessages((prev) => [
  //     //             ...prev,
  //     //             {sender: "bot", text: `\n${botMessage}`}
  //     //           ]);
  //     //         } else {
  //     //           const formattedData = processedData
  //     //             .map(
  //     //               (emp) =>
  //     //                 `${emp.first_name} ${emp.last_name}, Date: ${moment(
  //     //                   emp.dob
  //     //                 ).format("DD MMM YYYY")}`
  //     //             )
  //     //             .join("\n");
  //     //           setMessages((prev) => [
  //     //             ...prev,
  //     //             {sender: "bot", text: `\n${formattedData}`}
  //     //           ]);
  //     //         }
  //     //       } else {
  //     //         setMessages([...newMessages, {sender: "bot", text: botMessage}]);
  //     //       }
  //     //     } else {
  //     //       setMessages([...newMessages, {sender: "bot", text: botMessage}]);
  //     //     }
  //     //   }
  //     // });
  //   }
  // };
  useEffect(() => {
    if (isClicked && input) {
      handleSendMessage();
    }
  }, [isClicked, input]);

  const handleDefaultOptionClick = (option) => {
    setInput(option);
    setIsClicked(true);
  };

  return (
    <>
      {isExpanded ? (
        <div
          className={`fixed bottom-4 right-10 w-full max-w-sm bg-white rounded-lg shadow-lg transition-all duration-100 ease-in-out ${"h-96"}`}
          style={{
            backgroundImage:
              "linear-gradient(135deg, #f5f7fa 0%, #c3cfe2 100%)",
            zIndex: 99999000
          }}
        >
          <div className="flex justify-between items-center p-4 border-b border-gray-200">
            <h2 className="text-lg font-semibold">Chat Bot</h2>
            <button
              onClick={toggleExpansion}
              className="text-gray-500 hover:text-gray-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
              aria-label={isExpanded ? "Collapse chat" : "Expand chat"}
            >
              {isExpanded ? <FaChevronDown /> : <FaChevronUp />}
            </button>
          </div>
          {isExpanded && (
            <>
              <div
                ref={chatContainerRef}
                className="h-64 overflow-y-auto p-4 space-y-4"
              >
                {isLoading && (
                  <div className="flex justify-center items-center h-full w-full absolute top-0 left-0 bg-opacity-50 bg-white">
                    <div className="loader"></div>
                  </div>
                )}
                <div className="space-y-2">
                  {defaultOptions.map((option, idx) => (
                    <button
                      key={idx}
                      onClick={() => handleDefaultOptionClick(option)}
                      className="block w-full text-left px-4 py-2 text-indigo-600 bg-gray-100 rounded-lg hover:bg-gray-200"
                    >
                      {option}
                    </button>
                  ))}
                </div>
                {messages.map((message, index) => (
                  <div
                    key={index}
                    className={`flex ${
                      message.sender === "user"
                        ? "justify-end"
                        : "justify-start"
                    }`}
                  >
                    <div
                      className={`max-w-xs p-3 rounded-lg ${
                        message.sender === "user"
                          ? "bg-indigo-500 text-white"
                          : "bg-gray-200 text-gray-800"
                      }`}
                    >
                      {message.text}
                    </div>
                  </div>
                ))}
              </div>
              <div className="p-4 border-t border-gray-200">
                <div className="flex items-center space-x-2">
                  <input
                    type="text"
                    value={input}
                    disabled={isLoading}
                    onChange={handleInputChange}
                    placeholder="Type your message..."
                    className="flex-grow p-2 border border-gray-300 rounded-lg focus:outline-none focus:ring-2 focus:ring-indigo-500"
                  />
                  <button
                    onClick={toggleVoiceInput}
                    disabled={isLoading}
                    className={`p-2 rounded-full ${
                      isListening
                        ? "bg-red-500 text-white"
                        : "bg-gray-200 text-gray-600"
                    }`}
                    aria-label="Start voice input"
                  >
                    <FaMicrophone />
                  </button>
                  <button
                    onClick={handleSendMessage}
                    className="p-2 rounded-full bg-indigo-500 text-white hover:bg-indigo-600"
                    aria-label="Send message"
                  >
                    <FaPaperPlane />
                  </button>
                </div>
              </div>
            </>
          )}
        </div>
      ) : (
        <div className="fixed bottom-4 right-10 w-14 h-14 bg-[#023A51] rounded-full shadow-lg flex items-center justify-center">
          <FaRobot onClick={toggleExpansion} className="text-white" size={36} />
        </div>
      )}
    </>
  );
};

export default ChatBot;
