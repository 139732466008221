import {Link, NavLink, useNavigate} from "react-router-dom";
import {useState, useEffect, useRef} from "react";
import {useParams} from "react-router-dom";
import NavAnchor from "./navLink";
import {useDispatch} from "react-redux";
import {logout, notification} from "../redux/AuthSlice/authSlice";
import Popup from "reactjs-popup";
import {useSelector} from "react-redux";
import {openJoyce} from "../redux/JoyiceSlice/joyiceSlice";
import {
  useCustomizationDetailsQuery,
  useGetCompanyMutation
} from "../services/companyApi";
import AddExpense from "../pages/myexpense/AddExpense";
import AddMileageExpense from "../pages/myexpense/AddMileageExpense";
import AddMyOvertime from "../pages/myovertime/AddMyOvertime";
import AddEmployeeLeave from "../pages/myleave/AddEmployeeLeave";
import {
  useGetAllExpensesListMutation,
  useGetAllMileageExpensesMutation
} from "../services/masterExpensesApi";
import {useGetAllMainExpensesMutation} from "../services/expenseApi";
import {
  useFilterEmployeeByIdMutation,
  useGetAllEmployeeListMutation
} from "../services/employeeApi";
import {useGetAllMainExpensesCSVRecordMutation} from "../services/expenseApi";
import {useMyOvertimeListMutation} from "../services/myOvertimeApi";
import {
  useForgotPasswordMutation,
  useLogoutUserMutation
} from "../services/authApi";
import {toast} from "react-toastify";
import {companyAllData, notificationCount} from "../redux/AuthSlice/authSlice";
import {
  useGetAllNotificationMutation,
  useGetAllNotificationCountMutation,
  useUpdateEmployeeNotificationMutation
} from "../services/notesApi";
import moments from "moment";
import Avatar from "react-avatar";
import {useGetAllChartDashboradMutation} from "../services/dashboardAp";
import {
  REACT_APP_EMPLOYEE_ROLE_ID,
  REACT_APP_SUPER_ADMIN_ROLE_ID,
  REACT_APP_MANAGER_ROLE_ID,
  REACT_APP_COMPANY_ADMIN_ROLE_ID,
  REACT_APP_AUDITOR_ROLE_ID
} from "../helper/constant";
import {useGetPlanMutation} from "../services/authApi";
import AddAbsence from "../pages/absense/AddAbsence";
import {useGetDepartmentListMutation} from "../services/approverListApi";
import {useGetAllLeaveAbsenceMutation} from "../services/leaveAbsenceApi";
import {apiStatusHandler} from "../helper/apiErrorHandler";
import {departmentListAction} from "../redux/DepartmentSlice/departmentSlice";
import {allMainExpenseDataAction} from "../redux/ExpenseSlice/expenseSlice";
import {useGetAllOverTimeMutation} from "../services/overTimeApi";

export function Header() {
  const ignore = useRef(false);

  // useSelector for carrying out data from redux
  const ProfileData = useSelector(
    (state) =>
      state?.persistedReducer?.userPermissionsStates?.user?.profile_image
  );
  const departmentApiData = useSelector(
    (state) => state?.persistedReducer?.departmentSlice?.allDepartmentList
  );
  const loggedInUserData = useSelector(
    (state) => state?.persistedReducer?.userPermissionsStates?.user
  );
  const notificationUnReadCount = useSelector(
    (state) => state?.authState?.noticationCountData
  );
  const approvalsUnReadCount = useSelector(
    (state) => state?.authState?.approvalsCountData
  );
  const formattedDate = useSelector(
    (state) => state?.persistedReducer?.userPermissionsStates?.dateFormats
  );
  const notificationData = useSelector(
    (state) => state?.persistedReducer?.userPermissionsStates?.noticationData
  );
  const firstName = useSelector(
    (state) => state?.persistedReducer?.userPermissionsStates?.user?.first_name
  );
  const lastName = useSelector(
    (state) => state?.persistedReducer?.userPermissionsStates?.user?.last_name
  );
  const permissionsSelector = useSelector(
    (state) => state.persistedReducer.userPermissionsStates.userPermissions
  );
  const showApprovalsTab = useSelector(
    (state) => state?.persistedReducer?.userPermissionsStates?.showApprovalsTab
  );
  const roleIdData = useSelector(
    (state) => state?.persistedReducer?.userPermissionsStates?.user?.roleId
  );
  const auditorRoleId = useSelector(
    (state) => state?.persistedReducer?.userPermissionsStates?.user?.roleId
  );
  const employeeFirstName = useSelector(
    (state) => state?.persistedReducer?.userPermissionsStates?.user?.first_name
  );
  const companyName = useSelector(
    (state) => state?.persistedReducer?.userPermissionsStates?.companies?.name
  );
  const expenseApiRespo = useSelector(
    (state) => state?.persistedReducer?.expenseSlice?.allMainExpenseData
  );
  const imageData = useSelector(
    (state) => state?.authState?.companies?.company_logo
  );
  const adminEmployeeIdData = useSelector(
    (state) => state?.persistedReducer?.userPermissionsStates?.user?.employee_id
  );
  const auditorEmployeeIdData = useSelector(
    (state) => state?.persistedReducer?.userPermissionsStates?.user?.id
  );
  const employeeIdData =
    auditorRoleId === 5 ? auditorEmployeeIdData : adminEmployeeIdData;
  const companyId = useSelector(
    (state) =>
      state?.persistedReducer?.companySlice?.companyData?.companyData?.id
  );
  const companyStyleData = useSelector(
    (state) => state?.persistedReducer?.companySlice?.companyStyleData
  );

  //states and hooks
  // eslint-disable-next-line
  const {data} = useCustomizationDetailsQuery(
    {id: companyId},
    {skip: !companyId}
  );
  const [Helpclick, setHelpClick] = useState(false);
  const [allDepartmentListData, setAllDepartmentListData] = useState();
  const [allEmployeeData, setAllEmployeeData] = useState();
  const [allExpenseType, setAllExpenseType] = useState();
  const [allMileageType, setAllMileageType] = useState();
  const {id} = useParams();
  const [click, setClick] = useState(false);
  const handleClick = () => setClick(!click);
  const handleClick2 = () => setClick(false);
  const dispatch = useDispatch();
  const [open, setOpen] = useState(false);
  const closeModal = () => {
    setOpen(false);
  };
  const toastId = useRef(null);
  // Mutations APi calling
  const [getCompany, {data: companyData, isSuccess: isCompanySuccess}] =
    useGetCompanyMutation();
  const [getAllMileageExpenses, {data: MileageTypeRespons, isSuccess}] =
    useGetAllMileageExpensesMutation();
  const [getplan, {data: planData}] = useGetPlanMutation();
  const [getAllChartDashborad] = useGetAllChartDashboradMutation();
  const [logoutUser, {isSuccess: logoutUserIsSuccess}] =
    useLogoutUserMutation();
  const [
    getAllMaterOverTimeList,
    {data: allMasterOverTimeTypeResponse, isSuccess: isSuccessAllMasterOvertime}
  ] = useGetAllOverTimeMutation();
  const [
    forgotPassword,
    {
      isLoading: forgetIsLoading,
      isSuccess: forgetIsSuccess,
      isError: forgetIsError,
      error: forgetError
    }
  ] = useForgotPasswordMutation();
  const [
    getAllExpensesList,
    {data: expensesTypeResponseApi, isSuccess: isSuccessExpenseType}
  ] = useGetAllExpensesListMutation();
  const [
    getAllMainExpenses,
    {data: expenseApiRespoData, isSuccess: isSuccessExpenses}
  ] = useGetAllMainExpensesMutation();
  const [getAllMainExpensesCSVRecord] =
    useGetAllMainExpensesCSVRecordMutation();
  const [myOvertimeList] = useMyOvertimeListMutation();
  const [filterEmployeeById] = useFilterEmployeeByIdMutation();
  const [
    getDepartmentList,
    {data: departmentApiResponse, isSuccess: isSuccessDepartmentApi}
  ] = useGetDepartmentListMutation();
  const [getAllLeaveAbsence] = useGetAllLeaveAbsenceMutation();
  const [
    getAllEmployeeList,
    {data: filterEmployeeResponse, isSuccess: isSuccessAllEmployee}
  ] = useGetAllEmployeeListMutation();
  const [
    getAllNotification,
    {data: getNotificationRespo, isSuccess: isSuccessNotification}
  ] = useGetAllNotificationMutation();
  const [updateEmployeeNotification, {isSuccess: isSuccessRead}] =
    useUpdateEmployeeNotificationMutation();
  const [
    getAllNotificationCount,
    {
      data: notificationCountRespo,
      isSuccess: isSuccessNotificationCount,
      isError: isNotiError,
      error: notiError
    }
  ] = useGetAllNotificationCountMutation();

  //Permissions
  const companyReadPermissions = permissionsSelector?.filter(
    (obj) =>
      obj?.module_name === "Company Information" &&
      obj?.module_permission_name === "Read"
  )[0]?.status;
  const LeaveTypesReadPermissions = permissionsSelector?.filter(
    (obj) =>
      obj?.module_name === "Leave Types" &&
      obj?.module_permission_name === "Read"
  )[0]?.status;
  const expenseTypesReadPermissions = permissionsSelector?.filter(
    (obj) =>
      obj?.module_name === "Expense Types" &&
      obj?.module_permission_name === "Read"
  )[0]?.status;
  const overTimeTypesReadPermissions = permissionsSelector?.filter(
    (obj) =>
      obj?.module_name === "Overtime Types" &&
      obj?.module_permission_name === "Read"
  )[0]?.status;
  const restrictedDaysReadPermissions = permissionsSelector?.filter(
    (obj) =>
      obj?.module_name === "Restricted Days" &&
      obj?.module_permission_name === "Read"
  )[0]?.status;
  const departmentsReadPermissions = permissionsSelector?.filter(
    (obj) =>
      obj?.module_name === "Departments" &&
      obj?.module_permission_name === "Read"
  )[0]?.status;
  const carryForwardReadPermission = permissionsSelector?.filter(
    (obj) =>
      obj?.module_name === "Carry Forwarded" &&
      obj?.module_permission_name === "Read"
  )[0]?.status;
  const bankHolidayReadPermission = permissionsSelector?.filter(
    (obj) =>
      obj?.module_name === "Bank Holidays" &&
      obj?.module_permission_name === "Read"
  )[0]?.status;
  const importDataReadPermission = permissionsSelector?.filter(
    (obj) =>
      obj?.module_name === "Import Data" &&
      obj?.module_permission_name === "Read"
  )[0]?.status;
  const billingReadPermission = permissionsSelector?.filter(
    (obj) =>
      obj?.module_name === "Billing" && obj?.module_permission_name === "Read"
  )[0]?.status;
  const privacyReadPermissions = permissionsSelector?.filter(
    (obj) =>
      obj?.module_name === "Privacy" && obj?.module_permission_name === "Read"
  )[0]?.status;
  const generalSettingsReadPermissions = permissionsSelector?.filter(
    (obj) =>
      obj?.module_name === "General Settings" &&
      obj?.module_permission_name === "Read"
  )[0]?.status;
  const dashboardReadPermissions = permissionsSelector?.filter(
    (obj) =>
      obj?.module_name === "Dashboard" && obj?.module_permission_name === "Read"
  );
  const approvalsReadPermissions = permissionsSelector?.filter(
    (obj) =>
      obj?.module_name === "Approvals" && obj?.module_permission_name === "Read"
  );
  const absenceReadPermissions = permissionsSelector?.filter(
    (obj) =>
      obj?.module_name === "Absence" && obj?.module_permission_name === "Read"
  );
  const reportsReadPermissions = permissionsSelector?.filter(
    (obj) =>
      obj?.module_name === "Reports" && obj?.module_permission_name === "Read"
  );
  const calendersReadPermissions = permissionsSelector?.filter(
    (obj) =>
      obj?.module_name === "Leave Calenders" &&
      obj?.module_permission_name === "Read"
  );
  const activeEmployeeReadPermissions = permissionsSelector?.filter(
    (obj) =>
      obj?.module_name === "Active Employee" &&
      obj?.module_permission_name === "Read"
  );
  const LeaversEmployeeReadPermissions = permissionsSelector?.filter(
    (obj) =>
      obj?.module_name === "Leavers" && obj?.module_permission_name === "Read"
  );
  const myLeaveReadPermissions = permissionsSelector?.filter(
    (obj) =>
      obj?.module_name === "My Leave" && obj?.module_permission_name === "Read"
  );
  const myExpensesReadPermissions = permissionsSelector?.filter(
    (obj) =>
      obj?.module_name === "My Expenses" &&
      obj?.module_permission_name === "Read"
  );
  const myOvertimeReadPermissions = permissionsSelector?.filter(
    (obj) =>
      obj?.module_name === "My Overtime" &&
      obj?.module_permission_name === "Read"
  );

  useEffect(
    () => {
      if (companyData?.companyData !== undefined) {
        dispatch(companyAllData(companyData?.companyData));
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [isCompanySuccess]
  );

  useEffect(
    () => {
      if (isSuccessNotificationCount) {
        dispatch(notificationCount(notificationCountRespo?.unreadCount));
      }
      if (isSuccessNotification) {
        dispatch(notification(getNotificationRespo?.data));
      }
      if (isNotiError) {
        toast.dismiss(toastId.current);
        const toastMessage = notiError?.status
          ? notiError?.data?.error
          : "Something went wrong";
        toast.error(toastMessage, {
          render: toastMessage,
          type: "error",
          autoClose: 8000,
          isLoading: false,
          theme: "colored",
          closeOnClick: true,
          pauseOnHover: true
        });
        // alert("Session Expired");
        apiStatusHandler(notiError?.status);
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [isSuccessNotification, isNotiError, isSuccessNotificationCount]
  );

  useEffect(
    () => {
      if (!ignore.current) {
        getCompany();
        getDepartmentList();
        getAllEmployeeList();
        getAllExpensesList();
        getplan();
        getAllMileageExpenses();
        if (auditorRoleId === 5) {
          return;
        }
        let payload = `/employeenotification?employee_id=${employeeIdData}`;
        getAllNotification(payload);
        let payload1 = `/employeenotification/count/?employee_id=${employeeIdData}`;
        getAllNotificationCount(payload1);
        let payloads = `/expenses/filter/search?page=${1}&employee_id=${employeeIdData}&limit=100`;
        getAllMainExpensesCSVRecord(payloads);
        const url = `/masterovertime?page=${1}&userSpecific=${1}&limit=50`;
        getAllMaterOverTimeList(url);
      }
      return () => {
        ignore.current = true;
      };
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );

  useEffect(
    () => {
      if (isSuccessExpenseType) {
        setAllExpenseType(expensesTypeResponseApi.data);
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [isSuccessExpenseType]
  );

  useEffect(
    () => {
      if (isSuccess) {
        setAllMileageType(MileageTypeRespons?.data);
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [isSuccess]
  );
  useEffect(
    () => {
      if (isSuccessExpenses) {
        dispatch(allMainExpenseDataAction(expenseApiRespoData));
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [isSuccessExpenses]
  );

  useEffect(
    () => {
      if (expenseApiRespo) {
        if (
          expenseApiRespo?.appliedExpense?.rows < 1 &&
          expenseApiRespo?.currentPage > 1
        ) {
          getAllExpensesList(expenseApiRespo?.currentPage - 1);
        }
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [expenseApiRespo]
  );

  const selectGetCompanyData = (state) => {
    const mutations = state?.companyAPI?.mutations;
    const getCompanyMutation = Object.values(mutations).find(
      (mutation) => mutation.endpointName === "getCompany"
    );

    return getCompanyMutation?.data?.companyData;
  };
  const employee = useSelector(selectGetCompanyData);

  const handelJoyce = async () => {
    await dispatch(openJoyce({isJoyce: true, isSignup: false, data: []}));
    setHelpClick(!Helpclick);
  };

  const handleHelpClick = () => {
    setHelpClick(!Helpclick);
  };

  const handleMarkAllAsRead = async () => {
    if (auditorRoleId === 5) {
      return;
    }
    updateEmployeeNotification(0);
    let payload = `/employeenotification?employee_id=${employeeIdData}`;
    getAllNotification(payload);
    let payload1 = `/employeenotification/count/?employee_id=${employeeIdData}`;
    getAllNotificationCount(payload1);
  };

  const navigate = useNavigate();
  const accessToken = localStorage?.getItem("accessToken");
  const handlerLogout = (e) => {
    e.preventDefault();
    dispatch(logout());
    navigate("/");
    logoutUser(accessToken);
    localStorage.clear();
  };

  useEffect(
    () => {
      if (logoutUserIsSuccess) {
        localStorage.removeItem("accessToken");
        localStorage.removeItem("persist:root");
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [logoutUserIsSuccess]
  );

  useEffect(
    () => {
      if (forgetIsLoading) {
        toastId.current = toast.loading("Please wait...", {
          position: "top-center",
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          theme: "colored",
          type: "info"
        });
      }
      if (forgetIsSuccess) {
        toast.dismiss(toastId.current);
        toast.update(
          toastId.current,
          ("Reset link sent successfully to your email.",
          {
            render: "Reset link sent successfully to your email.",
            type: "success",
            autoClose: 2000,
            isLoading: false
          })
        );
      }
      if (isSuccessRead) {
        toast.dismiss(toastId.current);
        toast.update(
          toastId.current,
          ("All notification marked as viewed.",
          {
            render: "Al notification marked as viewed.",
            type: "success",
            autoClose: 2000,
            isLoading: false
          })
        );
      }
      if (forgetIsError) {
        toast.dismiss(toastId.current);
        const toastMessage = forgetError?.data?.message
          ? forgetError?.data?.message
          : "Something went wrong";
        toast.error(toastMessage, {
          render: toastMessage,
          type: "error",
          autoClose: 2000,
          duplicate: false,
          isLoading: false
        });
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [forgetIsLoading, forgetIsSuccess, forgetIsError, isSuccessRead]
  );

  const handlerResetPassword = (e, employee, close) => {
    e.preventDefault();
    const data = {
      email: employee.email
    };
    forgotPassword(data.email);
    close();
  };

  useEffect(
    () => {
      if (isSuccessAllEmployee) {
        setAllEmployeeData(filterEmployeeResponse.data);
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [isSuccessAllEmployee]
  );
  useEffect(
    () => {
      if (isSuccessDepartmentApi) {
        dispatch(departmentListAction(departmentApiResponse));
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [isSuccessDepartmentApi]
  );

  useEffect(
    () => {
      let arr = [];
      if (departmentApiData) {
        departmentApiData?.data?.length > 0 &&
          departmentApiData?.data?.forEach((dept) => {
            if (
              loggedInUserData?.roleId == REACT_APP_MANAGER_ROLE_ID &&
              loggedInUserData?.department_id &&
              dept?.id == loggedInUserData?.department_id
            ) {
              let deptOption = {
                value: dept?.id,
                label: dept?.department_name
              };
              arr.push(deptOption);
            }
            if (loggedInUserData?.roleId == REACT_APP_COMPANY_ADMIN_ROLE_ID) {
              let deptOption = {
                value: dept?.id,
                label: dept?.department_name
              };
              arr.push(deptOption);
            }
            if (
              loggedInUserData?.roleId == REACT_APP_EMPLOYEE_ROLE_ID &&
              loggedInUserData.department_id
            ) {
              let deptOption = {
                value: dept?.id,
                label: dept?.department_name
              };
              arr.push(deptOption);
            }
          });
        setAllDepartmentListData(arr);
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [loggedInUserData, departmentApiData]
  );
  //-----------------------------------------------------------------------------

  return (
    <>
      <header
        style={{
          backgroundColor: companyStyleData?.top_panel_bar_color?.startsWith(
            "#"
          )
            ? companyStyleData?.top_panel_bar_color
            : `#${companyStyleData?.top_panel_bar_color}`,
          color: companyStyleData?.font_color?.startsWith("#")
            ? companyStyleData?.font_color
            : `#${companyStyleData?.font_color}`,
          font: `${companyStyleData?.font_style}`
        }}
        className="bg-primary shadow-sm 	"
      >
        <div className="container mx-auto">
          <div className="flex items-center ">
            <div className="headerLeft flex items-center justify-between  w-full ">
              <div className="logo   ">
                {roleIdData === REACT_APP_SUPER_ADMIN_ROLE_ID ? (
                  <Link to="/dashboard">
                    <img
                      src={
                        companyStyleData?.platform_default_logo === "0"
                          ? companyStyleData?.custom_logo_url
                          : "/images/logo-leave.webp"
                      }
                      alt={"Logo"}
                      className={
                        companyStyleData?.platform_default_logo === "0"
                          ? "defaultLogo"
                          : "max-w-full"
                      }
                    />
                  </Link>
                ) : (
                  <Link to="/dashboard">
                    <img
                      src={
                        companyStyleData?.platform_default_logo === "0"
                          ? companyStyleData?.custom_logo_url
                          : "/images/logo-leave.webp"
                      }
                      alt={"Logo"}
                      className={
                        companyStyleData?.platform_default_logo === "0"
                          ? "defaultLogo"
                          : "max-w-full"
                      }
                    />
                  </Link>
                )}
              </div>

              <div className="headerRight flex items-center">
                <div
                  className={click ? "show headerNav " : "headerNav "}
                  style={{
                    color: companyStyleData?.font_color?.startsWith("#")
                      ? companyStyleData?.font_color
                      : `#${companyStyleData?.font_color}`
                  }}
                  onClick={handleClick2}
                >
                  <div
                    className="toggleIcon block lg:hidden absolute right-0 top-0 bg-white px-2 "
                    onClick={handleClick}
                  >
                    <span
                      className={
                        click
                          ? "fa fa-close text-3xl secondry-text cursor-pointer"
                          : "fa fa-close  text-3xl cursor-pointer secondry-text"
                      }
                    />
                  </div>

                  <ul className="lg:flex block lg:mt-0 mt-6">
                    {dashboardReadPermissions[0]?.status === true &&
                    roleIdData !== REACT_APP_EMPLOYEE_ROLE_ID ? (
                      <>
                        <li
                          style={{
                            color: companyStyleData?.font_color?.startsWith("#")
                              ? companyStyleData?.font_color
                              : `#${companyStyleData?.font_color}`
                          }}
                        >
                          <NavAnchor
                            style={{
                              color: companyStyleData?.font_color?.startsWith(
                                "#"
                              )
                                ? companyStyleData?.font_color
                                : `#${companyStyleData?.font_color}`
                            }}
                            className="Dashboard"
                            LinkUrl={"/dashboard"}
                            navText=" Dashboard"
                            navIcon="fa fa-dashboard"
                          />
                        </li>
                        {approvalsReadPermissions[0]?.status === true &&
                          roleIdData !== REACT_APP_SUPER_ADMIN_ROLE_ID &&
                          roleIdData !== REACT_APP_AUDITOR_ROLE_ID && (
                            <li>
                              <NavAnchor
                                className="Approvals "
                                LinkUrl="/approvals"
                                navText="Approvals"
                                navIcon="fa fa-check-circle"
                              />
                              {approvalsUnReadCount ? (
                                <span className="approvalsCount">
                                  {approvalsUnReadCount}
                                </span>
                              ) : null}
                            </li>
                          )}
                        {absenceReadPermissions[0]?.status === true &&
                          roleIdData !== REACT_APP_SUPER_ADMIN_ROLE_ID && (
                            <li>
                              <NavAnchor
                                className="Absence"
                                LinkUrl="/absense"
                                navText=" Absence"
                                navIcon="fa fa-calendar-times"
                              />
                            </li>
                          )}
                        {calendersReadPermissions[0]?.status === true &&
                          roleIdData !== REACT_APP_SUPER_ADMIN_ROLE_ID && (
                            <li>
                              <NavAnchor
                                className="Leave"
                                LinkUrl="/leave-calendar"
                                navText=" Leave Calendar "
                                navIcon="fa fa-calendar"
                              />
                            </li>
                          )}
                      </>
                    ) : (
                      <>
                        <li
                          style={{
                            color: companyStyleData?.font_color?.startsWith("#")
                              ? companyStyleData?.font_color
                              : `#${companyStyleData?.font_color}`,
                              fontFamily: companyStyleData?.font_style
                          }}
                        >
                          <NavAnchor
                            style={{
                              color: companyStyleData?.font_color?.startsWith(
                                "#"
                              )
                                ? companyStyleData?.font_color
                                : `#${companyStyleData?.font_color}`
                            }}
                            className="Dashboard"
                            LinkUrl="/dashboard"
                            navText=" Dashboard"
                            navIcon="fa fa-dashboard"
                          />
                        </li>
                        {approvalsReadPermissions[0]?.status === true &&
                          roleIdData !== REACT_APP_SUPER_ADMIN_ROLE_ID && (
                            <li>
                              {roleIdData === 4 ? ( // Check if roleIdData is 4
                                showApprovalsTab === true ? ( // If showApprovalTab is true
                                  <NavAnchor
                                    className="Approvals"
                                    LinkUrl="/approvals"
                                    navText="Approvals"
                                    navIcon="fa fa-check-circle"
                                  />
                                ) : null
                              ) : (
                                // For other roleIdData, always show the tab
                                <NavAnchor
                                  className="Approvals"
                                  LinkUrl="/approvals"
                                  navText="Approvals"
                                  navIcon="fa fa-check-circle"
                                />
                              )}
                              {/* Render unread count if available */}
                              {approvalsUnReadCount ? (
                                <span className="approvalsCount">
                                  {approvalsUnReadCount}
                                </span>
                              ) : null}
                            </li>
                          )}

                        {calendersReadPermissions[0]?.status === true && (
                          <li>
                            <NavAnchor
                              className="Leave"
                              LinkUrl="/leave-calendar"
                              navText=" Leave Calendar "
                              navIcon="fa fa-calendar"
                            />
                          </li>
                        )}
                        {myLeaveReadPermissions[0]?.status === true ? (
                          <li className="navpad">
                            <NavAnchor
                              LinkUrl="/my-leave"
                              navText="My Leave"
                              navIcon="fa fa-calendar"
                            />
                          </li>
                        ) : (
                          <> </>
                        )}
                        {myExpensesReadPermissions[0]?.status === true ? (
                          <li className="navpad">
                            <NavAnchor
                              LinkUrl="/expense"
                              navText="My Expenses"
                              navIcon="fa fa-credit-card"
                            />
                          </li>
                        ) : (
                          <></>
                        )}
                        {myOvertimeReadPermissions[0]?.status === true ? (
                          <li className="navpad">
                            <NavAnchor
                              LinkUrl="/overtime"
                              navText="My Overtime/Time in
                        Lieu"
                              navIcon="fa fa-clock"
                            />
                          </li>
                        ) : (
                          <></>
                        )}
                      </>
                    )}
                    <div className="block lg:hidden">
                      {myLeaveReadPermissions[0]?.status === true ? (
                        <li>
                          <NavAnchor
                            LinkUrl="/my-leave"
                            navText="My Leave"
                            navIcon="fa fa-calendar"
                          />
                        </li>
                      ) : (
                        <> </>
                      )}

                      {myExpensesReadPermissions[0]?.status === true ? (
                        <li>
                          <NavAnchor
                            LinkUrl="/expense"
                            navText="My Expenses"
                            navIcon="fa fa-credit-card"
                          />
                        </li>
                      ) : (
                        <></>
                      )}
                      {myOvertimeReadPermissions[0]?.status === true ? (
                        <li>
                          <NavAnchor
                            LinkUrl="/overtime"
                            navText="My overtime/time in
                        lieu"
                            navIcon="fa fa-clock"
                          />
                        </li>
                      ) : (
                        <></>
                      )}
                    </div>
                    {(LeaversEmployeeReadPermissions[0]?.status === true ||
                      activeEmployeeReadPermissions[0]?.status === true) &&
                      roleIdData !== REACT_APP_SUPER_ADMIN_ROLE_ID && (
                        <li>
                          <NavAnchor
                            className="Employees"
                            LinkUrl="/employees"
                            navText="Employees"
                            navIcon="fa fa-users"
                          />
                        </li>
                      )}

                    {reportsReadPermissions[0]?.status === true &&
                      roleIdData !== REACT_APP_SUPER_ADMIN_ROLE_ID && (
                        <li>
                          <NavAnchor
                            className="Reports"
                            LinkUrl="/report"
                            navText="Reports"
                            navIcon="fa fa-file"
                          />
                        </li>
                      )}

                    {roleIdData === REACT_APP_SUPER_ADMIN_ROLE_ID && (
                      <li>
                        <NavAnchor
                          className="Company"
                          LinkUrl="/super/company"
                          navText="Company"
                          navIcon="fa fa-file"
                        />
                      </li>
                    )}

                    {roleIdData === 4 && (
                      <li className="MyDocuments">
                        {/* <NavAnchor LinkUrl={`/employees/detail/${employeeIdData}`} state={"EmployeeDocument"} navText=<span className="text-green-500">My Documents</span>  navIcon="fa fa-file text-green-500" /> */}
                        <NavLink
                          to={`/my-profile/${employeeIdData}`}
                          state={{activeTab: "EmployeeDocument"}}
                        >
                          <span>
                            <i className="fa fa-file " /> My Documents
                          </span>
                        </NavLink>
                      </li>
                    )}

                    {/* <li>
                  <NavAnchor className="Settings" LinkUrl="/setting/info" navText="Settings" navIcon="fa fa-cog"/>       
                
                  </li> */}
                  </ul>
                </div>
                <div className="nav relative ">
                  <ul className="flex justify-end items-center   ">
                    <li>
                      <div
                        className="toggleIcon block lg:hidden"
                        onClick={handleClick}
                      >
                        <span
                          className={
                            click
                              ? "fa fa-close mr-3 text-3xl text-white cursor-pointer"
                              : "fa fa-bars mr-3 text-3xl cursor-pointer text-white"
                          }
                        />
                      </div>
                    </li>
                    {roleIdData === REACT_APP_EMPLOYEE_ROLE_ID ? null : (
                      <>
                        {planData?.current_active_plan === null ? (
                          // Redirect to "/setting/billing" if current_active_plan is null
                          <NavLink
                            to={(() => {
                              switch (true) {
                                case billingReadPermission:
                                  return "/setting/billing";
                              }
                            })()}
                            className="Settings bell mr-1 cursor-pointer inline-block"
                          >
                            <i className="fa fa-cog text-xl text-white opacity-80" />
                          </NavLink>
                        ) : (
                          // (roleIdData || roleId) === REACT_APP_COMPANY_ADMIN_ROLE_ID && <li>
                          <NavLink
                            to={(() => {
                              switch (true) {
                                case companyReadPermissions:
                                  return "/setting/info";
                                case LeaveTypesReadPermissions:
                                  return "/setting/leave";
                                case expenseTypesReadPermissions:
                                  return "/setting/expenses";
                                case overTimeTypesReadPermissions:
                                  return "/setting/overtime";
                                case restrictedDaysReadPermissions:
                                  return "/setting/restrciteddays";
                                case departmentsReadPermissions:
                                  return "/setting/department";
                                case carryForwardReadPermission:
                                  return "/setting/carryforward";
                                case bankHolidayReadPermission:
                                  return "/setting/holiday";
                                case importDataReadPermission:
                                  return "/setting/importdata";
                                case billingReadPermission:
                                  return "/setting/billing";
                                case privacyReadPermissions:
                                  return "/setting/privacy";
                                case generalSettingsReadPermissions:
                                  return "/setting/general";
                                default:
                                  return "/dashboard";
                              }
                            })()}
                            className="Settings bell mr-1 cursor-pointer inline-block"
                          >
                            <i className="fa fa-cog text-xl text-white opacity-80" />
                          </NavLink>
                          // </li>
                        )}
                      </>
                    )}

                    <li>
                      <div className="bell mr-1 cursor-pointer Notifications ">
                        <i className="fa fa-bell text-xl secondry-text" />
                        <div>
                          {notificationUnReadCount > 0 && (
                            <span className="bellCount">
                              {notificationUnReadCount}
                            </span>
                          )}
                        </div>
                      </div>
                      <div className="notification">
                        <ul>
                          {notificationData?.map((notification, index) => {
                            const handleClick = () => {
                              if (
                                notification?.title ===
                                  "Subscription Canceled" ||
                                notification?.title ===
                                  "Trial period Notification"
                              ) {
                                navigate("/setting/billing");
                              } else if (
                                notification?.title ===
                                "Overtime/Time in Lieu Request"
                              ) {
                                navigate("/approvals", {
                                  state: {activeTab: "Overtime"}
                                });
                              } else if (
                                notification?.title === "Leave Request"
                              ) {
                                navigate("/approvals", {
                                  state: {activeTab: "Leave"}
                                });
                              } else if (
                                notification?.title ===
                                "Mileage Expense Request"
                              ) {
                                navigate("/approvals", {
                                  state: {activeTab: "Expenses"}
                                });
                              } else if (
                                notification?.title === "Absence Marked"
                              ) {
                                navigate("/absense");
                              } else if (
                                notification?.title === "Folder Shared"
                              ) {
                                navigate(`/my-profile/${employeeIdData}`, {
                                  state: {
                                    activeTab: "EmployeeDocument",
                                    active: "Shared Folder"
                                  }
                                });
                              } else if (
                                notification?.title === "Expense Paid" ||
                                notification?.title === "Expense Approved"
                              ) {
                                navigate("/expense");
                              } else if (
                                notification?.title ===
                                  "Leave Request Declined" ||
                                notification?.title === "Leave Approved"
                              ) {
                                navigate("/my-leave", {
                                  state: {activeTab: "leaveRequest"}
                                });
                              } else if (
                                notification?.title === "Expense Request"
                              ) {
                                navigate("/approvals", {
                                  state: {activeTab: "Expenses"}
                                }); //added to resolve the problem
                              } else {
                                navigate("/dashboard");
                              }
                            };
                            return (
                              <li key={index}>
                                <div
                                  className="flex items-baseline px-4 py-2 cursor-pointer"
                                  onClick={handleClick}
                                >
                                  <span className=" w-6 h-6 flex justify-center items-center rounded-full bg-yellow-light secondry-text">
                                    <span
                                      className={`fa ${
                                        notification?.fa_icon
                                          ? notification?.fa_icon
                                          : ""
                                      }`}
                                    />{" "}
                                    {/* Use the FontAwesome icon class */}
                                  </span>

                                  <div className="ml-3">
                                    <h3>{notification?.title}</h3>
                                    <p>{notification?.message}</p>
                                    <p>
                                      {moments(notification?.createdAt).format(
                                        formattedDate
                                      )}{" "}
                                      {moments(notification?.createdAt).format(
                                        "HH:mm"
                                      )}
                                    </p>
                                  </div>
                                </div>
                                <hr />
                              </li>
                            );
                          })}
                        </ul>
                        <div className="flex border-t justify-between items-center px-4 pt-4 pb-2 ">
                          <Link
                            to="/notification"
                            className=" secondry-text  font-medium text-sm "
                          >
                            View All
                          </Link>
                          <p
                            className="secondry-text font-medium text-sm cursor-pointer"
                            onClick={handleMarkAllAsRead}
                          >
                            Mark All as read
                          </p>
                        </div>
                      </div>
                    </li>
                    <li className="relative py-2">
                      <div
                        className="bell mr-1 cursor-pointer Help inline-block "
                        onClick={handleHelpClick}
                      >
                        <i className="fa fa-question font-bold text-xl text-white opacity-100" />
                      </div>
                      <div
                        className={
                          Helpclick
                            ? "  show notification max-w-[280px] min-w-[280px] "
                            : "notification max-w-[280px] min-w-[280px]"
                        }
                      >
                        <div
                          className="flex items-center p-2"
                          onClick={handelJoyce}
                          style={{
                            color: companyStyleData?.font_color?.startsWith("#")
                              ? companyStyleData?.font_color
                              : `#${companyStyleData?.font_color}`
                          }}
                        >
                          <Link
                            style={{
                              color: companyStyleData?.font_color?.startsWith(
                                "#"
                              )
                                ? companyStyleData?.font_color
                                : `#${companyStyleData?.font_color}`
                            }}
                            className=" p-1 px-3 rounded text-md text-left "
                          >
                            <i
                              style={{
                                color: companyStyleData?.font_color?.startsWith(
                                  "#"
                                )
                                  ? companyStyleData?.font_color
                                  : `#${companyStyleData?.font_color}`
                              }}
                              className="fa fa-arrow-right mr-2 primary-text"
                            />
                            Getting Started
                          </Link>
                        </div>
                        <div className="flex items-center p-2">
                          <Popup
                            open={open}
                            onClose={() => closeModal()}
                            closeOnDocumentClick={false}
                            className="popUp480"
                            trigger={
                              <button
                                className="p-1 px-3 rounded text-md text-left primary-text"
                                aria-describedby="popup-8"
                                id="resetPassword"
                                style={{
                                  color:
                                    companyStyleData?.font_color?.startsWith(
                                      "#"
                                    )
                                      ? companyStyleData?.font_color
                                      : `#${companyStyleData?.font_color}`
                                }}
                                onClick={() => setOpen((o) => !o)}
                              >
                                <i
                                  style={{
                                    color:
                                      companyStyleData?.font_color?.startsWith(
                                        "#"
                                      )
                                        ? companyStyleData?.font_color
                                        : `#${companyStyleData?.font_color}`
                                  }}
                                  className="fa fa-lock mr-2 primary-text"
                                />
                                Reset Password
                              </button>
                            }
                            modal
                            nested
                          >
                            {(close) => (
                              <div className="modal">
                                <button
                                  id="resetPasswordClose"
                                  className="close"
                                  onClick={() => {
                                    closeModal();
                                    close();
                                  }}
                                >
                                  &times;
                                </button>

                                <div className="header">
                                  <h3>Reset Password</h3>
                                </div>
                                <div className="content">
                                  <p>
                                    Are you sure you want to reset password ?
                                  </p>
                                  <div className="w-full mt-8">
                                    <div className="form-group pt-4 pb-3 flex justify-center ">
                                      <button
                                        className="btn save mr-2"
                                        id="resetPasswordYes"
                                        onClick={(e) =>
                                          handlerResetPassword(
                                            e,
                                            employee,
                                            close
                                          )
                                        }
                                      >
                                        Yes
                                      </button>
                                      <button
                                        type="button"
                                        id="resetPasswordNo"
                                        onClick={() => close()}
                                        className="btn cancel "
                                      >
                                        No
                                      </button>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            )}
                          </Popup>
                        </div>
                        {/* <div className="flex items-center p-2">
                          <Popup
                            open={open}
                            onClose={() => closeModal()}
                            closeOnDocumentClick={false}
                            className="popUp480"
                            trigger={
                              <button
                                className="p-1 px-3 rounded text-md text-left primary-text"
                                aria-describedby="popup-8"
                                onClick={() => setOpen((o) => !o)}
                              >
                                <i className="fa fa-share mr-2 primary-text " />

                                Referral Code
                              </button>
                            }
                            modal
                            nested
                          >
                            {(close) => (
                              <div className="modal">
                                <button
                                  className="close"
                                  onClick={() => {
                                    closeModal();
                                    close();
                                  }}
                                >
                                  &times;
                                </button>

                                <div className="content">
                                  <h3 className="text-xl mb-3">   Referral Code: <span className="secondry-text">Sched10</span> </h3>
                                  <h3 className="text-base"> Your referrals who have subscribed to schedulize </h3>
                                  <ul className="list-disc pl-4 pt-4 text-sm leading-6">
                                    <li>John</li>
                                    <li>Shane</li>
                                    <li>Warner</li>
                                  </ul>
                                </div>
                              </div>
                            )}
                          </Popup>

                        </div> */}
                        <div className="flex items-center p-2">
                          <Link
                            style={{
                              color: companyStyleData?.font_color?.startsWith(
                                "#"
                              )
                                ? companyStyleData?.font_color
                                : `#${companyStyleData?.font_color}`
                            }}
                            className=" p-1 px-3 rounded text-md text-left "
                            to="https://schedulize.co.uk/knowledge-base/"
                            target="_blank"
                          >
                            <i
                              style={{
                                color: companyStyleData?.font_color?.startsWith(
                                  "#"
                                )
                                  ? companyStyleData?.font_color
                                  : `#${companyStyleData?.font_color}`
                              }}
                              className="fa fa-lightbulb mr-2 primary-text"
                            />
                            Knowledge Base
                          </Link>
                        </div>
                        <div className="flex items-center p-2">
                          <Link
                            style={{
                              color: companyStyleData?.font_color?.startsWith(
                                "#"
                              )
                                ? companyStyleData?.font_color
                                : `#${companyStyleData?.font_color}`
                            }}
                            className=" p-1 px-3 rounded text-md text-left "
                            to={`${process.env.REACT_APP_BASE_URL}/holiday-calculator`}
                            target="_blank"
                          >
                            <i
                              style={{
                                color: companyStyleData?.font_color?.startsWith(
                                  "#"
                                )
                                  ? companyStyleData?.font_color
                                  : `#${companyStyleData?.font_color}`
                              }}
                              className="fa-solid fa-calculator mr-2 primary-text"
                            />
                            Holiday Calculator
                          </Link>
                        </div>
                        <div className="flex items-center p-2">
                          <Link
                            style={{
                              color: companyStyleData?.font_color?.startsWith(
                                "#"
                              )
                                ? companyStyleData?.font_color
                                : `#${companyStyleData?.font_color}`
                            }}
                            className=" p-1 px-3 rounded text-md text-left "
                            to="https://schedulize.co.uk/contact/"
                            target="_blank"
                          >
                            <i
                              style={{
                                color: companyStyleData?.font_color?.startsWith(
                                  "#"
                                )
                                  ? companyStyleData?.font_color
                                  : `#${companyStyleData?.font_color}`
                              }}
                              className="fa fa-comments mr-2 primary-text"
                            />
                            Suggest an Improvement
                          </Link>
                        </div>
                        <div className="flex items-center p-2">
                          <Link
                            style={{
                              color: companyStyleData?.font_color?.startsWith(
                                "#"
                              )
                                ? companyStyleData?.font_color
                                : `#${companyStyleData?.font_color}`
                            }}
                            className=" p-1 px-3 rounded text-md text-left "
                            to="https://schedulize.co.uk/contact/"
                          >
                            <i
                              style={{
                                color: companyStyleData?.font_color?.startsWith(
                                  "#"
                                )
                                  ? companyStyleData?.font_color
                                  : `#${companyStyleData?.font_color}`
                              }}
                              className="fa fa-phone mr-2 primary-text"
                            />
                            Contact Support
                          </Link>
                        </div>
                        <div className="flex items-center p-2">
                          <Link
                            style={{
                              color: companyStyleData?.font_color?.startsWith(
                                "#"
                              )
                                ? companyStyleData?.font_color
                                : `#${companyStyleData?.font_color}`
                            }}
                            className=" p-1 px-3 rounded text-md text-left "
                            to="https://schedulize.co.uk/book-a-1-1-session/"
                            target="_blank"
                          >
                            <i
                              style={{
                                color: companyStyleData?.font_color?.startsWith(
                                  "#"
                                )
                                  ? companyStyleData?.font_color
                                  : `#${companyStyleData?.font_color}`
                              }}
                              className="fa fa-clock mr-2 primary-text"
                            />
                            Arrange a 1-1 Support Session
                          </Link>
                        </div>
                      </div>
                    </li>
                    <li className="relative">
                      <div className="dropdown relative z-30">
                        <div className="text-black dark:text-white block cursor-pointer MyProfile">
                          {roleIdData === REACT_APP_AUDITOR_ROLE_ID ? (
                            <Avatar
                              name={`${"E"} ${"U"}`}
                              size="32"
                              round={true}
                            />
                          ) : ProfileData ? (
                            <img
                              src={ProfileData}
                              alt={"Logo"}
                              className="md:w-8 w-8 bg-white rounded-full"
                            />
                          ) : (
                            <Avatar
                              name={`${firstName} ${lastName}`}
                              size="32"
                              round={true}
                            />
                          )}
                        </div>
                        <div className="notification max-w-[150px]">
                          <div className="flex items-center p-2">
                            {roleIdData !== REACT_APP_SUPER_ADMIN_ROLE_ID &&
                              roleIdData !== REACT_APP_AUDITOR_ROLE_ID && (
                                <div className="flex items-center px-2">
                                  <Link
                                    style={{
                                      color:
                                        companyStyleData?.font_color?.startsWith(
                                          "#"
                                        )
                                          ? companyStyleData?.font_color
                                          : `#${companyStyleData?.font_color}`
                                    }}
                                    to={`/my-profile/${employeeIdData}`}
                                    className="p-1 rounded text-md text-left"
                                  >
                                    <i
                                      style={{
                                        color:
                                          companyStyleData?.font_color?.startsWith(
                                            "#"
                                          )
                                            ? companyStyleData?.font_color
                                            : `#${companyStyleData?.font_color}`
                                      }}
                                      className="fa fa-user mr-2 primary-text"
                                    />
                                    Profile
                                  </Link>
                                </div>
                              )}
                          </div>
                          <div className="flex items-center  p-2">
                            <Link
                              style={{
                                color: companyStyleData?.font_color?.startsWith(
                                  "#"
                                )
                                  ? companyStyleData?.font_color
                                  : `#${companyStyleData?.font_color}`
                              }}
                              onClick={(e) => handlerLogout(e)}
                              className=" p-1 px-3 rounded text-md text-left "
                            >
                              <i
                                style={{
                                  color:
                                    companyStyleData?.font_color?.startsWith(
                                      "#"
                                    )
                                      ? companyStyleData?.font_color
                                      : `#${companyStyleData?.font_color}`
                                }}
                                className="fa fa-sign-out mr-2 primary-text"
                              />
                              Logout
                            </Link>
                          </div>
                        </div>
                      </div>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </header>
      <div className="w-full bg-white lg:hidden py-1 ">
        <div className="container mx-auto     ">
          <div className="flex justify-between">
            <div className="flex items-center mr-4">
              <div className="flex justify-center items-center subHeader ">
                <Link to={"/dashboard"}>
                  {imageData ? (
                    <img src={imageData} alt={"Logo"} className="max-w-full" />
                  ) : (
                    <img
                      src="/images/logo.webp"
                      alt={"Logo"}
                      className="max-w-full"
                    />
                  )}
                </Link>
              </div>
              <div>
                <span className="font-semibold ml-2 capitalize text-[#ba4706] ">
                  {" "}
                  Hi, {employeeFirstName}
                </span>
                <p className="font-semibold ml-2 capitalize "> {companyName}</p>
              </div>
            </div>
            <div className="flex items-center">
              <div className="headerQuickAdd flex justify-end items-center">
                {roleIdData != REACT_APP_SUPER_ADMIN_ROLE_ID ? (
                  <>
                    <button className="bg-secondry font-bold border p-1 md:p-3 px-3 rounded text-white">
                      <span className="hidden md:inline-flex"> Quick Add</span>{" "}
                      <span className="fa fa-plus md:ml-2" />
                    </button>
                    {/* <p className="font-semibold ml-2 capitalize">Hi {employeeFirstName}</p> */}

                    <div className="quickAddBox">
                      <ul>
                        <li>
                          <Popup
                            open={open}
                            onClose={() => closeModal()}
                            closeOnDocumentClick={false}
                            trigger={
                              <button
                                id="claimExpense"
                                aria-describedby="popup-8"
                                onClick={() => setOpen((o) => !o)}
                              >
                                <span className="fa fa-credit-card mr-1" />{" "}
                                Claim Expenses
                              </button>
                            }
                            modal
                            nested
                          >
                            {(close) => (
                              <div className="modal">
                                <button
                                  className="close"
                                  id="claimExpenseClose"
                                  onClick={() => {
                                    closeModal();
                                    close();
                                  }}
                                >
                                  &times;
                                </button>
                                <AddExpense
                                  allExpenseType={allExpenseType}
                                  close={close}
                                  getAllMainExpenses={getAllMainExpenses}
                                  getAllMainExpensesCSVRecord={
                                    getAllMainExpensesCSVRecord
                                  }
                                />
                              </div>
                            )}
                          </Popup>
                        </li>
                        <li>
                          <Popup
                            open={open}
                            onClose={() => closeModal()}
                            closeOnDocumentClick={false}
                            trigger={
                              <button
                                id="claimMileage"
                                aria-describedby="popup-8"
                                onClick={() => setOpen((o) => !o)}
                              >
                                <span className="fa fa-tachometer mr-1" /> Claim
                                Mileage
                              </button>
                            }
                            modal
                            nested
                          >
                            {(close) => (
                              <div className="modal">
                                <button
                                  className="close"
                                  id="claimMileageClose"
                                  onClick={() => {
                                    closeModal();
                                    close();
                                  }}
                                >
                                  &times;
                                </button>
                                <AddMileageExpense
                                  allExpenseType={allExpenseType}
                                  getAllMainExpenses={getAllMainExpenses}
                                  close={close}
                                  getAllMainExpensesCSVRecord={
                                    getAllMainExpensesCSVRecord
                                  }
                                />
                              </div>
                            )}
                          </Popup>
                        </li>
                        <li>
                          <Popup
                            open={open}
                            onClose={() => closeModal()}
                            closeOnDocumentClick={false}
                            trigger={
                              <button
                                id="bookLeave"
                                aria-describedby="popup-8"
                                onClick={() => setOpen((o) => !o)}
                              >
                                <span className="fa fa-calendar mr-1" /> Book
                                Leave
                              </button>
                            }
                            modal
                            nested
                          >
                            {(close) => (
                              <div className="modal">
                                <button
                                  className="close"
                                  id="bookLeaveClose"
                                  onClick={() => {
                                    closeModal();
                                    close();
                                  }}
                                >
                                  &times;
                                </button>
                                <AddEmployeeLeave
                                  close={close}
                                  filterEmployeeById={filterEmployeeById}
                                  employeeId={id ? id : employeeIdData}
                                />
                              </div>
                            )}
                          </Popup>
                        </li>
                        <li>
                          <Popup
                            open={open}
                            onClose={() => closeModal()}
                            closeOnDocumentClick={false}
                            trigger={
                              <button
                                id="claimOvertime"
                                aria-describedby="popup-8"
                                onClick={() => setOpen((o) => !o)}
                              >
                                <span className="fa fa-clock mr-1" /> Claim
                                Overtime
                              </button>
                            }
                            modal
                            nested
                          >
                            {(close) => (
                              <div className="modal">
                                <button
                                  className="close"
                                  id="claimOvertimeClose"
                                  onClick={() => {
                                    closeModal();
                                    close();
                                  }}
                                >
                                  &times;
                                </button>
                                <AddMyOvertime
                                  close={close}
                                  myOvertimeList={myOvertimeList}
                                  allMasterOverTimeTypeResponse={
                                    allMasterOverTimeTypeResponse
                                  }
                                  isSuccessAllMasterOvertime={
                                    isSuccessAllMasterOvertime
                                  }
                                  getAllMaterOverTimeList={
                                    getAllMaterOverTimeList
                                  }
                                />
                              </div>
                            )}
                          </Popup>
                        </li>
                      </ul>
                    </div>
                  </>
                ) : (
                  ""
                )}
              </div>
              {/* <p className="font-semibold ml-2 capitalize "> Hi {employeeFirstName}</p> */}
            </div>
          </div>
        </div>
      </div>
      <div className="w-full bg-white lg:block hidden">
        <div className="container mx-auto">
          <div className="headerBottomNav">
            <ul className="flex">
              <div className="flex items-center mr-4">
                <div
                  className={` flex justify-center items-center subHeader ${
                    imageData ? "" : "hidden"
                  } `}
                >
                  <Link to={"/dashboard"}>
                    {imageData ? (
                      <img
                        src={imageData}
                        alt={"Logo"}
                        className="max-w-full"
                      />
                    ) : (
                      <>
                        {/* <img src="/images/logo.webp" alt={"Logo"} className="max-w-full" /> */}
                      </>
                    )}
                  </Link>
                </div>
                <div>
                  <span className="font-semibold ml-2 capitalize text-[#ba4706] ">
                    {" "}
                    Hi, {employeeFirstName}
                  </span>
                  <p className="font-semibold ml-2 capitalize ">
                    {" "}
                    {companyName}
                  </p>
                </div>
              </div>
              {roleIdData !== REACT_APP_EMPLOYEE_ROLE_ID &&
              roleIdData !== REACT_APP_SUPER_ADMIN_ROLE_ID &&
              roleIdData !== REACT_APP_AUDITOR_ROLE_ID ? (
                <>
                  {myLeaveReadPermissions[0]?.status === true && (
                    <li className="MyLeave">
                      <NavAnchor
                        LinkUrl="/my-leave"
                        navText="My Leave"
                        navIcon="fa fa-calendar"
                      />
                    </li>
                  )}
                  {myExpensesReadPermissions[0]?.status === true && (
                    <li className="MyExpenses">
                      <NavAnchor
                        LinkUrl="/expense"
                        navText="My Expenses"
                        navIcon="fa fa-credit-card"
                      />
                    </li>
                  )}
                  {myExpensesReadPermissions[0]?.status === true && (
                    <li className="MyOvertime">
                      <NavAnchor
                        LinkUrl="/overtime"
                        navText="My Overtime/Time in Lieu"
                        navIcon="fa fa-clock"
                      />
                    </li>
                  )}
                  {roleIdData !== 1 &&
                    roleIdData !== REACT_APP_AUDITOR_ROLE_ID && (
                      <li className="MyDocuments">
                        {/* <NavAnchor LinkUrl={`/employees/detail/${employeeIdData}`} state={"EmployeeDocument"} navText=<span className="text-green-500">My Documents</span>  navIcon="fa fa-file text-green-500" /> */}
                        <NavLink
                          to={`/my-profile/${employeeIdData}`}
                          state={{activeTab: "EmployeeDocument"}}
                        >
                          <span>
                            <i className="fa fa-file" /> My Documents
                          </span>
                        </NavLink>
                      </li>
                    )}
                  {roleIdData !== 1 &&
                    roleIdData !== REACT_APP_AUDITOR_ROLE_ID && (
                      <li className="MyDocuments">
                        {/* <NavAnchor LinkUrl={`/employees/detail/${employeeIdData}`} state={"EmployeeDocument"} navText=<span className="text-green-500">My Documents</span>  navIcon="fa fa-file text-green-500" /> */}
                        <NavLink
                          to={`/my-profile/${employeeIdData}`}
                          state={{activeTab: "myAbsence"}}
                        >
                          <span>
                            <i className="fa-solid fa-arrow-right-from-bracket" />{" "}
                            My Absence
                          </span>
                        </NavLink>
                      </li>
                    )}
                </>
              ) : (
                <></>
              )}
            </ul>
            {roleIdData != REACT_APP_SUPER_ADMIN_ROLE_ID &&
            roleIdData !== REACT_APP_AUDITOR_ROLE_ID ? (
              <div className="flex items-center">
                <div className="headerQuickAdd flex items-center ">
                  <button
                    className="bg-secondry font-bold border p-2 px-3 rounded text-white mr-1"
                    id="quickAdd"
                  >
                    Quick Add <span className="fa fa-plus ml-2" />
                  </button>
                  <div className="quickAddBox">
                    <ul>
                      <li>
                        <Popup
                          open={open}
                          onClose={() => closeModal()}
                          closeOnDocumentClick={false}
                          trigger={
                            <button
                              id="quickClaimExpenses"
                              aria-describedby="popup-8"
                              onClick={() => setOpen((o) => !o)}
                            >
                              <span className="fa fa-credit-card mr-1" /> Claim
                              Expenses
                            </button>
                          }
                          modal
                          nested
                        >
                          {(close) => (
                            <div className="modal">
                              <button
                                className="close"
                                id="quickClaimExpensesClose"
                                onClick={() => {
                                  closeModal();
                                  close();
                                }}
                              >
                                &times;
                              </button>
                              <AddExpense
                                allExpenseType={allExpenseType}
                                close={close}
                                getAllMainExpenses={getAllMainExpenses}
                                getAllMainExpensesCSVRecord={
                                  getAllMainExpensesCSVRecord
                                }
                              />
                            </div>
                          )}
                        </Popup>
                      </li>
                      <li>
                        <Popup
                          open={open}
                          onClose={() => closeModal()}
                          closeOnDocumentClick={false}
                          trigger={
                            <button
                              id="quickClaimMileage"
                              aria-describedby="popup-8"
                              onClick={() => setOpen((o) => !o)}
                            >
                              <span className="fa fa-tachometer mr-1" /> Claim
                              Mileage
                            </button>
                          }
                          modal
                          nested
                        >
                          {(close) => (
                            <div className="modal">
                              <button
                                className="close"
                                id="quickClaimMileageClose"
                                onClick={() => {
                                  closeModal();
                                  close();
                                }}
                              >
                                &times;
                              </button>
                              <AddMileageExpense
                                allExpenseType={allMileageType}
                                getAllMainExpenses={getAllMainExpenses}
                                close={close}
                                getAllMainExpensesCSVRecord={
                                  getAllMainExpensesCSVRecord
                                }
                              />
                            </div>
                          )}
                        </Popup>
                      </li>
                      <li>
                        <Popup
                          open={open}
                          onClose={() => closeModal()}
                          closeOnDocumentClick={false}
                          trigger={
                            <button
                              id="quickBookLeave"
                              aria-describedby="popup-8"
                              onClick={() => setOpen((o) => !o)}
                            >
                              <span className="fa fa-calendar mr-1" /> Request
                              Leave
                            </button>
                          }
                          modal
                          nested
                        >
                          {(close) => (
                            <div className="modal">
                              <button
                                className="close"
                                id="quickBookLeaveClose"
                                onClick={() => {
                                  closeModal();
                                  close();
                                }}
                              >
                                &times;
                              </button>
                              <AddEmployeeLeave
                                close={close}
                                filterEmployeeById={filterEmployeeById}
                                employeeId={id ? id : employeeIdData}
                                getAllChartDashborad={getAllChartDashborad}
                              />
                            </div>
                          )}
                        </Popup>
                      </li>
                      <li>
                        <Popup
                          open={open}
                          onClose={() => closeModal()}
                          closeOnDocumentClick={false}
                          trigger={
                            <button
                              id="quickClaimOvertime"
                              aria-describedby="popup-8"
                              onClick={() => setOpen((o) => !o)}
                            >
                              <span className="fa fa-clock mr-1" /> Claim
                              Overtime
                            </button>
                          }
                          modal
                          nested
                        >
                          {(close) => (
                            <div className="modal">
                              <button
                                id="quickClaimOvertimeClose"
                                className="close"
                                onClick={() => {
                                  closeModal();
                                  close();
                                }}
                              >
                                &times;
                              </button>
                              <AddMyOvertime
                                close={close}
                                myOvertimeList={myOvertimeList}
                                allMasterOverTimeTypeResponse={
                                  allMasterOverTimeTypeResponse
                                }
                                isSuccessAllMasterOvertime={
                                  isSuccessAllMasterOvertime
                                }
                                getAllMaterOverTimeList={
                                  getAllMaterOverTimeList
                                }
                              />
                            </div>
                          )}
                        </Popup>
                      </li>
                      {roleIdData !== 4 && (
                        <li>
                          <Popup
                            open={open}
                            onClose={() => closeModal()}
                            closeOnDocumentClick={false}
                            trigger={
                              <button
                                id="quickBookLeave"
                                aria-describedby="popup-8"
                                onClick={() => setOpen((o) => !o)}
                              >
                                <span className="fa fa-plus mr-2" />
                                Add Absence
                              </button>
                            }
                            modal
                            nested
                          >
                            {(close) => (
                              <div className="modal">
                                <button
                                  className="close"
                                  id="addAbsence"
                                  onClick={() => {
                                    closeModal();
                                    close();
                                  }}
                                >
                                  &times;
                                </button>
                                <AddAbsence
                                  allDepartmentListData={allDepartmentListData}
                                  allEmployeeData={allEmployeeData}
                                  close={close}
                                  getAllLeaveAbsence={getAllLeaveAbsence}
                                />
                              </div>
                            )}
                          </Popup>
                        </li>
                      )}
                    </ul>
                  </div>
                </div>
                {/* <p className="font-semibold ml-2 capitalize "> Hi {employeeFirstName}</p> */}
              </div>
            ) : (
              ""
            )}
          </div>
        </div>
      </div>
    </>
  );
}
