/* eslint-disable camelcase */
import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import { ApiUrl } from '../constant';

export const leaveAbsenceApi = createApi({
    reducerPath: "leaveAbsenceAPI",
    baseQuery: fetchBaseQuery({ 
      baseUrl: ApiUrl
    }),
endpoints: (builder) => ({
 
 
getAllLeaveAbsence:builder.mutation({
      query: (payload) =>  {
       return {
        url: payload,
        method: 'GET',
        headers: {
          'Content-type': 'application/json; charset=UTF-8',
          Authorization: localStorage.getItem('accessToken')
        }
      };
    }
    }),
    getLeaveAbsenceAllRecord:builder.mutation({
      query: (payload) =>  {
       return {
        url: payload,
        method: 'GET',
        headers: {
          'Content-type': 'application/json; charset=UTF-8',
          Authorization: localStorage.getItem('accessToken')
        }
      };
    }
    }),
  getLeaveAbsenceFilterList:builder.mutation({
      query: (payload) => {
       
        const {department_id, type} = payload;
        return{
        url: `/leaves?department_id=${department_id}&type=${type}`,
        method: 'GET',
        headers: {
          'Content-type': 'application/json; charset=UTF-8',
          Authorization: localStorage.getItem('accessToken')
        }
      };
    }
    
  }),
  filterEmployee:builder.mutation({
    query: (payload) => {
     
      return{
      url: `/leaves?first_name=${payload.name}&type=${2}&page=${payload.page}`,
      method: 'GET',
      headers: {
        'Content-type': 'application/json; charset=UTF-8',
        Authorization: localStorage.getItem('accessToken')
      }
    };
  }
  
}),

createLeaveAbsence: builder.mutation({
      query: (payload) => ({
        url: '/leaves',
        method: 'POST',
        body: payload,
        headers: {
          'Content-type': 'application/json; charset=UTF-8',
          Authorization:  localStorage.getItem('accessToken')
        }
      }),
      invalidatesTags: [ 'Post' ]
  }),
  createCompanyLeaveAbsence: builder.mutation({
    query: (payload) => ({
      url: '/leaves/apply/company-wide',
      method: 'POST',
      body: payload,
      headers: {
        'Content-type': 'application/json; charset=UTF-8',
        Authorization:  localStorage.getItem('accessToken')
      }
    }),
    invalidatesTags: [ 'Post' ]
}),
    durationAbsence: builder.mutation({
      query: (payload) => ({
        url: '/leaves/duration',
        method: 'POST',
        body: payload,
        headers: {
          'Content-type': 'application/json; charset=UTF-8',
          Authorization:  localStorage.getItem('accessToken')
        }
      }),
      invalidatesTags: [ 'Post' ]
  }),
  restrictedDayInfoAbsence: builder.mutation({
    query: (payload) => ({
      url: '/leaves/validate/restricted/days',
      method: 'POST',
      body: payload,
      headers: {
        'Content-type': 'application/json; charset=UTF-8',
        Authorization:  localStorage.getItem('accessToken')
      }
    }),
    invalidatesTags: [ 'Post' ]
}),
leaveByDateRange: builder.mutation({
  query: (payload) => ({
    url: '/leaves/details/by/date/range',
    method: 'POST',
    body: payload,
    headers: {
      'Content-type': 'application/json; charset=UTF-8',
      Authorization:  localStorage.getItem('accessToken')
    }
  }),
  invalidatesTags: [ 'Post' ]
}),
companyScheduleByDateRange: builder.mutation({
  query: (payload) => ({
    url: '/company/leaves/date-range',
    method: 'POST',
    body: payload,
    headers: {
      'Content-type': 'application/json; charset=UTF-8',
      Authorization:  localStorage.getItem('accessToken')
    }
  }),
  invalidatesTags: [ 'Post' ]
}),
  updateLeaveAbsence: builder.mutation({
    query: (payload) => {
      return {
        url: `/leaves/${payload?.id}`,
        method: 'PUT',
        body: payload,
        headers: {
          'Content-type': 'application/json; charset=UTF-8',
          Authorization:  localStorage.getItem('accessToken')
        }
      };
    }
    
  }),
  
  deleteLeaveAbsence: builder.mutation({
    query: (id) => ({
      url: `/leaves/${id}`,
      method: 'DELETE',
      headers: {
        'Content-type': 'application/json; charset=UTF-8',
        Authorization:  localStorage.getItem('accessToken')
      }
    }),
    invalidatesTags: [ 'Post' ]
  }),
  leaveAbsenceList: builder.mutation({
    query: (id) => ({
      url: `leaves/absenceList?employee_id=${id}`,
      method: "GET",
     
      headers: {
       "Content-type": "application/json; charset=UTF-8",
        Authorization: localStorage.getItem("accessToken")
      }
    })
  })

})
});

export const { useFilterEmployeeMutation, 
  useGetLeaveAbsenceFilterListMutation, 
  useGetAllLeaveAbsenceMutation, 
  useCreateLeaveAbsenceMutation, 
  useUpdateLeaveAbsenceMutation, 
  useDeleteLeaveAbsenceMutation,
  useGetLeaveAbsenceAllRecordMutation,
  useDurationAbsenceMutation,
  useRestrictedDayInfoAbsenceMutation, 
  useLeaveByDateRangeMutation,
  useLeaveAbsenceListMutation,
  useCompanyScheduleByDateRangeMutation,
  useCreateCompanyLeaveAbsenceMutation
 } = leaveAbsenceApi;