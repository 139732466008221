import {useState, useEffect} from "react";
import {useSelector} from "react-redux";
import {useLocation} from "react-router-dom";
import ChatBot from "./chatbot";

const ShowChat = () => {
  const location = useLocation();
  const dashboardAllData = useSelector(
    (state) => state?.persistedReducer?.DashboardSlice?.dashboardData
  );
  const [isChat, setIsChat] = useState(false);

  useEffect(() => {
    // Get query parameters from URL
    const searchParams = new URLSearchParams(location.search);
    // Check if 'ischat' parameter is 'true'
    const isChatParam = searchParams.get("ischat");
    setIsChat(isChatParam === "true"); // Set state based on the parameter value
  }, [location]);
  return (
    <div>
      {isChat && (
        <ChatBot
          thisWeek={dashboardAllData?.data?.EmployeeBirthdayThisWeek}
          leaveThisWeek={dashboardAllData?.data?.EmployeeLeaveThisWeek}
          anniversariesThisWeek={
            dashboardAllData?.data?.EmployeeAnniversaryThisWeek
          }
          absenceThisWeek={dashboardAllData?.data?.EmployeeAbsenceThisWeek}
          TotalOustandingLeaves={dashboardAllData?.data?.TotalOustandingLeaves}
          TotalExpenseToPay={dashboardAllData?.data?.TotalExpenseToPay}
          TotalOustandingExpenses={
            dashboardAllData?.data?.TotalOustandingExpenses
          }
          TotalOustandingOvertimes={
            dashboardAllData?.data?.TotalOustandingOvertimes
          }
        />
      )}
    </div>
  );
};

export default ShowChat;
