/* eslint-disable camelcase */
import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  holiday: []
};

export const holidaySlice = createSlice({
  name: "holidaySlice",
  initialState,
  reducers: {
    holidayInitialAction: () => initialState,
    addHoliday: {  
      reducer: (state, action)  => {
       state.holiday.data.push(action.payload.holiday);
      }
    },
     holidayInfo: (state, action) => {
      state.holiday = action.payload;
    },
    updatedHoliday: (state, action) => {
      // const {id, name, start_date, end_date, days, description, is_restricted} = action.payload.updatedHoliday;
      const {id} = action.payload.updatedHoliday;
      const index = state.holiday.data.findIndex((obj) => obj.id === id);
      state.holiday.data[index] = action.payload.updatedHoliday;
    },
    deletedHoliday: (state, action) => {
     
      
        const data = state.holiday.data.filter((holiday) => {
         return holiday.id !== action.payload;
        }
          );
        state.holiday.data = data;
        
       

      
    }
  }
});

export const {holidayInitialAction, addHoliday, holidayInfo, updatedHoliday, deletedHoliday } = holidaySlice.actions;
export default holidaySlice.reducer;