import { createSlice } from "@reduxjs/toolkit";


const initialState = {
    allExpenseDataList: [],
    allMileageExpensesData: [],
    allMainMileageExpensesData: [],
    allMainExpenseData: []
};

export const expenseSlice = createSlice({
  name: "expenseSlice",
  initialState,
  reducers: {
    expenseInitialAction: () => initialState,
    allExpenseDataAction: (state, action) => {
      state.allExpenseDataList = action.payload;
      },
      allMileageExpensesDataAction: (state, action) => {
        state.allMileageExpensesData = action.payload;
        },
        allMainMileageExpensesDataAction: (state, action) => {
          state.allMainMileageExpensesData = action.payload;
          },
        allMainExpenseDataAction : (state, action) => {
          state.allMainExpenseData = action.payload;
        }

  }

});

export const {expenseInitialAction, allExpenseDataAction, allMileageExpensesDataAction, allMainMileageExpensesDataAction, allMainExpenseDataAction } = expenseSlice.actions;
// ? Export the authSlice.reducer to be included in the store.
export default expenseSlice.reducer;