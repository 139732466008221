const ToggleSwitch = ({toggleSwitch, enabled}) => {
  return (
    <div className="flex items-center">
      <span className="mr-3 text-sm font-medium">{enabled ? "On" : "Off"}</span>
      <div
        onClick={toggleSwitch}
        className={`relative inline-flex h-6 w-11 items-center cursor-pointer transition-colors duration-300 ease-in-out ${
          enabled ? "bg-green-500" : "bg-gray-300"
        } rounded-full`}
      >
        <span
          className={`inline-block w-4 h-4 transform transition-transform duration-300 ease-in-out bg-white rounded-full ${
            enabled ? "translate-x-6" : "translate-x-1"
          }`}
        />
      </div>
    </div>
  );
};

export default ToggleSwitch;
