import {useState} from "react";
import {Helmet, HelmetProvider} from "react-helmet-async";
import Popup from "reactjs-popup";
import {Link} from "react-router-dom";
import {useEffect} from "react";
import {useDispatch, useSelector} from "react-redux";
//import { EmployeeThisWeek } from "../../dashboard/Birthday/employeeThisWeek";
import moment from "moment";
import {UpcomingLeaveData} from "./UpcomingLeaveData";
import {EmployeeAnniversariesThisWeek} from "../../dashboard/employeeWorkAnniversary";
import {EmployeeBirthdayThisWeek} from "../../dashboard/empBirthdayThisWeek";
import {empDashboardDataAction} from "../../../redux/DashboardSlice/DashboardSlice";
import {
  useGetAllChartDashboradMutation,
  useGetEmployeeDashboradMutation
} from "../../../services/dashboardAp";
import Trends from "../Trends";

const EmpDashboard = () => {
  const dispatch = useDispatch();
  const cuurentYear = new Date().getFullYear();
  // useSelector
  const dashboardAllData = useSelector(
    (state) => state?.persistedReducer?.DashboardSlice?.empDashboardData
  );
  // eslint-disable-next-line
  const userId = useSelector(
    (state) => state?.persistedReducer?.userPermissionsStates?.user?.employee_id
  );
  const auditorRoleId = useSelector(
    (state) => state?.persistedReducer?.userPermissionsStates?.user?.roleId
  );
  const auditorCompanyId = useSelector(
    (state) => state?.persistedReducer?.auditorsSlice?.auditorSelectedCompany
  );
  const companyId = useSelector(
    (state) => state?.persistedReducer?.userPermissionsStates?.user?.company_id
  );
  // Mutation
  const [
    getAllChartDashborad,
    {data: dashboardChartApiRespo, isSuccess: chartSuccess}
  ] = useGetAllChartDashboradMutation();
  const [getEmployeeDashborad, {data: dashboardGetData, isSuccess}] =
    useGetEmployeeDashboradMutation();
  const [open, setOpen] = useState(false);
  const [expense, setExpense] = useState();
  const [mileage, setMileage] = useState();
  const [leave, setLeave] = useState();
  const [overtime, setOvertime] = useState();
  const [absence, setAbsence] = useState();
  const [toggleState4, setToggleState4] = useState(1);

  useEffect(
    () => {
      if (chartSuccess) {
        let chartData = dashboardChartApiRespo?.data;
        if (chartData?.type === "1") {
          setExpense(chartData);
        } else if (chartData?.type === "2") {
          setMileage(chartData);
        } else if (chartData?.type === "3") {
          setLeave(chartData);
        } else if (chartData?.type === "4") {
          setOvertime(chartData);
        } else if (chartData?.type === "5") {
          setAbsence(chartData);
        }
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [chartSuccess]
  );
  const closeModal = () => {
    setOpen(false);
  };
  const [toggleState, setToggleState] = useState(1);
  const toggleTab = (index) => {
    setToggleState(index);
  };

  const [toggleState2, setToggleState2] = useState(1);
  const toggleTab2 = (index) => {
    setToggleState2(index);
  };
  useEffect(() => {
    if (userId) {
      let payload = `/reports/get-chart-data?company_id=${
        auditorRoleId === 5 ? auditorCompanyId : companyId
      }&employee_id=${userId}&type=${1}&year=${cuurentYear}`;
      getAllChartDashborad(payload);
    }
  }, [userId]);
  const toggleTab4 = (index) => {
    setToggleState4(index);
    let payload = `/reports/get-chart-data?company_id=${
      auditorRoleId === 5 ? auditorCompanyId : companyId
    }&employee_id=${userId}&type=${index}&year=${cuurentYear}`;
    getAllChartDashborad(payload);
  };

  const token = localStorage?.getItem("accessToken");

  useEffect(
    () => {
      if (isSuccess) {
        dispatch(empDashboardDataAction(dashboardGetData));
      }
    },

    // eslint-disable-next-line react-hooks/exhaustive-deps
    [isSuccess]
  );
  useEffect(
    () => {
      getEmployeeDashborad({headers: {Authorization: `Bearer ${token}`}});
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [getEmployeeDashborad, token]
  );

  return (
    <div className="">
      <HelmetProvider>
        <Helmet>
          <title>Schedulize | Dashboard</title>
        </Helmet>
      </HelmetProvider>

      <div>
        <div className="py-6 dashboardPage empDashboard ">
          <div className="container mx-auto ">
            <div className="flex justify-between flex-wrap  items-center">
              <h2 className="text-xl mb-3 font-semibold  ">Dashboard</h2>
            </div>
            <div className="bg-white md:p-6 p-4 pb-0 rounded-md shadow-2xl">
              <div className="flex flex-wrap ">
                <div className=" w-full">
                  <div className="flex flex-wrap">
                    <div className="lg:w-2/5 w-full mb-4 md:mb-6 lg:pr-2">
                      <div
                        className="relative flex flex-col overflow-hidden h-auto md:h-36 lg:h-auto rounded-lg bg-gradient-to-br from-amber-600 to-orange-700 p-3.5 py-3 md:py-2 lg:py-3 shadow-xl"
                        style={{minHeight: "220px"}}
                      >
                        <p className="text-xs uppercase text-amber-50">
                          Company message
                        </p>
                        <div className=" ">
                          <div className="flex justify-between">
                            <p className="mt-3 md:mt-1 lg:mt-3 text-md font-medium text-white">
                              {" "}
                              From:{" "}
                              <span className="font-medium">
                                {
                                  dashboardAllData?.data?.CompanyMessage[0]
                                    ?.senderInfo?.first_name
                                }
                              </span>
                            </p>
                            <p className="mt-3 md:mt-1 lg:mt-3 text-md font-medium text-white">
                              {" "}
                              <span className="font-medium">
                                {moment(
                                  dashboardAllData?.data?.CompanyMessage[0]
                                    ?.createdAt
                                ).format("DD-MM-YYYY")}
                              </span>
                            </p>
                          </div>
                          <p
                            className="mt-0 text-white"
                            style={{fontSize: "12px", minHeight: "120px"}}
                          >
                            {dashboardAllData?.data?.CompanyMessage[0]?.message}{" "}
                            <Popup
                              open={open}
                              onClose={() => closeModal()}
                              closeOnDocumentClick={false}
                              trigger={
                                <span
                                  onClick={() => setOpen((o) => !o)}
                                  className=" font-bold cursor-pointer block"
                                >
                                  read more
                                </span>
                              }
                              modal
                              nested
                            >
                              {(close) => (
                                <div className="modal">
                                  <button
                                    className="close"
                                    id="closeMessageDetail"
                                    onClick={() => {
                                      closeModal();
                                      close();
                                    }}
                                  >
                                    &times;
                                  </button>
                                  <div className="header">
                                    <h3> Message Detail</h3>
                                  </div>
                                  <div className="OpenMeassage ">
                                    <h3 className="text-[14px]">
                                      From:{" "}
                                      <span className="font-medium">
                                        {
                                          dashboardAllData?.data
                                            ?.CompanyMessage[0]?.senderInfo
                                            ?.first_name
                                        }
                                      </span>
                                    </h3>
                                    <div className="form-group mt-3">
                                      <label className="formBlock">
                                        Message:
                                      </label>
                                      <div className="   ">
                                        <p>
                                          {
                                            dashboardAllData?.data
                                              ?.CompanyMessage[0]?.message
                                          }
                                        </p>
                                        {/* <p>
                                      Ut ut justo odio. Praesent aliquam urna
                                      sed dignissim suscipit.
                                    </p> */}
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              )}
                            </Popup>
                          </p>
                          <div className="flex justify-end mt-1 relative z-10">
                            <Popup
                              open={open}
                              onClose={() => closeModal()}
                              closeOnDocumentClick={false}
                              trigger={
                                <Link
                                  onClick={() => setOpen((o) => !o)}
                                  to="#"
                                  className="table mr-3 border-b border-dotted border-current pb-0.5 text-xs mt-1 font-medium text-amber-50 outline-none transition-colors"
                                >
                                  View All
                                </Link>
                              }
                              modal
                              nested
                            >
                              {(close) => (
                                <div className="modal">
                                  <button
                                    id="closeviewAll"
                                    className="close"
                                    onClick={() => {
                                      closeModal();
                                      close();
                                    }}
                                  >
                                    &times;
                                  </button>
                                  <div className="header">
                                    <h3> Message</h3>
                                  </div>
                                  <div className="content">
                                    <div className="w-full">
                                      {dashboardAllData?.data?.CompanyMessage
                                        ?.length > 0 &&
                                        dashboardAllData?.data?.CompanyMessage?.map(
                                          (message, index) => {
                                            return (
                                              <div
                                                className="card p-4 mb-4 border"
                                                key={index}
                                              >
                                                <div className="w-full mb-3">
                                                  <h3 className="text-[14px] mb-1">
                                                    Message
                                                  </h3>
                                                  <Popup
                                                    open={open}
                                                    onClose={() => closeModal()}
                                                    closeOnDocumentClick={false}
                                                    trigger={
                                                      <div
                                                        onClick={() =>
                                                          setOpen((o) => !o)
                                                        }
                                                      >
                                                        <p>
                                                          {" "}
                                                          {typeof message?.message ===
                                                            "string" &&
                                                            message?.message
                                                              .split(" ")
                                                              .slice(0, 35)
                                                              .join(" ")}
                                                          {typeof message?.message ===
                                                            "string" &&
                                                          message?.message.split(
                                                            " "
                                                          ).length > 35 ? (
                                                            <>
                                                              <span className="primary-text cursor-pointer">
                                                                ... read more
                                                              </span>
                                                            </>
                                                          ) : (
                                                            ""
                                                          )}
                                                        </p>
                                                      </div>
                                                    }
                                                    modal
                                                    nested
                                                  >
                                                    {(close) => (
                                                      <div className="modal">
                                                        <button
                                                          className="close"
                                                          id="closeMessageView"
                                                          onClick={() => {
                                                            closeModal();
                                                            close();
                                                          }}
                                                        >
                                                          &times;
                                                        </button>
                                                        <div className="header">
                                                          <h3> Message</h3>
                                                        </div>
                                                        <div className="OpenMeassage p-4">
                                                          <h3 className="text-[14px]">
                                                            From:{" "}
                                                            <span className="font-medium">
                                                              {
                                                                message
                                                                  ?.senderInfo
                                                                  ?.first_name
                                                              }
                                                            </span>
                                                          </h3>
                                                          <div className="form-group mt-3">
                                                            <label className="formBlock">
                                                              Message:
                                                            </label>
                                                            <div className="   ">
                                                              <p>
                                                                {
                                                                  message?.message
                                                                }
                                                              </p>
                                                            </div>
                                                          </div>
                                                        </div>
                                                      </div>
                                                    )}
                                                  </Popup>
                                                </div>
                                                <div className="w-full mb-1">
                                                  <h3 className="text-[14px] flex gap-1">
                                                    Date:{" "}
                                                    <p
                                                      className="font-medium"
                                                      style={{color: "#121212"}}
                                                    >
                                                      {moment(
                                                        message?.createdAt
                                                      ).format("DD-MM-YYYY")}
                                                    </p>
                                                  </h3>
                                                </div>
                                                <div className="w-full ">
                                                  <h3 className="text-[14px] flex gap-1">
                                                    Added by:
                                                    <p
                                                      className="font-medium"
                                                      style={{color: "#121212"}}
                                                    >
                                                      {
                                                        message?.senderInfo
                                                          ?.first_name
                                                      }
                                                    </p>
                                                  </h3>
                                                </div>
                                              </div>
                                            );
                                          }
                                        )}
                                    </div>
                                    {/* <div className="overflow-x-auto w-full whitespace-nowrap">
                                  <table className="table-auto table  w-full">
                                    <thead className="thead-dark ">
                                      <tr>
                                        <th> Date</th>
                                        <th>Message</th>
                                        <th>Added by</th>
                                      </tr>
                                    </thead>
                                    <tbody>
                                      {dashboardAllData?.data?.CompanyMessage?.map((message, index) => {
                                        return (

                                          <tr key={index}>
                                            <td>{moment(message?.createdAt).format('DD-MM-YYYY')}</td>
                                            <td className="tdMaxWidth">
                                              <Popup
                                                open={open}
                                                onClose={() => closeModal()}
                                                closeOnDocumentClick={false}
                                                trigger={
                                                  <div
                                                    onClick={() =>
                                                      setOpen((o) => !o)
                                                    }
                                                  >
                                                    {message?.message}
                                                  </div>
                                                }
                                                modal
                                                nested
                                              >
                                                {(close) => (
                                                  <div className="modal">
                                                    <button
                                                      className="close"
                                                      id="closeMessageView"
                                                      onClick={() => {
                                                        closeModal();
                                                        close();
                                                      }}
                                                    >
                                                      &times;
                                                    </button>
                                                    <div className="header">
                                                      <h3> Message</h3>
                                                    </div>
                                                    <div className="OpenMeassage p-4">
                                                      <h3 className="text-[14px]">
                                                        From:{" "}
                                                        <span className="font-medium">
                                                        {message?.senderInfo?.first_name}
                                                        </span>
                                                      </h3>
                                                      <div className="form-group mt-3">
                                                        <label className="formBlock">
                                                          Message:
                                                        </label>
                                                        <div className="   ">
                                                          <p>
                                                            {message?.message}
                                                          </p>
                                                        </div>
                                                      </div>
                                                    </div>
                                                  </div>
                                                )}
                                              </Popup>
                                            </td>
                                            <td>{message?.senderInfo?.first_name}</td>
                                          </tr>
                                        );
                                      })}
                                    </tbody>
                                  </table>
                                </div> */}
                                  </div>
                                </div>
                              )}
                            </Popup>
                          </div>
                        </div>
                        <div className="mask is-diamond absolute top-0 right-0 -m-3 h-16 w-16 rounded-md  bg-white/20" />
                      </div>
                    </div>
                    <div className="lg:w-1/5 w-full mb-4 md:mb-6 lg:pr-2">
                      <div className="card w-full infoList ">
                        <div className="heading_title lg:px-3 px-3 flex justify-between items-center py-2">
                          <div>
                            <h3 className=" font-semibold text-lg">
                              My Outstanding Leave Requests
                            </h3>
                          </div>
                        </div>
                        <div className="p-2">
                          <div className="flex p-2 w-full items-center justify-center ">
                            <Link
                              to="/my-leave"
                              className="text-3xl  w-24 h-24 flex justify-center items-center rounded-full bg-yellow-light2 secondry-text"
                            >
                              {
                                dashboardAllData?.data
                                  ?.myOutstandingLeaveRequestCount
                              }
                            </Link>
                          </div>
                        </div>
                      </div>
                    </div>

                    <div className="lg:w-1/5 w-full mb-4 md:mb-6 lg:pr-2">
                      <div className="card w-full infoList ">
                        <div className="heading_title lg:px-3 px-3 flex justify-between items-center py-2">
                          <div>
                            <h3 className=" font-semibold text-lg">
                              My Outstanding Expense Requests
                            </h3>
                          </div>
                        </div>
                        <div className="p-2">
                          <div className="overflow-x-auto w-full whitespace-nowrap">
                            <div className="flex p-2 w-full items-center justify-center ">
                              <Link
                                to="/expense"
                                className="text-3xl  w-24 h-24 flex justify-center items-center rounded-full bg-yellow-light2 secondry-text"
                              >
                                {
                                  dashboardAllData?.data
                                    ?.myOutstandingExpenseRequestCount
                                }
                              </Link>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="lg:w-1/5 w-full mb-4 md:mb-6 ">
                      <div className="card w-full infoList ">
                        <div className="heading_title lg:px-3 px-3 flex justify-between items-center py-2">
                          <div>
                            <h3 className=" font-semibold text-lg">
                              My Outstanding Overtime Requests
                            </h3>
                          </div>
                        </div>
                        <div className="p-2">
                          <div className="overflow-x-auto w-full whitespace-nowrap">
                            <div className="flex p-2 w-full items-center justify-center ">
                              <Link
                                to="/overtime"
                                className="text-3xl  w-24 h-24 flex justify-center items-center rounded-full bg-yellow-light2 secondry-text"
                              >
                                {
                                  dashboardAllData?.data
                                    ?.myOutstandingOvertimeRequestCount
                                }
                              </Link>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>

                    <div className="lg:w-1/3 w-full mb-4 md:mb-6 flex lg:pr-6">
                      <div className="card w-full infoList ">
                        <div className="heading_title lg:px-3 px-3 flex justify-between items-center py-2">
                          <div>
                            <h3 className=" font-semibold text-lg">
                              Employee Birthdays
                            </h3>
                          </div>
                        </div>
                        <div className="p-2">
                          <div className="cardtabBtn ">
                            <button
                              id="birthdayThisWeek"
                              onClick={() => toggleTab(1)}
                              className={
                                toggleState === 1
                                  ? "btn-sm  mr-2 active"
                                  : "btn-sm  mr-2"
                              }
                            >
                              This week
                            </button>

                            <button
                              onClick={() => toggleTab(2)}
                              id="birthdayNextWeek"
                              className={
                                toggleState === 2
                                  ? "btn-sm  active"
                                  : "btn-sm  "
                              }
                            >
                              Next week
                            </button>
                          </div>
                          <div className="tabCard">
                            <div
                              className={
                                toggleState === 1
                                  ? "tabContent tabContentActive"
                                  : "tabContent"
                              }
                            >
                              <div className="overflow-x-auto w-full whitespace-nowrap">
                                <EmployeeBirthdayThisWeek
                                  thisWeekBirthday={
                                    dashboardAllData?.data
                                      ?.EmployeeBirthdayThisWeek
                                  }
                                  tableNames={["Name", "Birthday Date"]}
                                />
                              </div>
                            </div>
                            <div
                              className={
                                toggleState === 2
                                  ? "tabContent tabContentActive"
                                  : "tabContent"
                              }
                            >
                              <div className="overflow-x-auto w-full whitespace-nowrap">
                                <EmployeeBirthdayThisWeek
                                  thisWeekBirthday={
                                    dashboardAllData?.data
                                      ?.EmployeeBirthdayNextWeek
                                  }
                                  tableNames={["Name", "Birthday Date"]}
                                />
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="lg:w-1/3 w-full mb-4 md:mb-6 flex lg:pr-6">
                      <div className="card w-full infoList ">
                        <div className="heading_title lg:px-3 px-3 flex justify-between items-center py-2">
                          <div>
                            <h3 className=" font-semibold text-lg">
                              Work Anniversaries
                            </h3>
                          </div>
                        </div>
                        <div className="p-2">
                          <div className="cardtabBtn ">
                            <button
                              id="anniversaryThisWeek"
                              onClick={() => toggleTab2(1)}
                              className={
                                toggleState2 === 1
                                  ? "btn-sm  mr-2 active"
                                  : "btn-sm  mr-2"
                              }
                            >
                              This week
                            </button>

                            <button
                              onClick={() => toggleTab2(2)}
                              id="anniversaryNextWeek"
                              className={
                                toggleState2 === 2
                                  ? "btn-sm  active"
                                  : "btn-sm  "
                              }
                            >
                              Next week
                            </button>
                          </div>
                          <div className="tabCard">
                            <div
                              className={
                                toggleState2 === 1
                                  ? "tabContent tabContentActive"
                                  : "tabContent"
                              }
                            >
                              <div className="overflow-x-auto w-full whitespace-nowrap">
                                <EmployeeAnniversariesThisWeek
                                  thisWeekAnniversaries={
                                    dashboardAllData?.data
                                      ?.EmployeeAnniversaryThisWeek
                                  }
                                  tableNames={["Name", "Day", "Year(s)"]}
                                />
                              </div>
                            </div>
                            <div
                              className={
                                toggleState2 === 2
                                  ? "tabContent tabContentActive"
                                  : "tabContent"
                              }
                            >
                              <div className="overflow-x-auto w-full whitespace-nowrap">
                                <EmployeeAnniversariesThisWeek
                                  thisWeekAnniversaries={
                                    dashboardAllData?.data
                                      ?.EmployeeAnniversaryNextWeek
                                  }
                                  tableNames={["Name", "Day", "Year(s)"]}
                                />
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="lg:w-1/3 w-full mb-4 md:mb-6 flex ">
                      <div className="card w-full infoList ">
                        <div className="heading_title lg:px-3 px-3 flex justify-between items-center py-2">
                          <div>
                            <h3 className=" font-semibold text-lg">
                              My Upcoming Leave
                            </h3>
                          </div>
                        </div>
                        <div className="p-2">
                          <div className="overflow-x-auto w-full whitespace-nowrap">
                            <UpcomingLeaveData
                              UpcomingLeaveData={
                                dashboardAllData?.data?.MyUpComingLeaves
                              }
                              tableNames={["Date", "Duration", "Status"]}
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                    <Trends
                    toggleTab4={toggleTab4}
                      toggleState4={toggleState4}
                      expense={expense}
                      mileage={mileage}
                      leave={leave}
                      overtime={overtime}
                      absence={absence}
                    />
                    {/* <div className=" w-full  ">
                      <div className="card w-full infoList ChartDiv">
                        <div className="heading_title lg:px-3 px-3 flex justify-between items-center py-2">
                          <div>
                            <h3 className=" font-semibold text-lg">Trends</h3>
                          </div>
                        </div>
                        <div className="p-2">
                          <div className="cardtabBtn  flex-wrap">
                            <button
                              onClick={() => toggleTab4(1)}
                              id="puchase"
                              className={
                                toggleState4 === 1
                                  ? "btn-sm  mr-2 active mb-1 sm:mb-0"
                                  : "btn-sm  mr-2 mb-1 sm:mb-0"
                              }
                            >
                              Expenses - Purchase
                            </button>

                            <button
                              onClick={() => toggleTab4(2)}
                              id="mileage"
                              className={
                                toggleState4 === 2
                                  ? "btn-sm mr-2 active mb-1 sm:mb-0"
                                  : "btn-sm mr-2 mb-1 sm:mb-0  "
                              }
                            >
                              Expenses - Mileage
                            </button>
                            <button
                              onClick={() => toggleTab4(3)}
                              id="leave"
                              className={
                                toggleState4 === 3
                                  ? "btn-sm mr-2 active mb-1 sm:mb-0"
                                  : "btn-sm mr-2 mb-1 sm:mb-0 "
                              }
                            >
                              Leave
                            </button>
                            <button
                              onClick={() => toggleTab4(4)}
                              id="overtime"
                              className={
                                toggleState4 === 4
                                  ? "btn-sm mr-2 active mb-1 sm:mb-0"
                                  : "btn-sm mr-2 mb-1 sm:mb-0"
                              }
                            >
                              Overtime
                            </button>
                            <button
                              onClick={() => toggleTab4(5)}
                              id="absence"
                              className={
                                toggleState4 === 5
                                  ? "btn-sm active mb-1 sm:mb-0"
                                  : "btn-sm mb-1 sm:mb-0 "
                              }
                            >
                              Absence
                            </button>
                          </div>
                          <div className="tabCard">
                            <div
                              className={
                                toggleState4 === 1
                                  ? "tabContent tabContentActive"
                                  : "tabContent"
                              }
                            >
                              <div className="barChartArea">
                                <BarChartExpense chart={expense} />
                              </div>
                            </div>
                            <div
                              className={
                                toggleState4 === 2
                                  ? "tabContent tabContentActive"
                                  : "tabContent"
                              }
                            >
                              <div className="barChartArea">
                                <BarChart chart={mileage} />
                              </div>
                            </div>
                            <div
                              className={
                                toggleState4 === 3
                                  ? "tabContent tabContentActive"
                                  : "tabContent"
                              }
                            >
                              <div className="barChartArea">
                                <BarChart chart={leave} />
                              </div>
                            </div>
                            <div
                              className={
                                toggleState4 === 4
                                  ? "tabContent tabContentActive"
                                  : "tabContent"
                              }
                            >
                              <div className="barChartArea">
                                <BarChart chart={overtime} />
                              </div>
                            </div>
                            <div
                              className={
                                toggleState4 === 5
                                  ? "tabContent tabContentActive"
                                  : "tabContent"
                              }
                            >
                              <div className="barChartArea">
                                <BarChart chart={absence} />
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div> */}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default EmpDashboard;
