import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  companyData: [],
  getSuperAdminCompanyListData: [],
  companyStyleData:[]
};

export const companySlice = createSlice({
  name: "companySlice",
  initialState,
  reducers: {
    companyInitialAction: () => initialState,
     getCompanyDataAction(state, action) {
      state.companyData = action.payload;
     },
     getSuperAdminCompanyListAction(state, action) { 
      state.getSuperAdminCompanyListData = action.payload;
     },
     companyStyleDataAction(state, action) {
      state.companyStyleData = action.payload;
     }
    // addDeparment: (state, action) => {
    //   const { id, department } = action.payload; 

    //   state.todos.push({ id, task })
    // },
    //  companyInfo: (state, action) => {
   
      
    //   state.department = action.payload;

    // }
  }
});

export const {companyInitialAction, getCompanyDataAction, getSuperAdminCompanyListAction, companyStyleDataAction } = companySlice.actions;
// ? Export the authSlice.reducer to be included in the store.
export default companySlice.reducer;