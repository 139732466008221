import AlternateWorkSchedule from "./AlternateWorkSchedule";
import WorkScheduleHistory from "./workScheduleHistory";
import WorkSchedule from "./WorkScheduleShift";
import {useState} from "react";

const WorkScheduleToggle = ({
  state,
  employeeInfo,
  handelChange,
  activeEmployeeUpdatePermissions,
  myprofileReadOnly = false
}) => {
  const [activeTab, setActiveTab] = useState("workSchedule");

  const handleTabChange = (tab) => {
    setActiveTab(tab);
  };

  return (
    <div>
      <div className="approvalTab employeeTab ">
        <ul>
          <li>
            <span
              onClick={() => handleTabChange("workSchedule")}
              className={activeTab === "workSchedule" ? " active" : "  "}
            >
              Current work schedule
            </span>
          </li>
          <li>
            <span
              onClick={() => handleTabChange("alternateWorkSchedule")}
              className={
                activeTab === "alternateWorkSchedule" ? "  active" : " "
              }
            >
              Alternate work schedule
            </span>
          </li>

          <li>
            <span
              onClick={() => handleTabChange("History_of_shift_changes")}
              className={
                activeTab === "History_of_shift_changes" ? "  active" : " "
              }
            >
              History of shift changes
            </span>
          </li>
        </ul>
      </div>
      <div className="tab-content mt-6">
        {activeTab === "workSchedule" ? (
          <WorkSchedule
            state={state}
            employeeInfo={employeeInfo}
            handelChange={handelChange}
            activeEmployeeUpdatePermissions={activeEmployeeUpdatePermissions}
            myprofileReadOnly={myprofileReadOnly}
          />
        ) : activeTab === "alternateWorkSchedule" ? (
          <div>
            {" "}
            <AlternateWorkSchedule state={state}
            employeeInfo={employeeInfo}
            handelChange={handelChange}
            activeEmployeeUpdatePermissions={activeEmployeeUpdatePermissions}
            myprofileReadOnly={myprofileReadOnly}
             />{" "}
          </div>
        ) : (
          <div>
            <WorkScheduleHistory employeeInfo={employeeInfo} />
          </div>
        )}
      </div>
    </div>
  );
};

export default WorkScheduleToggle;
