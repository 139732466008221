import {createApi, fetchBaseQuery} from "@reduxjs/toolkit/query/react";
import {ApiUrl} from "../constant";

export const employeeScheduleApi = createApi({
  reducerPath: "employeeScheduleApi",
  baseQuery: fetchBaseQuery({
    baseUrl: ApiUrl
  }),
  endpoints: (builder) => ({
    getWorkSchedule: builder.mutation({
      query: (id) => ({
        url: `/workschedule/?employee_id=${id}`,
        method: "GET",
        headers: {
          "Content-type": "application/json; charset=UTF-8",
          Authorization: localStorage.getItem("accessToken")
        }
      })
    }),
    getAlternateWorkSchedule: builder.mutation({
      query: (id) => ({
        url: `/employee/${id}/alternate-schedule`,
        method: "GET",
        headers: {
          "Content-type": "application/json; charset=UTF-8",
          Authorization: localStorage.getItem("accessToken")
        }
      })
    }),
    createAlternateWorkSchedule: builder.mutation({
      query: (payload) => {
        //  const {employee_id, ...workSchedules} = payload;
        return {
          url: `/workschedule/bulk/create`,
          method: "POST",
          body: payload,
          headers: {
            "Content-type": "application/json; charset=UTF-8",
            Authorization: localStorage.getItem("accessToken")
          }
        };
      },
      invalidatesTags: ["Post"]
    }),
    createWorkSchedule: builder.mutation({
      query: (payload) => {
        //  const {employee_id, ...workSchedules} = payload;
        return {
          url: `/workschedule/bulk/create`,
          method: "POST",
          body: payload,
          headers: {
            "Content-type": "application/json; charset=UTF-8",
            Authorization: localStorage.getItem("accessToken")
          }
        };
      },
      invalidatesTags: ["Post"]
    }),
    activeAlternateWorkSchedule: builder.mutation({
      query: (employee_id) => {
        //  const {employee_id, ...workSchedules} = payload;
        return {
          url: `/employee/toggle-alternate-schedule/${employee_id}`,
          method: "GET",
          headers: {
            "Content-type": "application/json; charset=UTF-8",
            Authorization: localStorage.getItem("accessToken")
          }
        };
      },
      invalidatesTags: ["Post"]
    })
    
  })
});

export const {
  useGetWorkScheduleMutation,
  useCreateWorkScheduleMutation,
  useGetAlternateWorkScheduleMutation,
  useCreateAlternateWorkScheduleMutation,
  useActiveAlternateWorkScheduleMutation
} = employeeScheduleApi;
